import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'withdrawal.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '退会',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawal.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '退会',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawal.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue: '「続ける」を押すと退会確認画面に進みます。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawal.continueButtonName',
    label: 'ボタン名 退会確認画面に遷移',
    type: 'Text',
    defaultValue: '退会確認画面へ',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawal.backButtonName',
    label: 'ボタン名 マイページTOPに遷移',
    type: 'Text',
    defaultValue: 'マイページTOPへ',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
