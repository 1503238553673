import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SettingTitleComponent } from 'admin/components/service/setting/settingTitle/SettingTitleComponent';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  headerLabel: string;
};

export const settingTitle: FC<Props> = memo(({ headerLabel }: Props) => <SettingTitleComponent label={headerLabel} />);

export const SettingTitle = memo(
  withSuspenseAndErrorBoundary(settingTitle, {
    ErrorComponent: <ErrorContents name="その他の設定タイトル" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
