import {
  Box,
  Button,
  DrawerBody,
  DrawerFooter,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { LoadingLayer } from 'admin/components/ui/loadingLayer';
import { Colors, ColorSet } from 'admin/define/colors';
import { SettingFormType } from 'admin/types/service/setting/form';
import { FC, memo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  settingDetail: SettingFormType;
  isLoading: boolean;
  formId: string;
  onClose: () => void;
  onSubmit: (value: SettingFormType) => void;
};

export const ApiWhiteListSettingFormComponent: FC<Props> = memo(
  ({ settingDetail, isLoading, formId, onClose, onSubmit }: Props) => {
    const [whiteList, setWhiteList] = useState(settingDetail.whiteList);
    const { handleSubmit, setValue } = useFormContext<SettingFormType>();

    const onChangeWhiteList = useCallback(
      (val: string) => {
        setWhiteList(val);
        setValue('whiteList', val);
      },
      [setValue],
    );

    return (
      <>
        {isLoading && <LoadingLayer />}
        <DrawerBody pt="16px" pb="16px">
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Box fontFamily="Hiragino Sans">
              <Text>
                API通信を許可するIPアドレスを改行区切りで記入してください。
              </Text>
              <Box mt="16px">
                <Textarea
                  value={whiteList}
                  onChange={(e) => onChangeWhiteList(e.target.value)}
                  bgColor={Colors.WHITE}
                  borderRadius="md"
                  height="250px"
                />
              </Box>
            </Box>
          </form>
        </DrawerBody>
        <DrawerFooter borderWidth="1px">
          <Button
            variant="outline"
            mr={3}
            p="16px 24px"
            w="120px"
            onClick={onClose}
            {...ColorSet.Default}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            form={formId}
            mr={3}
            p="16px 24px"
            w="120px"
            colorSchema="primary"
          >
            保存
          </Button>
        </DrawerFooter>
      </>
    );
  },
);
