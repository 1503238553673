import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  ListItem,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { IdPoolConsumerListItem } from 'admin/types/userPool';
import {
  AUTH_CODE_TYPE_LIST,
  AUTH_CODE_TYPE_OUTER,
  AUTH_CODE_TYPE_UNIIKEY,
} from 'define';
import { FC, memo, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';

type Props = {
  idPoolConsumer: IdPoolConsumerListItem;
  onOpen: () => void;
  navigate: NavigateFunction;
};

export const IdPoolConsumerBlockComponent: FC<Props> = memo(
  ({ idPoolConsumer, onOpen, navigate }: Props) => {
    const { hasPermissionUserPool, isShowUserPool } = useUserCorporationInfo();
    const hasServiceNames = useMemo(
      () => idPoolConsumer.serviceNames.length > 0,
      [idPoolConsumer.serviceNames.length],
    );
    const isUniikeyAuthenticationType = useMemo(
      () =>
        Number(idPoolConsumer.authenticationType) === AUTH_CODE_TYPE_UNIIKEY,
      [idPoolConsumer.authenticationType],
    );
    const isOuterAuthenticationType = useMemo(
      () => Number(idPoolConsumer.authenticationType) === AUTH_CODE_TYPE_OUTER,
      [idPoolConsumer.authenticationType],
    );
    const isShowMenu = useMemo<boolean>(
      () => isShowUserPool(idPoolConsumer.id),
      [idPoolConsumer.id, isShowUserPool],
    );

    return (
      <ListItem
        border="1px solid"
        borderColor={Colors.GRAY_30}
        overflow="hidden"
        w="100%"
        borderRadius="8px"
        bg={Colors.WHITE}
      >
        <Flex
          alignItems="center"
          p="16px 24px"
          bgColor={Colors.GRAY_70}
          justifyContent="space-between"
        >
          <Text as="a" fontSize="xl" fontWeight="700">
            {idPoolConsumer.name}
          </Text>
          {hasPermissionUserPool && (
            <Button
              width="188px"
              maxWidth="188px"
              minW="188px"
              ml={4}
              fontSize="14px"
              colorScheme="primary"
              variant="outline"
              fontWeight="700"
              bgColor={Colors.WHITE}
              onClick={onOpen}
            >
              ユーザープール名編集
              <EditIcon ml={2} fontSize="xs" fontWeight="thin" />
            </Button>
          )}
        </Flex>
        <VStack
          alignItems="flex-start"
          divider={<StackDivider borderColor="#CFD6DD" />}
          p="0 24px"
        >
          <VStack w="100%">
            <Flex w="100%" alignItems="center" p="24px 0px 20px">
              <Text fontWeight="700" fontSize="16px" maxW="180px" flexGrow={1}>
                利用ID種別
              </Text>
              <Text
                bgColor={Colors.GRAY_70}
                p="8px"
                borderRadius="8px"
                border="1px solid"
                borderColor={Colors.GRAY_75}
                color="#ACADAE"
                flexGrow={1}
                minH="39px"
              >
                {AUTH_CODE_TYPE_LIST.get(
                  Number(idPoolConsumer.authenticationType),
                )}
              </Text>
            </Flex>
            {isUniikeyAuthenticationType && (
              <Flex w="100%" alignItems="center" p="20px 0px 24px">
                <Text
                  fontWeight="700"
                  fontSize="16px"
                  maxW="180px"
                  flexGrow={1}
                >
                  フィールド名
                </Text>
                <Text
                  bgColor={Colors.GRAY_70}
                  p="8px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor={Colors.GRAY_75}
                  color="#ACADAE"
                  flexGrow={1}
                  minH="39px"
                >
                  {idPoolConsumer.fieldName}
                </Text>
              </Flex>
            )}
          </VStack>

          <Stack w="100%" p="24px 0">
            <Box>
              <Text fontWeight="700" fontSize="16px">
                使用テナント
              </Text>
            </Box>
            <Box>
              {!hasServiceNames && (
                <Box
                  border="solid 1px"
                  borderColor={Colors.GRAY_75}
                  borderRadius="8px"
                  p="8px 16px"
                >
                  <Text>
                    このユーザープールを使用しているテナントはありません。
                  </Text>
                </Box>
              )}
              {hasServiceNames && (
                <Box
                  border="solid 1px"
                  borderColor={Colors.GRAY_75}
                  borderRadius="8px"
                  pb="8px"
                  overflow="hidden"
                >
                  <Box bgColor="#EAEDF0" px="16px" py="8px">
                    <Text fontWeight="bold" fontSize="14px">
                      このユーザープールは{idPoolConsumer.serviceNames.length}
                      個のテナントで使用されています
                    </Text>
                  </Box>
                  <Flex p="8px 16px 0" gridGap="15px 30px" flexWrap="wrap">
                    {idPoolConsumer.serviceNames.map((serviceName, idx) => (
                      <Box
                        bgColor={Colors.BLUE_30}
                        p="4px 8px"
                        borderRadius="6px"
                        overflow="hidden"
                        key={`${serviceName}_${String(idx)}`}
                      >
                        {serviceName}
                      </Box>
                    ))}
                  </Flex>
                </Box>
              )}
            </Box>
          </Stack>

          {isOuterAuthenticationType && isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  外部IDシステムJSON設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  ご利用中のIDシステムの利用者管理方法をJSON形式で登録します。取り込んだ情報は属性データと紐づける際に必要になります。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/userInfo`,
                    )
                  }
                >
                  外部IDシステムJSON設定
                </Button>
              </Box>
            </Flex>
          )}
          {isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  選択肢マスタ一覧
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  選択肢マスタを設定します。選択形式の属性パーツを設定する際に必要になります。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/master`,
                    )
                  }
                >
                  選択肢マスタ設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
              <AlertInfoBar type="warning" iconType="error">
                選択肢マスタ設定が未設定です。
                <br />
                「選択肢マスタ設定」ボタンから選択肢マスタ設定をしてください。
              </AlertInfoBar>
            </Box> */}
            </Flex>
          )}

          {isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  属性設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  組織として利用したい属性情報を設定します。エンドユーザーの情報管理、設問テンプレートの作成時に必要になります。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  width="118px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/attribute`,
                    )
                  }
                >
                  属性設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
              <AlertInfoBar type="warning" iconType="error">
                組織レベルの属性が未設定です。
                <br />
                「属性設定」ボタンから組織レベルの属性を設定してください
              </AlertInfoBar>
            </Box> */}
            </Flex>
          )}
          {isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  フォーム設問グループ設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  指定した設問パーツを一つにまとめて利用するフォーム設問グループを設定します。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/formGroup`,
                    )
                  }
                >
                  フォーム設問グループ設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
              <AlertInfoBar type="warning" iconType="error">
                フォーム設問グループ設定が未設定です。
                <br />
                「フォーム設問グループ設定」ボタンからフォーム設問グループ設定を設定してください
              </AlertInfoBar>
            </Box> */}
            </Flex>
          )}
          {isUniikeyAuthenticationType && isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  規約設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  組織として利用したい規約やポリシーを設定します。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  width="118px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(`/admin/idPoolConsumer/${idPoolConsumer.id}/terms`)
                  }
                >
                  規約設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
                <AlertInfoBar type="warning" iconType="error">
                  組織レベルの規約が未設定です。
                  <br />
                  「規約設定」ボタンから組織レベルの規約を設定してください
                </AlertInfoBar>
              </Box> */}
            </Flex>
          )}
          {isUniikeyAuthenticationType && isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  メールマガジン購読設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  メールマガジン購読を設定します。希望するエンドユーザーに対して定期的に情報やお知らせを発信する際に必要になります。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  width="202px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/newsletter`,
                    )
                  }
                >
                  メールマガジン購読設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
                      <AlertInfoBar type="warning" iconType="error">
                        メールマガジン購読設定が未設定です。
                        <br />
                        「メールマガジン購読設定」ボタンからメールマガジン購読を設定してください
                      </AlertInfoBar>
                    </Box> */}
            </Flex>
          )}
          {isUniikeyAuthenticationType && isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  トランザクションメール設定
                </Text>
                <Text
                  fontSize="12px"
                  color={Colors.GRAY_00}
                  lineHeight="20.4px"
                >
                  トランザクションメールを設定します。エンドユーザーのアクションに応じたメールを送信したい場合に設定が必要になります。
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  ml={4}
                  fontSize="14px"
                  width="230px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/transactionMail`,
                    )
                  }
                >
                  トランザクションメール設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
                <AlertInfoBar type="warning" iconType="error">
                  トランザクションメール設定が未設定です。
                  <br />
                  「メール設定」ボタンからメールを設定してください
                </AlertInfoBar>
              </Box> */}
            </Flex>
          )}
          {isUniikeyAuthenticationType && isShowMenu && (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p="40px 0"
            >
              <Box>
                <Text fontWeight="700" fontSize="16px">
                  認証認可とセキュリティ設定
                </Text>
              </Box>
              <Box>
                <Button
                  p="4px 24px"
                  w="230px"
                  ml={4}
                  fontSize="14px"
                  colorScheme="primary"
                  variant="outline"
                  fontWeight="700"
                  onClick={() =>
                    navigate(
                      `/admin/idPoolConsumer/${idPoolConsumer.id}/attribute`,
                    )
                  }
                >
                  認証認可とセキュリティ設定
                </Button>
              </Box>
              {/* TODO: 2024.04.03時点では不要なレイアウトの為コメントアウト */}
              {/* <Box mt={4}>
                <AlertInfoBar type="warning" iconType="error">
                  その他の設定が未設定です。
                  <br />
                  「設定」ボタンからその他の設定してください
                </AlertInfoBar>
              </Box> */}
            </Flex>
          )}
        </VStack>
      </ListItem>
    );
  },
);
