import { customErrorMessage, toZod } from 'admin/config/zod';
import { SettingType } from 'admin/types/service/setting';
import { z } from 'zod';

// zodErrorの日本語化対応
z.setErrorMap(customErrorMessage);

export const settingSchema = z.object<toZod<SettingType>>({
  id: z.string(),
  label: z.string(),
  usageEnvironment: z.string(),
  purpose: z.string(),
  contentType: z.string(),
  xApiKey: z.string(),
  accessKey: z.string(),
  secretKey: z.string(),
  authorization: z.string(),
  whiteList: z.string(),
});

export const settingFormSchema = z.object<
  toZod<Pick<SettingType, 'id' | 'whiteList'>>
>({
  id: z.string(),
  whiteList: z.string(),
});
