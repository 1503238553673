import { memo } from 'react';
import { NewPasswordEntryPage as Template } from 'view-template/dist/page';
import { TemplatePageProps, Wrap } from '../Page';
import { defaultTheme } from '../util';

export const NewPasswordEntryPage = memo(
  ({ watch, force }: TemplatePageProps) => {
    const dummyProps = {
      csrfToken: '',
      postPath: '',
      errorMessage: undefined,
      oauthRequest: {
        response_type: '',
        client_id: '',
        redirect_uri: '',
      },
      passwordResetToken: '',
    };

    return (
      <Wrap>
        <Template {...dummyProps} theme={defaultTheme(watch)} force={force} />
      </Wrap>
    );
  },
);
