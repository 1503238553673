import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ApiWhiteListSettingFormComponent } from 'admin/components/service/setting/apiWhiteListSettingForm/ApiWhiteListSettingFormComponent';
import { useUpdateSetting } from 'admin/hooks/service/setting/useUpdateSetting';
import { SettingFormType } from 'admin/types/service/setting/form';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  settingDetail: SettingFormType;
  onClose: () => void;
};

export const apiWhiteListSettingForm: FC<Props> = memo(
  ({ settingDetail, onClose }: Props) => {
    const { mutate, isLoading } = useUpdateSetting();
    const formId = 'apiWhiteListSettingForm';
    const methods = useForm<SettingFormType>({
      defaultValues: {
        id: '',
        whiteList: '',
      },
    });

    const { setValue } = methods;

    useEffect(() => {
      setValue('id', settingDetail.id);
      setValue('whiteList', settingDetail.whiteList);
    }, [setValue, settingDetail]);

    const onSubmit: SubmitHandler<SettingFormType> = async (
      data: SettingFormType,
    ) => {
      await mutate(data);
      onClose();
    };

    return (
      <FormProvider {...methods}>
        <ApiWhiteListSettingFormComponent
          settingDetail={settingDetail}
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={isLoading}
          formId={formId}
        />
      </FormProvider>
    );
  },
);

export const ApiWhiteListSettingForm = memo(
  withSuspenseAndErrorBoundary(apiWhiteListSettingForm, {
    ErrorComponent: <ErrorContents name="API通信ホワイトリスト詳細" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
