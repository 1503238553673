import { Box, Text, VStack } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { SettingType } from 'admin/types/service/setting/';
import { FC, memo } from 'react';

type Props = {
  settingData: SettingType;
};

export const ApiKeyComponent: FC<Props> = memo(({ settingData }: Props) => (
  <VStack
    spacing={4}
    alignItems="flex-start"
    fontFamily="Hiragino Sans"
    display="inline-block"
    w="100%"
  >
    <Box>
      <Text fontWeight="700">APIキー</Text>
      <Text>XXXXに利用します。</Text>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">利用環境</Text>
      <Text>{settingData.usageEnvironment}</Text>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">用途</Text>
      <Text>{settingData.purpose}</Text>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">Content-Type</Text>
      <Text>{settingData.contentType}</Text>
    </Box>
    <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
      <Text fontWeight="700">x-apikey</Text>
      <Text>{settingData.xApiKey}</Text>
    </Box>
  </VStack>
));
