import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { settingFormSchema } from 'admin/schema/service/setting';
import { SettingFormType } from 'admin/types/service/setting/form';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

const dummyData: SettingFormType = {
  id: 'dummy-data-1',
  whiteList: '000.000.000',
};
export const getSettingDetail = async (): Promise<SettingFormType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });
  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  // ダミーデータ
  const response = await getDummySuccessResponse<SettingFormType>(dummyData);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SETTIN_DETAIL.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SETTIN_DETAIL.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    settingFormSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
