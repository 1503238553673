import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'registrationComplete.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '登録完了',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'registrationComplete.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '登録完了',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'registrationComplete.registrationCompletionMessage',
    label: '案内文 登録完了案内',
    type: 'Textarea',
    defaultValue:
      '会員登録が完了しました。\n\nアカウント情報の変更はマイページからお願いいたします。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'registrationComplete.continueButtonName',
    label: 'ボタン名 次の画面に遷移',
    type: 'Text',
    defaultValue: '続ける',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
