import {
  Box,
  Flex,
  ListItem,
  Tr as ChakraTr,
  UnorderedList,
} from '@chakra-ui/react';
import { AttributeTableInner } from 'admin/components/idPoolConsumer/attribute/attributeTableInner';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import {
  IdPoolConsumerAttributesType,
  IdPoolConsumerAttributeType,
} from 'admin/types/userPool/attribute';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  attributes: IdPoolConsumerAttributesType;
  isUniikey: boolean;
  isOuter: boolean;
  onOpen: (id: IdPoolConsumerAttributeType['id']) => void;
  setTargetAttribute: Dispatch<
    SetStateAction<IdPoolConsumerAttributeType | undefined>
  >;
  onOpenStatusDialog: () => void;
  onOpenAutoAppendDialog: () => void;
  onOpenFormAttrDialog: () => void;
  onOpenCommonMyPageModalHandler: (status: boolean) => void;
};

export const AttributeTableComponent: FC<Props> = memo(
  ({
    attributes,
    isUniikey,
    isOuter,
    onOpen,
    setTargetAttribute,
    onOpenStatusDialog,
    onOpenAutoAppendDialog,
    onOpenFormAttrDialog,
    onOpenCommonMyPageModalHandler,
  }: Props) => (
    <Box mt="24px">
      <Table w="100%" maxHeight="calc(100vh - 300px)">
        <Thead h="54px">
          <ChakraTr>
            <Th maxWidth="120px" minWidth="120px" fontSize="14px">
              属性ID
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（日本語）
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（外国語）
            </Th>
            <Th
              maxWidth="120px"
              minWidth="120px"
              fontSize="14px"
              p={0}
              textAlign="center"
            >
              フィールド名
            </Th>
            <Th maxWidth="150px" minWidth="150px" fontSize="14px">
              UIタイプ
            </Th>
            <Th maxWidth="110px" minWidth="110px" fontSize="14px">
              データ型
            </Th>
            <Th maxWidth="130px" minWidth="130px" fontSize="14px">
              選択肢マスタ
            </Th>

            <Th
              hasBorder={!isOuter}
              maxWidth="100px"
              minWidth="100px"
              fontSize="14px"
            >
              個人情報
            </Th>
            {isOuter && (
              <Th
                hasBorder={isOuter}
                maxWidth="210px"
                minWidth="210px"
                fontSize="14px"
              >
                JSONパス
              </Th>
            )}
            <Th maxWidth="124px" minWidth="124px" fontSize="14px">
              ステータス
            </Th>
            <Th
              maxWidth="150px"
              minWidth="150px"
              fontSize="14px"
              p="0"
              textAlign="center"
            >
              <Flex alignItems="center">
                全テナント割当
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        ステータスが「確定」になった場合に、操作が可能です。
                      </ListItem>
                      <ListItem>
                        ONにすると、対象の属性が全テナントで利用可能となります。
                      </ListItem>
                      <ListItem>
                        一度ONにした設定はOFFに変更できません。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Flex>
            </Th>
            <Th
              hasBorder={!isUniikey}
              maxWidth="150px"
              minWidth="150px"
              fontSize="14px"
              p="0"
              textAlign="center"
            >
              <Flex alignItems="center">
                全フォーム利用
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        ステータスが「確定」になった場合に、操作が可能です。
                      </ListItem>
                      <ListItem>
                        ONにすると、対象の属性が全テナントのフォームに表示されます。
                      </ListItem>
                      <ListItem>
                        一度ONにした設定はOFFに変更できません。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Flex>
            </Th>
            {isUniikey && (
              <Th
                hasBorder={isUniikey}
                maxWidth="180px"
                minWidth="180px"
                fontSize="14px"
                p="0"
                textAlign="center"
              >
                共通マイページ表示
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        ステータスが「確定」になった場合に、操作が可能です。
                      </ListItem>
                      <ListItem>
                        ONにすると、対象の属性がAuth
                        Hubの共通マイページに表示されます。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Th>
            )}
            <Th maxWidth="80px" minWidth="80px" fontSize="14px">
              並び順
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributes.map((attribute, idx) => (
            <AttributeTableInner
              attribute={attribute}
              onOpen={onOpen}
              key={String(idx)}
              setTargetAttribute={setTargetAttribute}
              onOpenStatusDialog={onOpenStatusDialog}
              onOpenAutoAppendDialog={onOpenAutoAppendDialog}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
              onOpenCommonMyPageModalHandler={onOpenCommonMyPageModalHandler}
              isUniikey={isUniikey}
              isOuter={isOuter}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
