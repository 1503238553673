/* eslint-disable @typescript-eslint/no-unused-vars */
import { putEnqueteSetting } from 'api/enquete/putEnqueteSetting';
import {
  EditEnqueteSettingForm,
  isSettingInputs,
  SettingInputs,
} from 'api/enquete/types';
import { ResponseError } from 'api/types';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { isGetValidationError, toMultiError } from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;
type SettingInputsOmitEnqueteContents = Omit<SettingInputs, 'enqueteContents'>;

const formData: SettingInputsOmitEnqueteContents = {
  id: '',
  title: '',
  startDate: '',
  endDate: '',
  answerPossibleNum: 1,
  answerErrMessage: '',
  group: '',
  trigger: '',
  postTarget: 0,
  positionPc: 0,
  positionSp: 0,
  positionTop: 0,
  positionLeft: 0,
  positionRight: 0,
  positionBottom: 0,
  scroll: 0,
  timer: 0,
  any: '',
  passwordQuestion: '',
  passwordAnswer: '',
  bgColor: '',
  buttonName: '',
  buttonFontColor: '',
  buttonBgColor: '',
  buttonSize: 0,
  isSendThanksMail: false,
  isNoticeMailFileDownloadUrl: false,
  thanksMailFromAddress: '',
  thanksMailReplyAddress: '',
  thanksMailSubject: '',
  thanksMailBodyText: '',
  thanksMailFromAddressList: [],
  thanksMailReplyAddressList: [],
};

/**
 * アンケート設定保存 hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditEnqueteSetting = ({
  setError,
  onDrawerClose,
}: {
  setError: UseFormSetError<SettingInputs>;
  onDrawerClose: () => void;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putEnqueteSetting>>,
    unknown,
    EditEnqueteSettingForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const { EnqueteListRefetchQueries } = useEnqueteUtil();
  const toast = useCustomToast();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, enqueteId, enqueteSetting }: EditEnqueteSettingForm) =>
      putEnqueteSetting({ tenantId, enqueteId, enqueteSetting }),
    {
      onSuccess: (result: SettingInputs | ResponseError) => {
        EnqueteListRefetchQueries();
        if (isSettingInputs(result)) {
          onDrawerClose();
          toast({
            status: 'success',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: 'フォーム設定を修正しました',
          });
        }

        if (
          isGetValidationError<SettingInputsOmitEnqueteContents>(
            result,
            Object.keys(formData),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            const errors: string[] = [];
            Object.keys(result).forEach((k) => {
              const key = k as keyof SettingInputsOmitEnqueteContents;
              const errMsgs = result?.[key];
              if (errMsgs) {
                setError(
                  key,
                  { types: toMultiError(errMsgs) },
                  { shouldFocus: true },
                );
                errors.push(errMsgs[0]);
              }
            });

            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errors.length > 0 ? errors[0] : 'エラーが発生しました。',
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
