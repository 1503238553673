import { useDisclosure } from '@chakra-ui/react';
import { CustomError } from 'api/error/CustomError';
import { createContext, ReactNode, useContext, useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useAuthHub } from 'admin/hooks/authHub/useAuthHub';
import {
  AuthHubResultType,
  AuthHubItemsType,
  FormType,
} from 'admin/types/authHub';
import { UseQueryResult } from 'react-query';
import {
  useForm,
  FieldValues,
  UseFormReturn,
  FormProvider,
} from 'react-hook-form';
import { useUpdateAuthHub } from 'admin/hooks/authHub/useUpdateAuthHub';
import { settings } from 'admin/components/authHub/settings';
import { SavedModal } from './SavedModal';

type AuthHubContextType = {
  authHub: {
    data?: AuthHubResultType;
    refetch: (options?: {
      throwOnError: boolean;
      cancelRefetch: boolean;
    }) => Promise<UseQueryResult>;
  };
  userPoolId?: string;
  tenantId?: string;
  authHubId?: string;
  form: UseFormReturn<FieldValues>;
};

const getDefaultValues: (
  authHubs?: AuthHubItemsType,
) => Record<string, FieldValues> = (authHubs) => {
  const values: Record<string, FieldValues> = {};
  authHubs?.forEach(({ groupName, key, value }) => {
    values[groupName] ||= {};
    values[groupName][key] = value;
  });

  return values;
};

const AuthHubContext = createContext<AuthHubContextType | undefined>(undefined);

type AuthHubProviderProps = {
  children: ReactNode;
};

export const AuthHubProvider: FC<AuthHubProviderProps> = ({ children }) => {
  const params = useParams();
  const userPoolId = params?.userPoolId ?? '';
  const authHubId = params?.authHubId ?? '';
  const tenantId = useUserTenantId();

  const backUrl = userPoolId ? '/admin/authHub' : '/authHub';

  const authHub = useAuthHub({ userPoolId, tenantId, authHubId });

  const defaultValues = getDefaultValues(authHub?.data?.authHubItems);

  const form = useForm<FormType>({ mode: 'onBlur', defaultValues });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSuccess = () => {
    onOpen();
  };
  const { refetch } = authHub;
  const { updateMutate } = useUpdateAuthHub({ onSuccess });

  const {
    formState: { dirtyFields },
    handleSubmit,
  } = form;

  const onSubmit = useCallback(
    async (submitData: Record<string, Record<string, string>>) => {
      const authHubItems = Object.keys(settings).reduce(
        (acc, x) => [
          ...acc,
          ...Object.entries(submitData[x] || [])
            .filter(([key]) => key in (dirtyFields[x] || {}))
            .map(([key, value]) => ({ groupName: x, key, value })),
        ],
        [] as AuthHubItemsType,
      );

      const id = authHubId;
      if (!id) {
        throw new CustomError('aaa'); // TODO: error message
      }
      await updateMutate({
        userPoolId,
        tenantId,
        id,
        authHubItems,
      });

      await refetch();
    },
    [userPoolId, tenantId, authHubId, dirtyFields, updateMutate, refetch],
  );

  return (
    <AuthHubContext.Provider
      value={{ authHub, userPoolId, tenantId, authHubId, form }}
    >
      <SavedModal isOpen={isOpen} onClose={onClose} backUrl={backUrl} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </AuthHubContext.Provider>
  );
};

type UseAuthHubContextType = () => AuthHubContextType;

export const useAuthHubContext: UseAuthHubContextType = () => {
  const context = useContext(AuthHubContext);
  if (context === undefined) {
    throw new Error('useAuthHubContext must be used within an AuthHubProvider');
  }

  return context;
};
