import { UseFormWatch } from 'react-hook-form';
import { FormType } from 'admin/types/authHub';
import { settings, Settings } from '../settings';
import { PageProps } from './Page';

type ItemType = readonly { readonly name: string }[];

const toDict = <K extends keyof Settings>(
  obj: ItemType,
  watch: UseFormWatch<FormType>,
) =>
  obj.reduce(
    (acc, x) => ({
      ...acc,
      [x.name.split('.').slice(-1)[0]]: watch(x.name),
    }),
    {} as PageProps<K>,
  );

export const defaultTheme = (watch: UseFormWatch<FormType>) => {
  const header = toDict<'header'>(settings.header, watch);
  const footer = toDict<'footer'>(settings.footer, watch);
  const theme = toDict<'theme'>(settings.theme, watch);
  const login = toDict<'login'>(settings.login, watch);
  const register = toDict<'register'>(settings.register, watch);
  const passwordChange = toDict<'passwordChange'>(
    settings.passwordChange,
    watch,
  );
  const emailChange = toDict<'emailChange'>(settings.emailChange, watch);
  const complete = toDict<'complete'>(settings.complete, watch);
  const withdrawalComplete = toDict<'withdrawalComplete'>(
    settings.withdrawalComplete,
    watch,
  );
  const withdrawalConfirmation = toDict<'withdrawalConfirmation'>(
    settings.withdrawalConfirmation,
    watch,
  );
  const withdrawal = toDict<'withdrawal'>(settings.withdrawal, watch);
  const newEmailEntry = toDict<'newEmailEntry'>(settings.newEmailEntry, watch);
  const verificationCodeEntry = toDict<'verificationCodeEntry'>(
    settings.verificationCodeEntry,
    watch,
  );
  const memberInfoConfirm = toDict<'memberInfoConfirm'>(
    settings.memberInfoConfirm,
    watch,
  );
  const memberInfoChange = toDict<'memberInfoChange'>(
    settings.memberInfoChange,
    watch,
  );
  const mypage = toDict<'mypage'>(settings.mypage, watch);
  const registrationComplete = toDict<'registrationComplete'>(
    settings.registrationComplete,
    watch,
  );
  const infoConfirmation = toDict<'infoConfirmation'>(
    settings.infoConfirmation,
    watch,
  );
  const firstInfoEntry = toDict<'firstInfoEntry'>(
    settings.firstInfoEntry,
    watch,
  );
  const missingInfoEntry = toDict<'missingInfoEntry'>(
    settings.missingInfoEntry,
    watch,
  );
  const memberInfoEntry = toDict<'memberInfoEntry'>(
    settings.memberInfoEntry,
    watch,
  );
  const newPasswordEntry = toDict<'newPasswordEntry'>(
    settings.newPasswordEntry,
    watch,
  );
  const passwordCreate = toDict<'passwordCreate'>(
    settings.passwordCreate,
    watch,
  );
  const passwordReset = toDict<'passwordReset'>(settings.passwordReset, watch);
  const passwordUnset = toDict<'passwordUnset'>(settings.passwordUnset, watch);

  return {
    authHubId: '',
    primaryClientId: 'clientid123',
    primaryClientSecret: 'secret456',
    isPreviewMode: true,
    passwordChange,
    emailChange,
    complete,
    withdrawalComplete,
    withdrawalConfirmation,
    withdrawal,
    newEmailEntry,
    verificationCodeEntry,
    memberInfoConfirm,
    memberInfoChange,
    mypage,
    registrationComplete,
    infoConfirmation,
    firstInfoEntry,
    missingInfoEntry,
    memberInfoEntry,
    newPasswordEntry,
    passwordCreate,
    passwordReset,
    passwordUnset,
    header,
    login,
    register,
    footer,
    theme,
    embeddedTags: {
      head: '',
      body: '',
    },
  };
};

const attributes = [
  {
    id: 'name',
    titleJa: '',
    attributeRole: undefined,
    attributeGroup: {
      displaytype: 'horizontal2' as const,
      attributes: [
        {
          id: 'last_name',
          titleJa: '姓',
          attributeRole: undefined,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: true,
        },
        {
          id: 'first_name',
          titleJa: '名',
          attributeRole: undefined,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: true,
        },
        {
          id: 'last_name_kana',
          titleJa: '姓（カナ）',
          attributeRole: undefined,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: true,
        },
        {
          id: 'first_name_kana',
          titleJa: '名（カナ）',
          attributeRole: undefined,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: true,
        },
      ],
    },
    codes: [],
    uiType: 'group' as const,
    isRequired: true,
  },
  {
    id: 'gender',
    titleJa: '性別',
    attributeRole: undefined,
    attributeGroup: undefined,
    codes: [
      { id: '1', label: '男性' },
      { id: '2', label: '女性' },
      { id: '3', label: 'その他' },
      { id: '4', label: '回答しない' },
    ],
    uiType: 'radio' as const,
    isRequired: true,
  },
  {
    id: 'birthday',
    titleJa: '生年月日',
    attributeRole: undefined,
    attributeGroup: undefined,
    codes: [],
    uiType: 'date_split' as const,
    isRequired: false,
  },
  {
    id: 'address',
    titleJa: '',
    attributeRole: undefined,
    attributeGroup: {
      displaytype: 'address_ja' as const,
      attributes: [
        {
          id: 'zip_code',
          titleJa: '郵便番号',
          attributeRole: 'zip_code_ja' as const,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: false,
        },
        {
          id: 'prefecture',
          titleJa: '都道府県',
          attributeRole: 'prefecture_ja' as const,
          attributeGroup: undefined,
          codes: [{ id: '1', label: '東京都' }],
          uiType: 'selectbox' as const,
          isRequired: false,
        },
        {
          id: 'address1',
          titleJa: '住所1',
          attributeRole: 'address1' as const,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: false,
        },
        {
          id: 'address2',
          titleJa: '住所2',
          attributeRole: 'address2' as const,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: false,
        },
        {
          id: 'address3',
          titleJa: '住所3',
          attributeRole: 'address3' as const,
          attributeGroup: undefined,
          codes: [],
          uiType: 'text' as const,
          isRequired: false,
        },
      ],
    },
    codes: [],
    uiType: 'group' as const,
    isRequired: true,
  },
  {
    id: 'tel',
    titleJa: '電話番号',
    attributeRole: undefined,
    attributeGroup: undefined,
    codes: [],
    uiType: 'text' as const,
    isRequired: false,
  },
  {
    id: 'occupation',
    titleJa: '職業',
    attributeRole: undefined,
    attributeGroup: undefined,
    codes: [{ id: '1', label: '会社員' }],
    uiType: 'selectbox' as const,
    isRequired: false,
  },
  {
    id: 'income',
    titleJa: '年収',
    attributeRole: undefined,
    attributeGroup: undefined,
    codes: [{ id: '1', label: '800万円〜900万円' }],
    uiType: 'selectbox' as const,
    isRequired: false,
  },
];

const newsletters = [
  { id: 'a', title: 'メールマガジンA' },
  { id: 'b', title: 'メールマガジンB' },
];

const terms = [
  {
    id: 'terms1',
    url: '',
    title: 'プライバシーポリシー',
    version: 1,
  },
  {
    id: 'terms2',
    url: '',
    title: '利用規約',
    version: 1,
  },
];

export const dummyUserInfo = {
  authenticatedToken: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  attributes,
  attributeValues: {
    name: {
      type: 'attribute_group',
      value: {
        last_name: '山田',
        first_name: '花子',
        last_name_kana: 'ヤマダ',
        first_name_kana: 'ハナコ',
      },
    } as const,
    gender: '2',
    birthday: { type: 'date_split', year: 1998, month: 1, day: 10 } as const,
    address: {
      type: 'attribute_group',
      value: {
        zip_code: '154-0015',
        prefecture: '1',
        address1: '世田谷区桜新町',
        address2: '１丁目２番地３４５',
        address3: '',
      },
    } as const,
    tel: '090-1234-5678',
    occupation: '1',
    income: '1',
  },
  isTermsChecked: false,
  password: '',
  checkedNewsletterIds: ['a', 'b'],
  newsletters,
  terms,
  email: 'test@example.com',
};
