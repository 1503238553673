import { VFC } from 'react';
import { EnqueteInner } from 'components/enquete/organisms/EnqueteInner';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useEnqueteDetail } from 'hooks/enquete/useEnqueteDetail';

type Props = {
  formId: string;
};

export const EnqueteEditForm: VFC<Props> = ({ formId }: Props) => {
  const tenantId = useUserTenantId();
  const enqueteDetail = useEnqueteDetail({ enqueteId: formId });

  return (
    <EnqueteInner
      tenantId={tenantId}
      euqueteForm={enqueteDetail}
      formIdParams={formId}
    />
  );
};
