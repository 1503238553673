import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AccessSecretKeyComponent } from 'admin/components/service/setting/accessSecretKey/AccessSecretKeyComponent';
import { SettingType } from 'admin/types/service/setting/';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  settingData: SettingType;
};

export const accessSecretKey: FC<Props> = memo(({ settingData }: Props) => (
  <AccessSecretKeyComponent settingData={settingData} />
));

export const AccessSecretKey = memo(
  withSuspenseAndErrorBoundary(accessSecretKey, {
    ErrorComponent: <ErrorContents name="アクセスキー・シークレットキー" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
