export default [
  {
    name: 'header.logo',
    label: 'ロゴ',
    type: 'Image',
    defaultValue: '',
    options: {},
    elementOptions: {},
  },
] as const;
