import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  urlOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  {
    name: 'register.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '新規会員登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'register.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '会員登録は無料です。\n会員登録を行うことで、編集部主催のキャンペーンへの応募やメールマガジンの購読ができるようになります。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'register.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '新規会員登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'register.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentDescription',
    label: '案内文 みなし同意',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLink1',
    label: 'URL みなし同意規約リンク1',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLinkName1',
    label: 'リンクテキスト みなし同意規約リンク1',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLink2',
    label: 'URL みなし同意規約リンク2',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLinkName2',
    label: 'リンクテキスト みなし同意規約リンク2',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLink3',
    label: 'URL みなし同意規約リンク3',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLinkName3',
    label: 'リンクテキスト みなし同意規約リンク3',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLink4',
    label: 'URL みなし同意規約リンク4',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'register.deemedConscentLinkName4',
    label: 'リンクテキスト みなし同意規約リンク4',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.registerButtonName',
    label: 'ボタン名 会員登録',
    type: 'Text',
    defaultValue: '会員登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'register.existingUserDescription',
    label: '案内文 既存利用者向け案内',
    type: 'Textarea',
    defaultValue: 'すでにアカウントをお持ちの方',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'register.loginButtonName',
    label: 'ボタン名 ログイン',
    type: 'Text',
    defaultValue: 'ログイン',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
