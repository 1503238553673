import { VFC } from 'react';
import { EnqueteInner } from 'components/enquete/organisms/EnqueteInner';
import { Enquete, EnquetDefaultValue } from 'api/enquete/types';
import { useUserTenantId } from 'hooks/user/useUserTenantId';

const defaultValues: Enquete = EnquetDefaultValue;

export const EnqueteCreateForm: VFC = () => {
  const tenantId = useUserTenantId();

  return <EnqueteInner tenantId={tenantId} euqueteForm={defaultValues} />;
};
