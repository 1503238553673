import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'passwordCreate.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'passwordCreate.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordCreate.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue: '新しいパスワードを入力してください。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'passwordCreate.passwordItemName',
    label: '入力欄名 新パスワード',
    type: 'Text',
    defaultValue: '新しいパスワード',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordCreate.passwordConfirmationItemName',
    label: '入力欄名 新パスワード（確認用）',
    type: 'Text',
    defaultValue: '新しいパスワード（確認用）',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordCreate.continueButtonName',
    label: 'ボタン名 パスワードの更新',
    type: 'Text',
    defaultValue: 'パスワードの更新',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
