import {
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ApiWhiteListSettingForm } from 'admin/components/service/setting/apiWhiteListSettingForm';
import { useSettingDetail } from 'admin/hooks/service/setting/useSettingDetail';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type FetchApiWhiteListDrawerProps = {
  onClose: () => void;
};

export const fetchApiWhiteListDrawer: FC<FetchApiWhiteListDrawerProps> = memo(
  ({ onClose }: FetchApiWhiteListDrawerProps) => {
    const { data: settingDetail } = useSettingDetail();

    if (!settingDetail) {
      return null;
    }

    return (
      <ApiWhiteListSettingForm
        settingDetail={settingDetail}
        onClose={onClose}
      />
    );
  },
);

export const FetchApiWhiteListDrawer = memo(
  withSuspenseAndErrorBoundary(fetchApiWhiteListDrawer, {
    ErrorComponent: (
      <ErrorContents name="API通信のホワイトリスト設定ドロワー" />
    ),
    LoadingComponent: <LoadingSpinner />,
  }),
);

type ApiWhiteListDrawerProps = {
  onClose: () => void;
};

export const ApiWhiteListDrawer: FC<ApiWhiteListDrawerProps> = memo(
  ({ onClose }: ApiWhiteListDrawerProps) => (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderWidth="1px" pb="24px" fontFamily="Hiragino Sans">
          <Text>API通信のホワイトリスト設定</Text>
        </DrawerHeader>
        <FetchApiWhiteListDrawer onClose={onClose} />
        <DrawerCloseButton />
      </DrawerContent>
    </>
  ),
);
