import { AuthHubEditor } from 'admin/components/authHub/authHubEditor';
import { TRANSACTION_MAIL_PERSONAL_TYPE } from 'admin/define/idPoolConsumer/transactionMail';
import { useAdminAuth } from 'admin/hooks/useAdminAuth';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { useIdPoolConsumerUniikey } from 'admin/hooks/userPool/useIdPoolConsumerUniikey';
import { Admin } from 'admin/pages';
import { AuthHub } from 'admin/pages/authHub';
import { AuthHubDetail } from 'admin/pages/authHub/authHubDetail';
import { AuthHubListGroupedByUserPool } from 'admin/pages/authHub/authHubListGroupedByUserPool';
import { IdPoolConsumer } from 'admin/pages/idPoolConsumer';
import { Attribute } from 'admin/pages/idPoolConsumer/attribute';
import { AttributeGroup } from 'admin/pages/idPoolConsumer/attribute/group';
import { FormGroup } from 'admin/pages/idPoolConsumer/formGroup';
import { Detail } from 'admin/pages/idPoolConsumer/formGroup/detail';
import { IdPoolConsumerIndex } from 'admin/pages/idPoolConsumer/Index/index';
import { Master } from 'admin/pages/idPoolConsumer/master';
import { Code } from 'admin/pages/idPoolConsumer/master/code';
import { Newsletter } from 'admin/pages/idPoolConsumer/newsletter';
import { Terms } from 'admin/pages/idPoolConsumer/terms';
import { TermsIndex } from 'admin/pages/idPoolConsumer/terms/index/index';
import { Log } from 'admin/pages/idPoolConsumer/terms/log';
import { TransactionMail } from 'admin/pages/idPoolConsumer/transactionMail';
import { TransactionMailEdit } from 'admin/pages/idPoolConsumer/transactionMail/transactionMailEdit';
import { TransactionMails } from 'admin/pages/idPoolConsumer/transactionMail/transactionMails';
import { TransactionMailSetting } from 'admin/pages/idPoolConsumer/transactionMail/transactionMailSetting';
import { UserInfo } from 'admin/pages/idPoolConsumer/userInfo';
import { JsonPath } from 'admin/pages/jsonPath';
import { JsonPathExample } from 'admin/pages/jsonPathExample';
import { Member } from 'admin/pages/member';
import { MemberDetail } from 'admin/pages/member/detail';
import { MemberLog } from 'admin/pages/member/detail/log';
import { MemberIndex } from 'admin/pages/member/Index/index';
import { Service as MemberService } from 'admin/pages/member/service';
import { SAML } from 'admin/pages/saml';
import { Service } from 'admin/pages/service';
import { Attribute as ServiceAttribute } from 'admin/pages/service/attribute';
import { ServiceIndex } from 'admin/pages/service/Index/index';
import { Newsletter as ServiceNewsletter } from 'admin/pages/service/newsletter';
import { Setting as ServiceSetting } from 'admin/pages/service/setting';
import { ServiseRule } from 'admin/pages/service/terms';
import { TenantUsers } from 'admin/pages/tenantUsers';
import { Users } from 'admin/pages/users';
import { TransactionMailPersonalType } from 'admin/types/userPool/transactionMail';
import { FC, useMemo } from 'react';
import {
  Navigate,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';

const redirectPath = '/admin';

// 組織管理画面アクセス制御処理HOC
const withAuthCheckRoutingControl = (
  WrappedComponent: React.ComponentType,
  redirect: string,
  menuName: string,
): FC => {
  const WithAuthCheckRoutingControl: FC = (props) => {
    const { isAllowedMenuPermission } = useAdminAuth();
    // アクセスが許可されていない場合は設定されているリダイレクトへ遷移させる
    if (!isAllowedMenuPermission(menuName)) return <Navigate to={redirect} />;

    return <WrappedComponent {...props} />;
  };

  return WithAuthCheckRoutingControl;
};

const withUniikeyAuthCheckRouter = (
  WrappedComponent: React.ComponentType,
  pathName: string,
) => {
  const WithUniikeyAuthCheckRouter: FC = (props) => {
    const { isShowUserPool } = useUserCorporationInfo();

    // 利用種別IDがuniikeyか判定
    const { isIdPoolConsumerUniikey } = useIdPoolConsumerUniikey();
    const params = useParams();

    const idPoolConsumerId = useMemo(
      () => params.idPoolConsumerId ?? '',
      [params],
    );

    // ユーザープールの権限があるか
    const isShowMenu = useMemo<boolean>(
      () => isShowUserPool(idPoolConsumerId),
      [idPoolConsumerId, isShowUserPool],
    );

    if (!isIdPoolConsumerUniikey || !isShowMenu) {
      return <Navigate to={pathName} />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithUniikeyAuthCheckRouter;
};

const withTransactionMailTypeCheckRouter = (
  WrappedComponent: React.ComponentType,
  pathName: string,
) => {
  const WithTransactionMailTypeCheckRouter: FC = (props) => {
    const location = useLocation();
    const params = useParams();
    const transactionMailType = useMemo<TransactionMailPersonalType>(
      () => params?.transactionMailType as TransactionMailPersonalType,
      [params],
    );

    // transactionMail TOPページではmailTypeのチェックを行わない
    if (
      !location.pathname.endsWith('/transactionMail') &&
      !TRANSACTION_MAIL_PERSONAL_TYPE.includes(transactionMailType)
    ) {
      return <Navigate to={pathName} />;
    }

    // 元のHOCをラップして適用
    const ExtendsUniikeyAuthHOC = withUniikeyAuthCheckRouter(
      WrappedComponent,
      pathName,
    );

    return <ExtendsUniikeyAuthHOC {...props} />;
  };

  return WithTransactionMailTypeCheckRouter;
};

// HOCを適用する
const AdminUsersWithAuthCheckRouting = withAuthCheckRoutingControl(
  Users,
  redirectPath,
  'users',
);
const AdminTenantUsersWithAuthCheckRouting = withAuthCheckRoutingControl(
  TenantUsers,
  redirectPath,
  'tenantUsers',
);
const AdminSamlWithAuthCheckRouting = withAuthCheckRoutingControl(
  SAML,
  redirectPath,
  'saml',
);
const AdminIdPoolConsumerWithAuthCheckRouting = withAuthCheckRoutingControl(
  IdPoolConsumer,
  redirectPath,
  'idPoolConsumer',
);
const AdminMemberWithAuthCheckRouting = withAuthCheckRoutingControl(
  Member,
  redirectPath,
  'member',
);
const AdminServiceWithAuthCheckRouting = withAuthCheckRoutingControl(
  Service,
  redirectPath,
  'service',
);

const AdminRuleWithUniikeyAuthCheckRouting = withUniikeyAuthCheckRouter(
  Terms,
  redirectPath,
);

const AdminRuleWithTransactionMailTypeRouting =
  withTransactionMailTypeCheckRouter(TransactionMail, redirectPath);

const AdminAuthHubWithAuthCheckRouting = withAuthCheckRoutingControl(
  AuthHub,
  redirectPath,
  'authHub',
);

export const AdminRoutes: RouteObject[] = [
  // 開発確認用
  // {
  //   path: 'sample',
  //   element: <Sample />,
  // },
  {
    path: '',
    element: <Admin />,
  },

  {
    path: 'users',
    element: <AdminUsersWithAuthCheckRouting />,
  },
  {
    path: 'tenantUsers',
    element: <AdminTenantUsersWithAuthCheckRouting />,
  },
  {
    path: 'saml',
    element: <AdminSamlWithAuthCheckRouting />,
  },
  {
    path: 'member',
    element: <AdminMemberWithAuthCheckRouting />,
    children: [
      { path: '', element: <MemberIndex /> },
      {
        path: ':idPoolConsumerId/:memberId',
        children: [
          {
            path: '',
            element: <MemberDetail />,
          },
          {
            path: 'log',
            element: <MemberLog />,
          },
        ],
      },
      { path: 'service/:serviceId', element: <MemberService /> },
    ],
  },
  {
    path: 'authHub',
    element: <AdminAuthHubWithAuthCheckRouting />,
    children: [
      {
        path: '',
        element: <AuthHubListGroupedByUserPool />,
      },
      {
        path: ':userPoolId',
        element: <AuthHub />,
        children: [
          {
            path: ':authHubId',
            element: <AuthHubDetail />,
            children: [
              // テーマ
              {
                path: 'theme',
                element: <AuthHubEditor target="theme" />,
              },
              // ヘッダー
              {
                path: 'header',
                element: <AuthHubEditor target="header" />,
              },
              // フッター
              {
                path: 'footer',
                element: <AuthHubEditor target="footer" />,
              },
              // 新規登録画面
              {
                path: 'register',
                element: <AuthHubEditor target="register" />,
              },
              // 認証コード入力画面
              {
                path: 'verificationCodeEntry',
                element: <AuthHubEditor target="verificationCodeEntry" />,
              },
              // 会員情報新規入力画面
              {
                path: 'memberInfoEntry',
                element: <AuthHubEditor target="memberInfoEntry" />,
              },
              // 入力情報確認画面
              {
                path: 'infoConfirmation',
                element: <AuthHubEditor target="infoConfirmation" />,
              },
              // 新規登録完了画面
              {
                path: 'registrationComplete',
                element: <AuthHubEditor target="registrationComplete" />,
              },
              // ログイン
              {
                path: 'login',
                element: <AuthHubEditor target="login" />,
              },
              // パスワード作成画面
              {
                path: 'passwordCreate',
                element: <AuthHubEditor target="passwordCreate" />,
              },
              // パスワード未設定画面
              {
                path: 'passwordUnset',
                element: <AuthHubEditor target="passwordUnset" />,
              },
              // パスワードリセット画面
              {
                path: 'passwordReset',
                element: <AuthHubEditor target="passwordReset" />,
              },
              // 新パスワード入力画面
              {
                path: 'newPasswordEntry',
                element: <AuthHubEditor target="newPasswordEntry" />,
              },
              // 初回情報入力画面
              {
                path: 'firstInfoEntry',
                element: <AuthHubEditor target="firstInfoEntry" />,
              },
              // 不足情報入力画面
              {
                path: 'missingInfoEntry',
                element: <AuthHubEditor target="missingInfoEntry" />,
              },
              // 完了画面
              {
                path: 'complete',
                element: <AuthHubEditor target="complete" />,
              },
              // マイページ画面
              {
                path: 'mypage',
                element: <AuthHubEditor target="mypage" />,
              },
              // 会員情報確認画面
              {
                path: 'memberInfoConfirm',
                element: <AuthHubEditor target="memberInfoConfirm" />,
              },
              // 会員情報確認・変更画面
              {
                path: 'memberInfoChange',
                element: <AuthHubEditor target="memberInfoChange" />,
              },
              // メールアドレス変更画面
              {
                path: 'emailChange',
                element: <AuthHubEditor target="emailChange" />,
              },
              // 新メアド入力画面
              {
                path: 'newEmailEntry',
                element: <AuthHubEditor target="newEmailEntry" />,
              },
              // パスワード変更画面
              {
                path: 'passwordChange',
                element: <AuthHubEditor target="passwordChange" />,
              },
              // 退会画面
              {
                path: 'withdrawal',
                element: <AuthHubEditor target="withdrawal" />,
              },
              // 退会確認画面
              {
                path: 'withdrawalConfirmation',
                element: <AuthHubEditor target="withdrawalConfirmation" />,
              },
              // 退会完了画面
              {
                path: 'withdrawalComplete',
                element: <AuthHubEditor target="withdrawalComplete" />,
              },
              // 各ページに埋め込むタグ
              {
                path: 'embeddedTags',
                element: <AuthHubEditor target="embeddedTags" />,
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path: 'setting',
  //   element: <Setting />,
  //   children: [{ path: 'user', element: <SettingUser /> }],
  // },
  {
    path: 'idPoolConsumer',
    element: <AdminIdPoolConsumerWithAuthCheckRouting />,
    children: [
      { path: '', element: <IdPoolConsumerIndex /> },
      { path: ':idPoolConsumerId/attribute', element: <Attribute /> },
      {
        path: ':idPoolConsumerId/attribute',
        children: [
          {
            path: '',
            element: <Attribute />,
          },
          {
            path: 'group',
            element: <AttributeGroup />,
          },
        ],
      },
      {
        path: ':idPoolConsumerId/jsonPath',
        element: <JsonPath />,
      },
      { path: ':idPoolConsumerId/userInfo', element: <UserInfo /> },
      {
        path: ':idPoolConsumerId/master',
        children: [
          {
            path: '',
            element: <Master />,
          },
          {
            path: ':masterId/code',
            element: <Code />,
          },
        ],
      },
      {
        path: ':idPoolConsumerId/formGroup',
        children: [
          {
            path: '',
            element: <FormGroup />,
          },
          {
            path: 'detail/',
            element: <Detail />,
            children: [
              {
                path: ':formGroupId/',
                element: <Detail />,
              },
            ],
          },
        ],
      },
      {
        path: ':idPoolConsumerId/terms',

        element: <AdminRuleWithUniikeyAuthCheckRouting />,
        children: [
          { path: '', element: <TermsIndex /> },
          { path: ':termId/log', element: <Log /> },
        ],
      },
      {
        path: ':idPoolConsumerId/newsletter',
        element: <Newsletter />,
      },
      {
        path: ':idPoolConsumerId/transactionMail',
        element: <AdminRuleWithTransactionMailTypeRouting />,
        children: [
          {
            path: '',
            element: <TransactionMailSetting />,
          },
          {
            path: ':transactionMailType',
            element: <TransactionMails />,
          },
          {
            path: ':transactionMailType/wallEditor/',
            element: <TransactionMailEdit />,
            children: [
              {
                path: ':transactionMailId',
                element: <TransactionMailEdit />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'service',
    element: <AdminServiceWithAuthCheckRouting />,
    children: [
      { path: '', element: <ServiceIndex /> },
      { path: 'setting', element: <ServiceSetting /> },
      {
        path: ':tenantId/:userPoolChildId/attribute',
        element: <ServiceAttribute />,
      },
      {
        path: ':tenantId/:userPoolChildId/newsletter',
        element: <ServiceNewsletter />,
      },
      { path: ':tenantId/:userPoolChildId/terms', element: <ServiseRule /> },
    ],
  },

  {
    path: 'jsonPathExample',
    element: <JsonPathExample />,
  },
];
