// import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { CustomMenuListItem } from 'admin/components/sideMenu/customeMenuListItem';
import { useMenuPermission } from 'admin/hooks/useMenuPermission';
import { CustomMenuList } from 'components/chakra/sidemenu';
import { FC, memo } from 'react';
import { BiCheckShield } from 'react-icons/bi';
import {
  MdAccountTree,
  MdCorporateFare,
  MdLockOpen,
  MdOutlineGroup,
  MdOutlineSettingsApplications,
  MdOutlineSpaceDashboard,
} from 'react-icons/md';
import { PiUserCircleBold } from 'react-icons/pi';
import { useLocation } from 'react-router-dom';
// import { FiUser } from 'react-icons/fi';

type Props = {
  isOpen: boolean;
};

export const SideMenuList: FC<Props> = memo(({ isOpen = false }) => {
  const location = useLocation();

  const {
    hasAllowedIdPoolConsumer,
    hasAllowedService,
    hasAllowedSaml,
    hasAllowedMember,
    hasAllowedAuthHub,
  } = useMenuPermission();

  return (
    <CustomMenuList flexGrow={1}>
      <CustomMenuListItem
        fontSize="20px"
        to="/admin"
        name="TOPページ"
        icon={MdOutlineSpaceDashboard}
        isActive={
          !location.pathname.includes('/users') &&
          !location.pathname.includes('/tenantUsers') &&
          !location.pathname.includes('/idPoolConsumer') &&
          !location.pathname.includes('/saml') &&
          !location.pathname.includes('/idPoolConsumer') &&
          !location.pathname.includes('/service') &&
          !location.pathname.includes('/setting') &&
          !location.pathname.includes('/member')
        }
        isOpen={isOpen}
      />
      <CustomMenuListItem
        fontSize="20px"
        to="/admin/users"
        name="組織アカウント管理"
        icon={MdCorporateFare}
        isActive={location.pathname.includes('/users')}
        isOpen={isOpen}
      />

      <CustomMenuListItem
        fontSize="20px"
        to="/admin/tenantUsers"
        name="テナント アカウント管理"
        icon={PiUserCircleBold}
        isActive={location.pathname.includes('/tenantUsers')}
        isOpen={isOpen}
      />

      {/* TODO: 3月末対象外 */}
      {hasAllowedIdPoolConsumer && (
        <CustomMenuListItem
          fontSize="20px"
          to="/admin/idPoolConsumer"
          name="IDシステム設定管理"
          icon={BiCheckShield}
          isActive={location.pathname.includes('/idPoolConsumer')}
          isOpen={isOpen}
        />
      )}

      {/* <CustomMenuListItem to="/admin/member" name="会員管理" icon={FiUser} />
      <CustomMenuListItem to="/admin/setting/" name="設定" icon={FiUser} />
      <CustomMenuListItem
        to="/admin/idPoolConsumer/"
        name="IDシステム設定管理"
        icon={FiUser}
        isOpen={isOpen}
      /> */}

      {hasAllowedSaml && (
        <CustomMenuListItem
          to="/admin/saml/"
          fontSize="20px"
          name="SAML設定"
          icon={MdLockOpen}
          color="black"
          isActive={location.pathname.includes('/saml')}
          isOpen={isOpen}
        />
      )}
      {hasAllowedService && (
        <CustomMenuListItem
          to="/admin/service/"
          fontSize="20px"
          name="テナント設定管理"
          icon={MdOutlineSettingsApplications}
          isActive={location.pathname.includes('/admin/service')}
          isOpen={isOpen}
        />
      )}
      {hasAllowedMember && (
        <CustomMenuListItem
          to="/admin/member/"
          fontSize="20px"
          name="会員管理"
          icon={MdOutlineGroup}
          color="black"
          isActive={location.pathname.includes('/member')}
          isOpen={isOpen}
        />
      )}

      {hasAllowedAuthHub && (
        <CustomMenuListItem
          to="/admin/authHub/"
          fontSize="20px"
          name="Auth Hub設定"
          icon={MdAccountTree}
          isActive={location.pathname.includes('/authHub')}
          isOpen={isOpen}
        />
      )}

      {/* <Box display="flex" flexDirection="column" mt="calc(100vh - 350px)">
        <CustomMenuListItem
          fontSize="20px"
          href="https://uniikey.notion.site/Uniikey-Experience-Cloud-da7a975922ae43aa8372a703b3ec3e27"
          name="ヘルプ"
          tLabel="ヘルプ：利用マニュアル・問い合わせ（別タブで開く）"
          icon={FiHelpCircle}
          isOpen={isOpen}
        />
        <CustomMenuListItem
          fontSize="20px"
          to="/admin/setting/"
          name="設定"
          icon={FiSettings}
          isActive={location.pathname.includes('/setting')}
          isOpen={isOpen}
        />
      </Box> */}
    </CustomMenuList>
  );
});
