import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'login.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'ログイン',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'login.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'ログイン',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'login.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'login.passwordItemName',
    label: '入力欄名 パスワード',
    type: 'Text',
    defaultValue: 'パスワード',
    options: itemNameOptions,
    elementOptions: {},
  },
  // {
  //   name: 'login.nextAutoLoginGuide',
  //   label: '次回自動ログイン説明文',
  //   type: 'Textarea',
  //   defaultValue: '次回から自動ログインする',
  //   options: {
  //     maxLength: maxLength(50),
  //   },
  //   elementOptions: {},
  // },
  // captcha	-
  {
    name: 'login.loginButtonName',
    label: 'ボタン名 ログイン',
    type: 'Text',
    defaultValue: 'ログイン',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'login.passwordRecoveryGuide',
    label: '案内文 パスワードリセット',
    type: 'Textarea',
    defaultValue: 'パスワードリセットはこちら',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  // SNS選択	-
  {
    name: 'login.unregisteredUserGuide',
    label: '案内文 未登録者向け案内',
    type: 'Textarea',
    defaultValue: 'アカウントをお持ちでない方',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'login.signupButtonName',
    label: 'ボタン名 新規登録',
    type: 'Text',
    defaultValue: '新規登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
