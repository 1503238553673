import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  Enquete,
  EnqueteFormType,
  EnqueteSurveyForm,
  EnqueteSurveyFormDefaultValue,
} from 'api/enquete/types';
import { ChangeLocationDialog, ErrorTextMsg } from 'components/common/atoms';
import { BackToEnqueteListButton } from 'components/enquete/atoms/BackToEnqueteListButton';
import { EnqueteComplete } from 'components/enquete/atoms/EnqueteComplete';
import { EnqueteDesignSkin } from 'components/enquete/atoms/EnqueteDesignSkin';
import { EnqueteTitle } from 'components/enquete/atoms/EnqueteTitle';
import { EnquetePreviewModal } from 'components/enquete/molecules/EnquetePreviewModal';
import { DraggableListItem } from 'components/enquete/organisms/DraggableListItem';
import { useCreateEnquete } from 'hooks/enquete/useCreateEnquete';
import { useEditEnquete } from 'hooks/enquete/useEditEnquete';
import { useSkinList } from 'hooks/enquete/useSkinList';
import { useMailMagazineList } from 'hooks/mail/useMailMagazineList';
import { useBrowserBackControl } from 'hooks/useBrowserBackControl';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserInfo } from 'hooks/useUserInfo';
import { memo, RefObject, useCallback, useEffect, useState, VFC } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { HEADER_HEIGHT, NOTIFICATION_MARGIN } from 'theme';
import { randStr } from 'utils/str';

const defaultValues: EnqueteSurveyForm = EnqueteSurveyFormDefaultValue;

type Props = {
  tenantId: string;
  euqueteForm: Enquete;
  formIdParams?: string;
};

export const EnqueteInner: VFC<Props> = memo(
  ({ formIdParams = '', tenantId, euqueteForm }: Props) => {
    const toast = useCustomToast();
    const [uneditableMessage, setUneditableMessage] = useState('');
    const [isCreate] = useState(formIdParams === '');
    const [initFields, setInitFields] = useState(false);
    const [copySelectType, setCopySelectType] = useState('');
    const { isStaff, isAssistant } = useUserInfo();
    const mailMagazineList = useMailMagazineList('');
    const { skinList } = useSkinList({ perPage: '200' });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isOpenConfirm,
      onOpen: onOpenConfirm,
      onClose: onCloseConfirm,
    } = useDisclosure();
    const methods = useForm<EnqueteSurveyForm>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues,
    });
    const {
      control,
      setValue,
      getValues,
      setError,
      clearErrors,
      handleSubmit,
      formState: { isDirty, errors },
    } = methods;
    const { onSubmit: onCreateSubmit, isLoading: isCreateLoading } =
      useCreateEnquete({ setError });
    const { onSubmit: onEditSubmit, isLoading: isEditLoading } = useEditEnquete(
      {
        setError,
      },
    );
    // 編集中かどうかをstateで管理
    const [isEdited, setIsEdited] = useState(false);
    // ページ遷移カスタムnavigate
    const { resetEditState } = useCustomNavigate({
      isEdited,
      callback: onOpenConfirm,
    });
    // ブラウザバック制御
    useBrowserBackControl({ isEdited, onOpen: onOpenConfirm });

    const { fields, append, remove, move, swap, insert } = useFieldArray({
      control,
      name: 'enqueteContents',
      keyName: 'key',
    });

    /**
     * 修正が加えられたかどうか
     */
    useEffect(() => {
      if (isDirty) setIsEdited(true);
    }, [isDirty]);

    // ドロップ時の処理
    const handleDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) {
          return;
        }

        move(result.source.index, result.destination.index);
      },
      [move],
    );

    // dndIdのユニーク値生成
    const uniqueId = useCallback(() => {
      // dndIdのユニーク値生成のための再帰処理
      const uid = () => {
        const str = randStr();
        if (fields.some((form) => form.dndId === str)) uid();

        return str;
      };

      return uid();
    }, [fields]);

    // 初期処理
    useEffect(() => {
      if (!initFields) {
        setInitFields(true);
        // 新規作成時
        if (isCreate) {
          const uniqueStr = randStr();
          append({
            dndId: `${uniqueStr}`,
            type: '',
          });
        } else {
          setValue('enqueteContents', euqueteForm.enqueteContents);
          setValue('thanks', euqueteForm.thanks || '');
          setValue('thanksHtml', euqueteForm.thanksHtml || '');
          setValue('thanksButtonText', euqueteForm.thanksButtonText || '');
          setValue('thanksButtonUrl', euqueteForm.thanksButtonUrl || '');
          setValue('submitButtonText', euqueteForm.submitButtonText || '');
          setValue('title', euqueteForm.title);
          setValue('skin', euqueteForm.skin);
        }
      }
    }, [initFields, isCreate, euqueteForm, append, setValue]);

    useEffect(() => {
      const errorMessage =
        euqueteForm.isPublic && (isStaff || isAssistant)
          ? '公開されているため、編集することができません。'
          : '';
      setValue('uneditableState', errorMessage !== '');
      setUneditableMessage(errorMessage);
    }, [setValue, euqueteForm, isStaff, isAssistant]);

    // 追加ボタン押下時の処理 (末尾)
    const onAddElement = useCallback(() => {
      append({
        dndId: uniqueId(),
        type: '',
      });
    }, [append, uniqueId]);

    const onInsertElement = useCallback(
      (index: number, enqueteContents: EnqueteFormType) => {
        insert(index, {
          ...enqueteContents,
          dndId: uniqueId(),
        });
      },
      [insert],
    );

    // 追加ボタン押下時の処理 (項目の下に追加)
    const onAdd = useCallback(
      (index: number) => {
        insert(index + 1, { dndId: uniqueId(), type: '' });
      },
      [insert, uniqueId],
    );

    // 削除ボタン押下時の処理
    const onDeleteElement = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove],
    );

    // 上へボタン押下時の処理
    const onUp = useCallback(
      (
        index: number,
        isFirst: boolean,
        curRef: RefObject<HTMLDivElement>,
        prevRef: RefObject<HTMLDivElement> | null,
      ) => {
        if (isFirst) return;

        swap(index, index - 1);

        const rect1 = curRef.current?.getBoundingClientRect();
        const rect2 = prevRef?.current?.getBoundingClientRect();
        if (!rect1 || !rect2) return;

        const moveY = rect2.top - rect1.top;
        window.scrollBy(0, moveY);
      },
      [swap],
    );

    // 下へボタン押下時の処理
    const onDown = useCallback(
      (
        index: number,
        isLast: boolean,
        curRef: RefObject<HTMLDivElement>,
        nextRef: RefObject<HTMLDivElement> | null,
      ) => {
        if (isLast) return;

        swap(index, index + 1);

        const rect1 = curRef.current?.getBoundingClientRect();
        const rect2 = nextRef?.current?.getBoundingClientRect();
        if (!rect1 || !rect2) return;

        const moveY = rect2.bottom - rect1.bottom;
        window.scrollBy(0, moveY);
      },
      [swap],
    );

    const onCopy = useCallback(
      (index: number) => {
        const enquetes = getValues('enqueteContents');
        const newFields: EnqueteFormType = {
          ...enquetes[index],
        };

        // EnqueteFormType型の必須フィールド
        const exclusionKeys = [
          'dndId',
          'type',
          'isCondFlg',
          'condTitle',
          'condItems',
        ];
        // 使用していないオプショナルプロパティを全て削除する
        Object.keys(newFields).forEach((key) => {
          const fieldKey = key as keyof EnqueteFormType;
          if (!exclusionKeys.includes(fieldKey)) {
            if (newFields[fieldKey] === null) {
              delete newFields[fieldKey];
            }
          }
        });
        delete newFields.id;
        newFields.dndId = uniqueId();
        setCopySelectType(newFields.type);
        insert(index + 1, newFields);
      },
      [insert, getValues, uniqueId],
    );

    // 送信
    const onSubmitHandler: SubmitHandler<EnqueteSurveyForm> = (data) => {
      // 一度全てのエラーを削除する
      let isError = false;
      clearErrors();

      const title = getValues('title');
      const enquetes = getValues('enqueteContents');
      // react hook formのバリデーションが効かないため、入力が存在しない場合は手動バリデーションを実行する
      if (title === '') {
        setError('title', {
          type: 'manual',
          message: 'フォームタイトルを入力してください',
        });
        isError = true;
      }
      // 入力フォームが0の場合は保存しない
      if (enquetes.length === 0) {
        isError = true;
      }
      enquetes.forEach((item, idx) => {
        if (item.type !== '') return;
        setError(`enqueteContents.${idx}.type`, {
          type: 'manual',
          message: 'パーツを選択してください',
        });
        isError = true;
      });

      // 送信する必要のない項目を削除
      const enqueteContents = enquetes.map((v) => {
        const item = { ...v };
        delete item.typeOrUserAttrId;

        return item;
      });

      const submitForm: EnqueteSurveyForm = {
        ...defaultValues,
        ...data,
        enqueteContents,
        title: getValues('title'),
        thanks: getValues('thanks'),
        thanksHtml: getValues('thanksHtml'),
        thanksButtonText: getValues('thanksButtonText'),
        thanksButtonUrl: getValues('thanksButtonUrl'),
        submitButtonText: getValues('submitButtonText'),
        skin: getValues('skin'),
      };

      if (isError) {
        toast({
          status: 'error',
          position: 'bottom',
          duration: 4000,
          isClosable: true,
          title: '入力エラーがあります。',
          description: 'エラーを確認してください。',
        });

        return;
      }

      // 新規作成
      if (isCreate) {
        onCreateSubmit({
          tenantId,
          enqueteSurverForm: submitForm,
        });
        resetEditState();
      }

      // 修正
      if (!isCreate) {
        onEditSubmit({
          tenantId,
          enqueteId: euqueteForm.id,
          enqueteSurverForm: submitForm,
        });
        resetEditState();
      }
    };

    return (
      <Box w="100%" pt={30} pb={150}>
        <FormProvider {...methods}>
          <form id="enquete-form">
            <VStack
              w="100%"
              spacing={4}
              flex={1}
              pb={10}
              pt="3.5rem"
              alignItems="center"
            >
              {/* ヘッダー */}
              <Grid
                templateRows="1fr"
                templateColumns="auto 1fr auto auto"
                pos="fixed"
                w="calc(100% - 60px)"
                py={2}
                zIndex={3}
                right={0}
                top={`calc(${HEADER_HEIGHT} + ${NOTIFICATION_MARGIN})`}
                bg="#EEE"
                borderTop="solid 1px #EEE"
                alignItems="center"
              >
                <GridItem px={4}>
                  <BackToEnqueteListButton path="/enquete" />
                </GridItem>
                {/* タイトル */}
                <GridItem px={4}>
                  <EnqueteTitle />
                </GridItem>
                {/* ヘッダーボタン */}
                <GridItem pr={4}>
                  <ButtonGroup spacing={4}>
                    <Tooltip
                      label="プレビュー機能は、保存した後に利用可能となります。保存を行なった後、実際のページ内容を確認するためにご使用ください。"
                      bg="teal.50"
                      color="gray.800"
                      isDisabled={!isEdited}
                    >
                      <Box>
                        <Button
                          type="button"
                          bgColor="#ACCFFC"
                          _hover={{
                            bgColor: '#CCE1FD',
                          }}
                          _active={{
                            bgColor: '#CCE1FD',
                          }}
                          color="#000"
                          fontSize={12}
                          name="preview"
                          size="md"
                          onClick={() => onOpen()}
                          // 新規作成時は常に押せないが編集時と同じレイアウトを維持する為に表示する
                          disabled={
                            isCreate ||
                            getValues('uneditableState') ||
                            isEdited ||
                            isEditLoading
                          }
                        >
                          プレビュー
                        </Button>
                      </Box>
                    </Tooltip>
                    <Button
                      type="button"
                      name="submit"
                      size="md"
                      bgColor="#4880c8"
                      _hover={{
                        bgColor: '#6D9BD5',
                      }}
                      _active={{
                        bgColor: '#6D9BD5',
                      }}
                      color="#FFF"
                      fontSize={16}
                      onClick={handleSubmit(onSubmitHandler)}
                      isLoading={isCreateLoading || isEditLoading}
                      disabled={
                        // バリデーションエラーが発生した際は保存ボタン押下出来ないように制御する想定だったが、
                        // 不具合で、上記状態でも保存ボタンが押下できる状態であることが判明した（20240527時点）
                        // 共通化対応時に上記不具合が解消できたものの、現時点で本番環境はエラー時もdisabledにならない挙動となっていることから
                        // 本番環境の挙動に合わせるため下記の条件を適用させないようコメントアウトする(itsukaichi)
                        // Object.keys(errors).length > 0 ||
                        getValues('uneditableState') ||
                        isCreateLoading ||
                        isEditLoading
                      }
                    >
                      保存
                    </Button>
                  </ButtonGroup>
                </GridItem>
              </Grid>

              {uneditableMessage !== '' && (
                <Box m={0} p={0} w="100%">
                  <ErrorTextMsg msg={uneditableMessage} />
                </Box>
              )}

              <Heading
                as="h2"
                size="md"
                pt={0}
                pb={2}
                marginBottom="4px !important"
                w="100%"
                borderBottom="2px solid #4299e1"
              >
                フォーム表示内容
              </Heading>

              {/* フォーム表示内容が存在しない場合 */}
              {getValues('enqueteContents').length === 0 && (
                <Box w="100%">
                  <ErrorTextMsg
                    fontSize="md"
                    msg="最低１つ以上の設問を設定してください。"
                  />
                </Box>
              )}

              {/* フォームエレメント */}
              <DraggableListItem<EnqueteSurveyForm>
                baseName="enqueteContents"
                list={fields}
                errors={errors}
                mailMagazineList={mailMagazineList || []}
                handleDragEnd={handleDragEnd}
                copySelectType={copySelectType}
                onUp={onUp}
                onDown={onDown}
                onAdd={onAdd}
                onAddElement={onAddElement}
                onInsertElement={onInsertElement}
                onCopy={onCopy}
                onDelete={onDeleteElement}
                apiData={euqueteForm.enqueteContents}
                isThanksFlg={
                  euqueteForm.isSendThanksMail ||
                  (euqueteForm.postTarget === 1 &&
                    euqueteForm.answerPossibleNum === 2) ||
                  false
                }
                setIsEdited={setIsEdited}
                uniqueId={uniqueId}
              />
              <Button
                colorScheme="blue"
                onClick={onAddElement}
                mt={2}
                isDisabled={getValues('uneditableState')}
              >
                項目追加
              </Button>
            </VStack>

            {/* フォーム完了ページ文言設定 */}
            <EnqueteComplete<EnqueteSurveyForm>
              baseName="enqueteContents"
              setIsEdited={setIsEdited}
            />

            {/* デザインスキン設定 */}
            <EnqueteDesignSkin skinList={skinList} />

            {/* プレビュー */}
            <EnquetePreviewModal
              isOpen={isOpen}
              onClose={onClose}
              enqueteFormId={formIdParams}
              previewCode={euqueteForm?.previewCode || ''}
            />
          </form>
        </FormProvider>
        <ChangeLocationDialog
          isOpen={isOpenConfirm}
          onClose={onCloseConfirm}
          setIsEdited={setIsEdited}
        />
      </Box>
    );
  },
);
