import { API_MESSAGES } from 'admin/define/apiMessages';
import { CustomError } from 'admin/error/CustomError';
import { settingSchema } from 'admin/schema/service/setting';
import { SettingType } from 'admin/types/service/setting';
import { getDummySuccessResponse } from 'admin/utils/getDummyData';
import { ZodError } from 'zod';

const dummyData: SettingType = {
  id: 'dummy-data-1',
  label: '個別サービスB',
  usageEnvironment: 'ブラウザ（JavaScript）',
  purpose: 'XXXXXXXXXXXX',
  contentType: 'application/json;',
  xApiKey: 'XXXXXXXXXXXX',
  accessKey: 'xxxxxx',
  secretKey: 'yyyyyyyyyyyyyyyyyy',
  authorization: 'Bearer XXXXXXXXXXXXXXXXXXXX=',
  whiteList: '000.000.000',
};

export const getSetting = async (): Promise<SettingType> => {
  // TODO: APIの繋ぎ込みの際に実装する
  // const response = await request<getIdPoolConsumersType>({
  //   path,
  //   method: 'get',
  //   options,
  // });
  await new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('Timer done');
    }, 1000);
  });

  // ダミーデータ
  const response = await getDummySuccessResponse<SettingType>(dummyData);

  if (response.hasError) {
    if (response.status === 404) {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SETTING.NOT_FOUND, {
        path: 'dummy',
        status: response.status,
      });
    } else {
      throw new CustomError(API_MESSAGES.SERVICE.GET_SETTING.ERROR, {
        path: 'dummy',
        status: response.status,
      });
    }
  }
  try {
    settingSchema.parse(response.data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new CustomError(error.message, {
        path: 'dummy',
        status: response.status,
        logLevel: 'error',
      });
    }
  }

  return response.data;
};
