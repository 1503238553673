import {
  headTitleOptions,
  buttonNameOptions,
  screenNameOptions,
  screenDescriptionOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'withdrawalComplete.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '退会完了',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalComplete.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '退会完了',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalComplete.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '退会が完了いたしました。\n ご利用いただきありがとうございました。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalConfirmation.backButtonName',
    label: 'ボタン名 TOPページに戻る',
    type: 'Text',
    defaultValue: 'TOPページに戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
