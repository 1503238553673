import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AuthHubList } from 'admin/components/authHub/authHubList';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { useUserPool } from 'admin/hooks/userPool/useUserPool';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import {
  AUTH_CODE_TYPE_UNIIKEY,
  CORPORATION_USER_POOL_PERMISSION_AUTH_HUB,
} from 'define';
import { FC, memo } from 'react';

const UserPoolTab = memo(({ userPoolId }: { userPoolId: string }) => {
  const { data: userPool } = useUserPool(userPoolId);

  return <Tab>{userPool?.name}</Tab>;
});

export const AuthHubListGroupedByUserPool: FC = memo(() => {
  const { currentCorporation } = useUserCorporationInfo();
  const userPoolIds = currentCorporation?.userPools
    ?.filter(
      (x) =>
        /**
         * 取得したログインユーザーに紐づくCorporationのUserPoolを下記条件で絞り込む
         * - ユーザープールの認証タイプが`Uniikey`であること
         * - ユーザープールの権限に`auth_hub`が含まれていること
         */
        x.authenticationType === AUTH_CODE_TYPE_UNIIKEY &&
        x.permission.includes(CORPORATION_USER_POOL_PERMISSION_AUTH_HUB),
    )
    .map(({ userPoolId }) => userPoolId);

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '/admin', title: '組織管理TOP' },
    { url: '/admin/authHub', title: 'Auth Hub設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Tabs mt={8}>
        <TabList>
          {(userPoolIds ?? []).map((x) => (
            <UserPoolTab key={x} userPoolId={x} />
          ))}
        </TabList>
        <TabPanels>
          {(userPoolIds ?? []).map((x) => (
            <TabPanel key={x}>
              <AuthHubList userPoolId={x} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
});
