import { User, UserTenant } from 'api/user/types';
import {
  DISABLE_PUBLISH_PERMISSION,
  PERMISSION_ASSISTANT,
  PERMISSION_MANAGER,
  PERMISSION_OWNER,
  PERMISSION_STAFF,
  TENANT_PERMISSION_LIST,
} from 'define';
import { useEffect, useState } from 'react';
import {
  useRecoilRefresher_UNSTABLE as useRecoilRefresherUNSTABLE,
  useRecoilValue,
} from 'recoil';
import { userState, userTenantState } from 'store/user';

export const useUserInfo = (): {
  permissionList: { value: number; text: string; label: string }[];
  userTenant: UserTenant | null;
  isDisablePublish: boolean;
  isOwner: boolean;
  isManager: boolean;
  isStaff: boolean;
  isAssistant: boolean;
  isReadableGroup: boolean;
  selfData: User | null;
  isMine: (id: string) => boolean;
  getPermissionName: (val: number) => string;
  getPermissionKey: (val: number) => string;
  isEditableTarget: (val: number) => boolean;
  hasFeature: (val: number) => boolean;
  refreshSelfData: () => void;
  isEnabledFormHosting: () => boolean;
  hasPresetForm: (tenantId: string) => boolean;
  getFormType: (tenantId: string) => number;
  getLoginUrl: (tenantId: string) => string;
} => {
  const [permissionList, setPermissionList] = useState(TENANT_PERMISSION_LIST);
  const [isDisablePublish, setIsDisablePublish] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isAssistant, setIsAssistant] = useState(false);
  const [isReadableGroup, setIsReadableGroup] = useState(false);

  const selfData = useRecoilValue(userState);
  const userTenant = useRecoilValue(userTenantState);

  /**
   * パーミッションのコードより表示名を取得
   * @param {number} val - permission code
   * @returns {string} パーミッションの表示名を返す
   */
  const getPermissionName = (val: number): string => {
    const target = TENANT_PERMISSION_LIST.filter((item) => item.value === val);
    if (target.length === 0) return '';

    return target[0].label;
  };

  /**
   * パーミッションのコードより表示名を取得
   * @param {number} val - permission code
   * @returns {string} パーミッションの表示名を返す
   */
  const getPermissionKey = (val: number): string => {
    const target = TENANT_PERMISSION_LIST.filter((item) => item.value === val);
    if (target.length === 0) return '';

    return target[0].text;
  };

  /**
   * 対象がログインユーザーの権限で編集可能かどうか判定
   * @param {number} val - permission
   * @returns {boolean} 編集可能かどうかを返す
   */
  const isEditableTarget = (val: number): boolean =>
    val <= (userTenant?.permission || 0);

  /**
   * 引数で指定した機能IDを所持するかを判定
   * @param {number} val - 機能ID
   * @returns {boolean} true: 機能利用可能, false: 機能利用不可
   */
  const hasFeature = (val: number): boolean =>
    (userTenant?.features as number[]).includes(val);

  /**
   * 対象がログイン中のユーザーの情報かどうか判定
   * @param {string} id - user id
   * @returns {boolean} ログイン中のユーザー情報かどうかを返す
   */
  const isMine = (id: string): boolean => id === selfData?.id;

  /**
   * フォームホスティング機能が有効かどうかを返す
   *
   * @param tenantId
   */
  const isEnabledFormHosting = (): boolean =>
    typeof userTenant?.hostingDomain === 'string' &&
    userTenant.hostingDomain !== '';

  /**
   * 対象のテナントがプリセット設問を選択可能かどうかを判定
   * @param {string} id - tenant id
   * @returns {boolean} テナントでプリセット設問を選択可能か
   */
  const hasPresetForm = (tenantId: string): boolean => {
    // フラグを持たない場合は true (選択可能) とする
    if (selfData?.tenant && Array.isArray(selfData.tenant)) {
      const ten = selfData.tenant.find((v) => v.id === tenantId);
      if (ten && typeof ten?.hasUniikey1FormPreset === 'boolean') {
        return ten?.hasUniikey1FormPreset;
      }
    }

    return true;
  };

  /**
   * 対象のテナントで使用可能な設問のタイプを取得する
   * @param {string} id - tenant id
   * @returns {number} 1:Uniikey ユーザー属性 2:Gigya(従来) 3:集英社
   */
  const getFormType = (tenantId: string): number => {
    if (selfData?.tenant && Array.isArray(selfData.tenant)) {
      const ten = selfData.tenant.find((v) => v.id === tenantId);

      if (ten && typeof ten?.authType === 'number') {
        return ten?.authType;
      }
    }

    return 2;
  };

  const getLoginUrl = (tenantId: string): string => {
    if (selfData?.tenant && Array.isArray(selfData.tenant)) {
      const ten = selfData.tenant.find((v) => v.id === tenantId);

      if (ten && typeof ten?.loginUrl === 'string') {
        return ten?.loginUrl;
      }
    }

    return '';
  };

  const refreshSelfData = useRecoilRefresherUNSTABLE(userState);

  useEffect(() => {
    // 現在ログインしているユーザーが編集権限持ちかどうか
    setIsOwner((userTenant?.permission as number) === PERMISSION_OWNER);
    // マネージャーかどうか
    setIsManager((userTenant?.permission as number) === PERMISSION_MANAGER);
    // スタッフかどうか
    setIsStaff((userTenant?.permission as number) === PERMISSION_STAFF);
    // アシスタントかどうか
    setIsAssistant((userTenant?.permission as number) === PERMISSION_ASSISTANT);

    // 個人情報を取り扱えるスタッフ（オーナー、マネージャー、個人情報スタッフ）
    setIsReadableGroup(
      (userTenant?.permission as number) === PERMISSION_OWNER ||
        (userTenant?.permission as number) === PERMISSION_MANAGER ||
        (userTenant?.permission as number) === PERMISSION_ASSISTANT,
    );

    // 現在のログインしているユーザーが使用できるパーミッションのリストを作成
    const pList = TENANT_PERMISSION_LIST.filter(
      (item) => item.value <= (userTenant?.permission || 0),
    );
    setPermissionList(pList);

    // 現在ログインしているユーザーがcontentsDBの公開権限持ちかどうか
    setIsDisablePublish(
      DISABLE_PUBLISH_PERMISSION.indexOf(userTenant?.permission as number) !==
        -1,
    );
  }, [userTenant]);

  return {
    permissionList,
    userTenant,
    isOwner,
    isManager,
    isStaff,
    isAssistant,
    isReadableGroup,
    isDisablePublish,
    selfData,
    isMine,
    getPermissionName,
    getPermissionKey,
    isEditableTarget,
    hasFeature,
    refreshSelfData,
    isEnabledFormHosting,
    hasPresetForm,
    getFormType,
    getLoginUrl,
  };
};
