import { Th, Tr } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const Header: FC = memo(() => {
  const columns = ['ID', '設定名', 'アクション', '利用設定'];

  return (
    <Tr>
      {columns.map((x) => (
        <Th key={x} fontSize="md">
          {x}
        </Th>
      ))}
    </Tr>
  );
});
