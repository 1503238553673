import { Box } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { SettingBody } from 'admin/components/service/setting/settingBody';
import { SettingTitle } from 'admin/components/service/setting/settingTitle';
import { Breadcrumb } from 'admin/components/ui/breadcrumb';
import { Colors } from 'admin/define/colors';
import { BreadcrumbType } from 'admin/types/ui/breadcrumb';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import { FC, memo, useState } from 'react';

export const setting: FC = memo(() => {
  const [headerLabel, setHeaderLabel] = useState<string>('');
  useSetPageTitle('その他の設定');

  const breadcrumbItems: BreadcrumbType[] = [
    { url: '', title: '組織管理TOP' },
    { url: '/admin/service', title: 'テナント設定管理' },
    { url: '', title: 'その他の設定' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Box mt="40px">
        <Box>
          <SettingTitle headerLabel={headerLabel} />
        </Box>
        <Box bgColor={Colors.WHITE} p="24px" mt="16px" borderRadius="4px">
          <SettingBody setHeaderLabel={setHeaderLabel} />
        </Box>
      </Box>
    </>
  );
});

export const Setting = memo(
  withSuspenseAndErrorBoundary(setting, {
    ErrorComponent: <ErrorContents name="その他の設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
