import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'newEmailEntry.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'メールアドレス登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'メールアドレス登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '以下のメールアドレスに認証コードメールを送信しました。\n\nメールに記載されている６桁のコードを入力し、\n新しいメールアドレスを設定して「新しいメールアドレスに認証コードを送信」を押下してください。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.codeEntryItemName',
    label: '入力欄名 認証コード',
    type: 'Text',
    defaultValue: 'コードの入力',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: '新しいメールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.continueButtonName',
    label: 'ボタン名 認証コードメール送信',
    type: 'Text',
    defaultValue: '認証コードメールの送信',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.backButtonName',
    label: 'ボタン名 戻る',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'newEmailEntry.resendInstructionText',
    label: '案内文 再送信案内',
    type: 'Textarea',
    defaultValue:
      '認証コードメールが届かない場合、\n前の画面に戻り再度送信をお試しください。',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
