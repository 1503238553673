import { CopyIcon, InfoOutlineIcon, QuestionIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { SettingInputs } from 'api/enquete/types';
import { MultiValue, OptionBase, Select } from 'chakra-react-select';
import {
  ColorPicker,
  DatePicker,
  ErrorTextMsg,
  RequireTag,
} from 'components/common/atoms';
import {
  InputForm,
  RadioElement,
  RadioForm,
  SelectForm,
} from 'components/common/molecules';
import { format } from 'date-fns';
import { useEnqueteDetail } from 'hooks/enquete/useEnqueteDetail';
import { useEnqueteMail } from 'hooks/enquete/useEnqueteMail';
import { useMailMagazineId } from 'hooks/mail/useMailMagazineId';
import { useGroupList } from 'hooks/tenant/useGroupList';
import { useCopy } from 'hooks/useCopy';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUserInfo } from 'hooks/useUserInfo';
import { memo, useCallback, useEffect, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { toErrMsgList } from 'utils/form';

const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

const enquetePosition = [
  { id: 1, name: 'left-top' },
  { id: 2, name: 'left-center' },
  { id: 3, name: 'left-bottom' },
  { id: 4, name: 'center-top' },
  { id: 5, name: 'center-center' },
  { id: 6, name: 'center-bottom' },
  { id: 7, name: 'right-top' },
  { id: 8, name: 'right-center' },
  { id: 9, name: 'right-bottom' },
];

const enqueteTrigger = [
  { id: 1, name: 'アクセス時に表示', value: 'immediately' },
  { id: 2, name: '任意要素クリックで表示（サイト側ボタン等）', value: 'any' },
  { id: 3, name: 'スクロール量で表示', value: 'scroll' },
  { id: 4, name: '経過時間で表示', value: 'timer' },
  { id: 5, name: 'オーバーレイボタンクリックで表示', value: 'button' },
];

const enqueteButtonSize = [
  { id: 1, name: '小' },
  { id: 2, name: '中' },
  { id: 3, name: '大' },
];

// chakra-react-select用インターフェース
interface userOption extends OptionBase {
  value: string;
  label: string;
}

type Props = {
  uneditableMessate: string;
  isEmail: boolean;
  userList: userOption[];
  selectedList: userOption[];
};

export const EnqueteFormSettings: VFC<Props> = memo(
  ({ uneditableMessate, isEmail, userList, selectedList }: Props) => {
    const [initFields, setInitFields] = useState(false);
    const [postTarget, setPostTarget] = useState(1);
    const [trigger, setTrigger] = useState('immediately');
    const [delivaryStartDate, setDelivaryStartDate] = useState<Date | null>(
      null,
    );
    const [delivaryEndDate, setDelivaryEndDate] = useState<Date | null>(null);
    const [thanksFlg, setThanksFlg] = useState(false);
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [enqueteFromAddressList, setEnqueteFromAddressList] = useState<
      string[]
    >([]);
    const [enqueteReplyAddressList, setEnqueteReplyAddressList] = useState<
      string[]
    >([]);
    const tenantId = useUserTenantId();
    const treeData = useGroupList(tenantId);
    const { copy } = useCopy();
    const { getLoginUrl } = useUserInfo();
    const toast = useCustomToast();

    const {
      register,
      setValue,
      setError,
      getValues,
      clearErrors,
      watch,
      formState: { errors },
    } = useFormContext<SettingInputs>();

    const userSelected = useCallback(
      (data: MultiValue<userOption>): void => {
        const user = data.map((item) => item.value);
        if (user) setValue('notificationUsers', user);
      },
      [setValue],
    );

    /**
     * 初期処理
     */
    useEffect(() => {
      if (!initFields) {
        const startDate = getValues('startDate') || null;
        setDelivaryStartDate(startDate ? new Date(startDate) : null);
        const endDate = getValues('endDate') || null;
        setDelivaryEndDate(endDate ? new Date(endDate) : null);
        setPostTarget(getValues('postTarget'));
        setTrigger(getValues('trigger') || 'immediately');
        setInitFields(true);
        setEnqueteFromAddressList(getValues('thanksMailFromAddressList') || []);
        setEnqueteReplyAddressList(
          getValues('thanksMailReplyAddressList') || [],
        );
        const isThanksCheck = getValues('isSendThanksMail');
        if (isThanksCheck) setThanksFlg(true);
        const isDownloadableCheck = getValues('isNoticeMailFileDownloadUrl');
        if (isDownloadableCheck) setIsDownloadable(true);
      }
    }, [initFields, getValues]);

    // ボタントリガー、フォーム選択変更の監視処理
    useEffect(() => {
      const subscription = watch((value, { name }) => {
        if (name === 'trigger') setTrigger(value.trigger || '');
        if (name === 'postTarget') {
          setPostTarget(value.postTarget || 1);
          if (value.postTarget === 1 && isEmail) {
            setValue('answerPossibleNum', 1);
          }
        }
      });

      return () => subscription.unsubscribe();
    }, [watch, treeData, getValues, setValue, isEmail, postTarget]);

    const onStartDateChange = useCallback(
      (date: Date | null) => {
        clearErrors('startDate');
        setDelivaryStartDate(date);
        setValue('startDate', date ? format(date, DATETIME_FORMAT) : null);
      },
      [setValue, clearErrors],
    );

    const onEndDateChange = useCallback(
      (date: Date | null) => {
        clearErrors('endDate');
        setDelivaryEndDate(date);
        setValue('endDate', date ? format(date, DATETIME_FORMAT) : null);
      },
      [setValue, clearErrors],
    );

    // ユーザーIDによるメルマガが設問に存在する場合、オープンフォームを表示しない
    const formid = getValues('id') || '';
    const enqueteDetail = useEnqueteDetail({
      enqueteId: formid,
      enabled: formid !== '',
    });

    let mailMagazineId = '';
    if (enqueteDetail) {
      const mm = enqueteDetail.enqueteContents.find(
        (item) => item.type === 'mailMagazine',
      );
      mailMagazineId = mm?.mailMagazineId || '';
    }

    const mailMagazine = useMailMagazineId({
      id: mailMagazineId,
      enabled: mailMagazineId !== '',
    });

    const hasUidMailMagazine = mailMagazine?.targetType === 1;

    // フォーム公開種別 リスト
    const enqueteTypeRadioList = () => {
      const list: RadioElement[] = [];
      if (!hasUidMailMagazine) {
        list.push({
          id: 1,
          value: 1,
          element: <Text>オープンフォーム</Text>,
          isDisabled: hasUidMailMagazine,
        });
      }

      if (getLoginUrl(tenantId) !== '') {
        list.push({
          id: 2,
          value: 2,
          element: <Text>会員限定フォーム</Text>,
        });
      }

      return list;
    };

    const ToolTipLimitText: VFC = () => (
      <Text fontSize="xs" textIndent="-1em" marginLeft="1em">
        ■重複回答可能
        <br />
        何度でも回答することができます。
        <br />
        <br />
        ■回答は１回のみ
        <br />
        ・オープンフォームの場合
        <br />
        <Text marginLeft="1em">
          ユーザーが入力するメールアドレスを元に回数を制限します。
          <br />
          そのため設問に必ず入力されるメールアドレス設問が必要になります。
          <br />
        </Text>
        ・会員限定フォームの場合
        <br />
        <Text marginLeft="1em">ログインユーザーIDを元に制限します。</Text>
      </Text>
    );
    const ToolTipFileDownloadURL: VFC = () => (
      <Text fontSize="xs" textIndent="-1em" marginLeft="1em">
        チェックをつけると回答結果通知メールに添付ファイルのダウンロードリンクを含めます。
        <br />
        リンクの有効期間は７日間です。
        <br />
        リンクを知っていれば誰でもアクセスできます。
        <br />
        通知メールの取り扱いにはご注意ください。
      </Text>
    );
    const ToolTipDispCond: VFC = () => (
      <Text fontSize="xs" textIndent="-1em" marginLeft="1em">
        モーダル型で表示する場合にのみ適用されます。
        <br />
        ホスティング型で表示する場合には適用されません。
      </Text>
    );

    const { onSubmit, isLoading } = useEnqueteMail({
      setError,
    });

    const onTestMail = useCallback(() => {
      const formId = getValues('id') as string;
      const fromAddress = getValues('thanksMailFromAddress');
      const replyAddress = getValues('thanksMailReplyAddress');
      const subject = getValues('thanksMailSubject');
      const bodyText = getValues('thanksMailBodyText');
      const mailForm = {
        thanksMailFromAddress: fromAddress || '',
        thanksMailReplyAddress: replyAddress || '',
        thanksMailSubject: subject || '',
        thanksMailBodyText: bodyText || '',
      };
      const mailDataCheck = Object.values(mailForm).filter(Boolean).length;

      if (mailDataCheck >= 3) {
        onSubmit({
          tenantId,
          formId,
          enqueteMailSetting: mailForm,
        });
      } else {
        toast({
          status: 'error',
          position: 'bottom',
          duration: 4000,
          isClosable: true,
          title: '入力エラーがあります。',
          description: '必須項目を確認してください。',
        });
      }
    }, [getValues, tenantId, onSubmit, toast]);

    const setThanks = useCallback(() => {
      setThanksFlg(!thanksFlg);
      setValue('isSendThanksMail', !thanksFlg);
    }, [setThanksFlg, thanksFlg, setValue]);

    const setFileDownloadable = useCallback(() => {
      setIsDownloadable(!isDownloadable);
      setValue('isNoticeMailFileDownloadUrl', !isDownloadable);
    }, [setIsDownloadable, isDownloadable, setValue]);

    return (
      <Stack>
        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" mb="4">
            フォーム公開日時
          </Heading>
          <Flex alignItems="center">
            <DatePicker
              startDate={delivaryStartDate ? new Date(delivaryStartDate) : null}
              width={40}
              onChange={onStartDateChange}
              showTimeSelect
              isDisabled={uneditableMessate !== ''}
            />
            <span style={{ margin: '0 10px' }}>〜</span>
            <DatePicker
              endDate={delivaryEndDate ? new Date(delivaryEndDate) : null}
              width={40}
              onChange={onEndDateChange}
              showTimeSelect
              isDisabled={uneditableMessate !== ''}
            />
          </Flex>
          {toErrMsgList({ startDate: errors.startDate }, 'startDate').map(
            (err, idx) => (
              <ErrorTextMsg key={`error-startDate-${String(idx)}`} msg={err} />
            ),
          )}
          {toErrMsgList({ endDate: errors.endDate }, 'endDate').map(
            (err, idx) => (
              <ErrorTextMsg key={`error-endDate-${String(idx)}`} msg={err} />
            ),
          )}
        </Box>

        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" mt="4" mb="2">
            フォーム公開種別
          </Heading>
          {hasUidMailMagazine && (
            <ErrorTextMsg
              key="error-mail-magazine"
              msg="設問には会員IDによるメルマガが存在するため、オープンフォームは選択できません。"
              iconColor="#b98900"
              textColor="black"
            />
          )}
          <RadioForm<SettingInputs>
            name="postTarget"
            attr={{
              required: '',
            }}
            isDisabled={uneditableMessate !== ''}
            radioList={enqueteTypeRadioList()}
            contains
          />
        </Box>

        <Box pb={7} borderBottom="1px solid #eee">
          <Heading
            as="h2"
            size="sm"
            mt="4"
            mb="2"
            display="flex"
            alignItems="center"
          >
            <Text as="span">フォーム回答制限</Text>
            <Tooltip
              label={<ToolTipLimitText />}
              bg="teal.50"
              color="gray.800"
              size="xs"
            >
              <QuestionIcon ml="8px" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Heading>

          <RadioForm<SettingInputs>
            name="answerPossibleNum"
            attr={{
              required: '',
            }}
            isDisabled={uneditableMessate !== ''}
            isEmail={isEmail && postTarget !== 2}
            radioList={[
              {
                id: 1,
                value: 1,
                element: <Text>重複回答可能</Text>,
              },
              {
                id: 2,
                value: 2,
                element: (
                  <Text>
                    回答は１回のみ
                    <br />
                    エラーメッセージを設定する
                  </Text>
                ),
              },
            ]}
            contains
          />
          <InputForm<SettingInputs>
            name="answerErrMessage"
            type="text"
            placeholder="エラーメッセージ"
            isDisabled={
              uneditableMessate !== '' || (isEmail && postTarget !== 2)
            }
          />
          {isEmail && postTarget !== 2 && (
            <ErrorTextMsg
              key="error-limit"
              msg="設問に必須のメールアドレスのパーツが含まれていないので、「回答は１回のみ」の設定はできません。設問設定画面に戻り、メールアドレスのパーツを１つにした上で改めて設定してください。"
              iconColor="#b98900"
              textColor="black"
            />
          )}
        </Box>

        <Box borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" my="4" display="flex" alignItems="center">
            <Text as="span">フォーム表示条件</Text>
            <Tooltip
              label={<ToolTipDispCond />}
              bg="teal.50"
              color="gray.800"
              size="xs"
            >
              <QuestionIcon ml="8px" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Heading>
          <Flex alignItems="center" mb={6}>
            <SelectForm<SettingInputs>
              name="trigger"
              attr={{
                required: '',
                value: 'immediately',
              }}
              optionList={enqueteTrigger.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              ))}
              isDisabled={uneditableMessate !== ''}
            />
          </Flex>

          {trigger === 'button' && (
            <>
              <Box>
                <Heading as="h2" size="sm" my="4">
                  ボタンを表示する位置(PC)
                </Heading>
                <Flex alignItems="center" mb={4}>
                  <SelectForm<SettingInputs>
                    name="positionPc"
                    attr={{
                      required: '',
                    }}
                    optionList={enquetePosition.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                    isDisabled={uneditableMessate !== ''}
                  />
                </Flex>

                <Heading as="h2" size="sm" mb="4">
                  ボタンを表示する位置(SP)
                </Heading>
                <Flex alignItems="center" mb={4}>
                  <SelectForm<SettingInputs>
                    name="positionSp"
                    attr={{
                      required: '',
                    }}
                    optionList={enquetePosition.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                    isDisabled={uneditableMessate !== ''}
                  />
                </Flex>

                <Heading as="h2" size="sm" mb="4">
                  ボタンの表示位置変更用（ left-top の場合 left と top
                  に数値を入れてください）
                </Heading>
                <Flex alignItems="center" mb={4}>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <Box w="40%" display="flex" alignItems="flex-end" mb={2}>
                      <Box>
                        <Text mr={2}>top</Text>
                        <NumberInput
                          step={10}
                          defaultValue={0}
                          min={0}
                          mr={2}
                          isDisabled={uneditableMessate !== ''}
                        >
                          <NumberInputField {...register('positionTop')} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                      <Text>px</Text>
                    </Box>

                    <Box w="40%" display="flex" alignItems="flex-end" mb={2}>
                      <Box>
                        <Text mr={2}>left</Text>
                        <NumberInput
                          step={10}
                          defaultValue={0}
                          min={0}
                          mr={2}
                          isDisabled={uneditableMessate !== ''}
                        >
                          <NumberInputField {...register('positionLeft')} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                      <Text>px</Text>
                    </Box>

                    <Box w="40%" display="flex" alignItems="flex-end" mb={2}>
                      <Box>
                        <Text mr={2}>right</Text>
                        <NumberInput
                          step={10}
                          defaultValue={0}
                          min={0}
                          mr={2}
                          isDisabled={uneditableMessate !== ''}
                        >
                          <NumberInputField {...register('positionRight')} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                      <Text>px</Text>
                    </Box>

                    <Box w="40%" display="flex" alignItems="flex-end" mb={2}>
                      <Box>
                        <Text mr={2}>bottom</Text>
                        <NumberInput
                          step={10}
                          defaultValue={0}
                          min={0}
                          mr={2}
                          isDisabled={uneditableMessate !== ''}
                        >
                          <NumberInputField {...register('positionBottom')} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                      <Text>px</Text>
                    </Box>
                  </Box>
                </Flex>
              </Box>

              <Box>
                <Heading as="h2" size="sm" mb="4">
                  ボタンスタイル
                </Heading>
                <Text mb={4}>
                  フォーム表示するためのボタンのテキストや大きさを設定します。
                </Text>
                <InputForm<SettingInputs>
                  mb={2}
                  name="buttonName"
                  type="text"
                  label="ボタン表示テキスト"
                  isDisabled={uneditableMessate !== ''}
                />
                <FormControl mb={2}>
                  <FormLabel htmlFor="buttonFontColor" fontSize="sm">
                    テキスト色
                  </FormLabel>

                  <ColorPicker<SettingInputs>
                    name="buttonFontColor"
                    value={getValues('buttonFontColor') || '#000000'}
                    isDisabled={uneditableMessate !== ''}
                  />
                </FormControl>
                <FormControl mb={2}>
                  <FormLabel htmlFor="buttonBgColor" fontSize="sm">
                    ボタン背景色
                  </FormLabel>

                  <ColorPicker<SettingInputs>
                    name="buttonBgColor"
                    value={getValues('buttonBgColor') || '#FFFFFF'}
                    isDisabled={uneditableMessate !== ''}
                  />
                </FormControl>
                <SelectForm<SettingInputs>
                  name="buttonSize"
                  label="ボタンの大きさ"
                  attr={{
                    required: '',
                  }}
                  optionList={enqueteButtonSize.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                  isDisabled={uneditableMessate !== ''}
                />
              </Box>
            </>
          )}

          {trigger === 'scroll' && (
            <Box>
              <Heading as="h2" size="sm" mb="2">
                スクロール量
              </Heading>
              <Text fontSize="sm" mb={2}>
                1〜100%で指定してください。
              </Text>
              <NumberInput
                defaultValue={0}
                w={200}
                min={1}
                max={100}
                isDisabled={uneditableMessate !== ''}
              >
                <NumberInputField {...register('scroll')} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          )}

          {trigger === 'timer' && (
            <Box>
              <Heading as="h2" size="sm" mb="2">
                タイマー
              </Heading>
              <Text fontSize="sm" mb={2}>
                秒数を指定してください。
              </Text>
              <NumberInput
                defaultValue={0}
                w={200}
                min={1}
                isDisabled={uneditableMessate !== ''}
              >
                <NumberInputField {...register('timer')} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          )}

          {trigger === 'any' && (
            <Box>
              <Heading as="h2" size="sm" mb="2">
                任意DOM要素のID名 or class名
              </Heading>
              <Text fontSize="sm" mb={2}>
                埋め込みサイトに入っている既存のボタンなどを利用してクリックした際にモーダルを立ち上げたい場合に使用してください。
                <br />
                IDの場合は先頭に「#」をclass名の場合は先頭に「.」をつけてください。
              </Text>
              <InputForm<SettingInputs>
                name="any"
                type="text"
                placeholder="IDの場合: #idname、classの場合: .classname"
                // required
                isDisabled={uneditableMessate !== ''}
              />
            </Box>
          )}
        </Box>

        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" my="4">
            パスワード質問（任意）
          </Heading>
          <Text mb={4}>
            まず質問を表示し、ここで設定したパスワードを入力したユーザーにのみフォームを表示・回答させる場合にご利用ください
          </Text>

          <InputForm<SettingInputs>
            name="passwordQuestion"
            type="text"
            label="質問"
            isDisabled={uneditableMessate !== ''}
          />
          <Box mt={4}>
            <InputForm<SettingInputs>
              name="passwordAnswer"
              type="text"
              label="パスワード"
              isDisabled={uneditableMessate !== ''}
            />
          </Box>
        </Box>

        {/* フォーム背景色は不要とのことで非表示 */}
        <Heading as="h2" size="sm" my="4" hidden>
          フォーム背景色
        </Heading>
        <ColorPicker<SettingInputs>
          name="bgColor"
          value={getValues('bgColor')}
          isDisabled={uneditableMessate !== ''}
          hidden
        />

        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" my="4">
            回答者向けサンクスメール設定（任意）
          </Heading>
          <Checkbox
            {...register('isSendThanksMail')}
            isDisabled={uneditableMessate !== '' || isEmail}
            onChange={setThanks}
            name="isSendThanksMail"
          >
            <Text fontSize={14}>
              送信の有無（チェックをつけると回答完了時に以下で設定した内容でメールが送信されます。）
            </Text>
          </Checkbox>
          {isEmail && (
            <ErrorTextMsg
              key="error-email"
              msg="サンクスメールを利用する場合には、設問に「メールアドレス」を必須として設定してください。"
              iconColor="#b98900"
              textColor="black"
            />
          )}
          {toErrMsgList(
            { isSendThanksMail: errors.isSendThanksMail },
            'isSendThanksMail',
          ).map((err, idx) => (
            <ErrorTextMsg
              key={`error-isSendThanksMail-${String(idx)}`}
              msg={err}
            />
          ))}
          {thanksFlg && (
            <Stack>
              <Box pt={3}>
                <SelectForm<SettingInputs>
                  name="thanksMailFromAddress"
                  label="差出人メールアドレス"
                  placeholder="選択してください"
                  attr={{
                    required: '送信元アドレスを選択してください',
                  }}
                  requireTag
                  isDisabled={uneditableMessate !== ''}
                  optionList={enqueteFromAddressList.map((item, index) => (
                    <option key={String(index)} value={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>

              <Box pt={2}>
                <SelectForm<SettingInputs>
                  name="thanksMailReplyAddress"
                  label="返信用メールアドレス"
                  placeholder="選択してください"
                  attr={{
                    required: '',
                  }}
                  isDisabled={uneditableMessate !== ''}
                  optionList={enqueteReplyAddressList.map((item, index) => (
                    <option key={String(index)} value={item}>
                      {item}
                    </option>
                  ))}
                />
              </Box>

              <Box pt={2}>
                <Text mb={2}>
                  件名
                  <RequireTag />
                </Text>
                <Accordion allowMultiple>
                  <AccordionItem border="1px solid #edf2f6">
                    <AccordionButton backgroundColor="#edf2f6">
                      <Box flex="1" textAlign="left" fontSize={11}>
                        <InfoOutlineIcon mr={2} />
                        埋め込みワードについて
                        <AccordionIcon ml={2} />
                      </Box>
                    </AccordionButton>
                    <AccordionPanel px={2} py={1}>
                      <Text fontSize="11px" my={2}>
                        件名にフォームタイトルや回答日時などを埋め込むことが可能です。
                        <br />
                        ラベルをクリックにて差し込みワードをコピーし、件名に貼り付けてお使いください。
                      </Text>
                      <Box>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%formTitle%%')}
                        >
                          タイトル
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%formId%%')}
                        >
                          フォームID
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%answerId%%')}
                        >
                          回答ID
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%answerDate%%')}
                        >
                          回答日時
                          <CopyIcon ml={1} />
                        </Button>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <InputForm<SettingInputs>
                  name="thanksMailSubject"
                  type="text"
                  isDisabled={uneditableMessate !== ''}
                  attr={{ required: '件名を入力してください' }}
                />
              </Box>

              <Box pt={2}>
                <Text mb={2}>
                  本文
                  <RequireTag />
                </Text>
                <Accordion allowMultiple my={2}>
                  <AccordionItem border="1px solid #edf2f6">
                    <AccordionButton backgroundColor="#edf2f6">
                      <Box flex="1" textAlign="left" fontSize={11}>
                        <InfoOutlineIcon mr={2} />
                        埋め込みワードについて
                        <AccordionIcon ml={2} />
                      </Box>
                    </AccordionButton>
                    <AccordionPanel px={2} py={1}>
                      <Text fontSize="11px" my={2}>
                        本文にフォームタイトルや回答日時などを埋め込むことが可能です。
                        <br />
                        ラベルをクリックにて差し込みワードをコピーし、本文に貼り付けてお使いください。
                      </Text>
                      <Box>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%formTitle%%')}
                        >
                          タイトル
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%formId%%')}
                        >
                          フォームID
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%answerId%%')}
                        >
                          回答ID
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%answerDate%%')}
                        >
                          回答日時
                          <CopyIcon ml={1} />
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          mr={2}
                          mb={2}
                          onClick={() => copy('%%answerContents%%')}
                        >
                          回答内容
                          <CopyIcon ml={1} />
                        </Button>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Textarea
                  {...register('thanksMailBodyText', {
                    required: '本文を入力してください',
                  })}
                  rows={15}
                />
                {toErrMsgList(
                  { thanksMailBodyText: errors.thanksMailBodyText },
                  'thanksMailBodyText',
                ).map((err, idx) => (
                  <ErrorTextMsg
                    key={`error-thanksMailBodyText-${String(idx)}`}
                    msg={err}
                  />
                ))}
              </Box>
              <Box textAlign="center" py={2}>
                <Button
                  w="100px"
                  colorScheme="gray"
                  onClick={onTestMail}
                  isDisabled={isLoading}
                >
                  テスト送信
                </Button>
              </Box>
              <Text fontSize="xs" textAlign="left">
                このアカウントのメールアドレスにテスト送信されます。
              </Text>
            </Stack>
          )}
        </Box>

        <Box pb={4} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" my="4">
            回答結果通知の宛先（任意）
          </Heading>

          <Box mt={4}>
            <Text mb={2}>宛先（複数選択可能）</Text>
            <Select
              isMulti
              defaultValue={selectedList}
              name="notificationUsers"
              options={userList}
              placeholder="ユーザー名を選択してください"
              onChange={(data) => userSelected(data)}
            />
          </Box>
          <Box mt={4} display="flex" alignItems="center">
            <Checkbox
              {...register('isNoticeMailFileDownloadUrl')}
              isDisabled={uneditableMessate !== ''}
              onChange={setFileDownloadable}
              name="isNoticeMailFileDownloadUrl"
            >
              <Text fontSize={14}>
                添付ファイルのダウンロードリンクを含める
              </Text>
            </Checkbox>
            <Tooltip
              label={<ToolTipFileDownloadURL />}
              bg="teal.50"
              color="gray.800"
              size="xs"
            >
              <QuestionIcon ml="8px" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        </Box>

        <Box pb={6} borderBottom="1px solid #eee">
          <Heading as="h2" size="sm" my="4">
            グループ設定（任意）
          </Heading>
          <SelectForm<SettingInputs>
            name="group"
            attr={{
              required: '',
            }}
            optionDefault="true"
            optionList={treeData.map((grp) => (
              <option key={grp.id} value={grp.id}>
                {grp.text}
              </option>
            ))}
            isDisabled={uneditableMessate !== ''}
          />
        </Box>
      </Stack>
    );
  },
);
