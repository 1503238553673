import {
  headTitleOptions,
  screenDescriptionOptions,
  screenNameOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'passwordUnset.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'パスワード未設定',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'passwordUnset.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'パスワード未設定',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordUnset.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      'このアカウントではパスワードが設定されていません。\n\n「続ける」を押して、パスワードの設定を行ってください。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'passwordUnset.continueButtonName',
    label: 'ボタン名 次の画面に遷移',
    type: 'Text',
    defaultValue: '続ける',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordUnset.backButtonName',
    label: 'ボタン名 前の画面に遷移',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
