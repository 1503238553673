export default [
  {
    name: 'embeddedTags.head',
    label: 'Head用タグ',
    type: 'Textarea',
    defaultValue: '',
    options: {},
    elementOptions: {
      rows: 10,
    },
  },
  {
    name: 'embeddedTags.body',
    label: 'Body用タグ',
    type: 'Textarea',
    defaultValue: '',
    options: {},
    elementOptions: {
      rows: 10,
    },
  },
] as const;
