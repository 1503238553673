import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ApiKeyComponent } from 'admin/components/service/setting/apiKey/ApikeyComponent';
import { SettingType } from 'admin/types/service/setting/';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  settingData: SettingType;
};

export const apiKey: FC<Props> = memo(({ settingData }: Props) => (
  <ApiKeyComponent settingData={settingData} />
));

export const ApiKey = memo(
  withSuspenseAndErrorBoundary(apiKey, {
    ErrorComponent: <ErrorContents name="APIキー" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
