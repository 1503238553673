import { Wrap, WrapItem } from '@chakra-ui/react';
import { memo } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { gooberSetup } from 'view-template';
import { FormType } from 'admin/types/authHub';
import { FooterProps } from './templates/Footer';
import { HeaderProps } from './templates/Header';
import { LoginPage } from './templates/LoginPage';
import { RegisterPage } from './templates/RegisterPage';
import { PasswordCreatePage } from './templates/PasswordCreatePage';
import { PasswordUnsetPage } from './templates/PasswordUnsetPage';
import { PasswordResetPage } from './templates/PasswordResetPage';
import { NewPasswordEntryPage } from './templates/NewPasswordEntryPage';
import { MemberInfoEntryPage } from './templates/MemberInfoEntryPage';
import { FirstInfoEntryPage } from './templates/FirstInfoEntryPage';
import { MissingInfoEntryPage } from './templates/MissingInfoEntryPage';
import { InfoConfirmationPage } from './templates/InfoConfirmationPage';
import { RegistrationCompletePage } from './templates/RegistrationCompletePage';
import { MypagePage } from './templates/MypagePage';
import { MemberInfoChangePage } from './templates/MemberInfoChangePage';
import { MemberInfoConfirmPage } from './templates/MemberInfoConfirmPage';
import { VerificationCodeEntryPage } from './templates/VerificationCodeEntryPage';
import { NewEmailEntryPage } from './templates/NewEmailEntryPage';
import { WithdrawalPage } from './templates/WithdrawalPage';
import { WithdrawalConfirmationPage } from './templates/WithdrawalConfirmationPage';
import { WithdrawalCompletePage } from './templates/WithdrawalCompletePage';
import { CompletePage } from './templates/CompletePage';
import { EmailChangePage } from './templates/EmailChangePage';
import { PasswordChangePage } from './templates/PasswordChangePage';
import { ThemeProps } from './Theme';
import { SettingType } from '../authHubEditor/type';

gooberSetup();

type PreviewProps = {
  pageName: string;
  watch: UseFormWatch<FormType>;
  theme: ThemeProps;
  header?: HeaderProps;
  footer: FooterProps;
  force?: {
    hover?: boolean;
    focus?: boolean;
  };
  settingType: SettingType;
};

const PreviewInner = (props: PreviewProps): JSX.Element => {
  const { pageName, settingType } = props;
  const showsSignUpButton = settingType === 'tenant';

  return (
    <>
      {pageName === 'theme' && (
        <Wrap m="auto" w="full" justify="center">
          <WrapItem>
            <LoginPage {...props} showsSignUpButton={showsSignUpButton} />
          </WrapItem>
          <WrapItem>
            <FirstInfoEntryPage {...props} />
          </WrapItem>
          <WrapItem>
            <VerificationCodeEntryPage {...props} />
          </WrapItem>
        </Wrap>
      )}
      {pageName === 'header' && (
        <Wrap m="auto" w="full" justify="center">
          <WrapItem>
            <LoginPage {...props} showsSignUpButton={showsSignUpButton} />
          </WrapItem>
          <WrapItem>
            <FirstInfoEntryPage {...props} />
          </WrapItem>
          <WrapItem>
            <VerificationCodeEntryPage {...props} />
          </WrapItem>
        </Wrap>
      )}
      {pageName === 'footer' && (
        <Wrap m="auto" w="full" justify="center">
          <WrapItem>
            <LoginPage {...props} showsSignUpButton={showsSignUpButton} />
          </WrapItem>
          <WrapItem>
            <FirstInfoEntryPage {...props} />
          </WrapItem>
          <WrapItem>
            <VerificationCodeEntryPage {...props} />
          </WrapItem>
        </Wrap>
      )}
      {pageName === 'login' && (
        <LoginPage {...props} showsSignUpButton={showsSignUpButton} />
      )}
      {pageName === 'register' && <RegisterPage {...props} />}
      {pageName === 'passwordCreate' && <PasswordCreatePage {...props} />}
      {pageName === 'passwordUnset' && <PasswordUnsetPage {...props} />}
      {pageName === 'passwordReset' && <PasswordResetPage {...props} />}
      {pageName === 'newPasswordEntry' && <NewPasswordEntryPage {...props} />}
      {pageName === 'memberInfoEntry' && <MemberInfoEntryPage {...props} />}
      {pageName === 'firstInfoEntry' && <FirstInfoEntryPage {...props} />}
      {pageName === 'missingInfoEntry' && <MissingInfoEntryPage {...props} />}
      {pageName === 'infoConfirmation' && <InfoConfirmationPage {...props} />}
      {pageName === 'registrationComplete' && (
        <RegistrationCompletePage {...props} />
      )}
      {pageName === 'mypage' && <MypagePage {...props} />}
      {pageName === 'memberInfoChange' && <MemberInfoChangePage {...props} />}
      {pageName === 'memberInfoConfirm' && <MemberInfoConfirmPage {...props} />}
      {pageName === 'verificationCodeEntry' && (
        <VerificationCodeEntryPage {...props} />
      )}
      {pageName === 'newEmailEntry' && <NewEmailEntryPage {...props} />}
      {pageName === 'withdrawal' && <WithdrawalPage {...props} />}
      {pageName === 'withdrawalConfirmation' && (
        <WithdrawalConfirmationPage {...props} />
      )}
      {pageName === 'withdrawalComplete' && (
        <WithdrawalCompletePage {...props} />
      )}
      {pageName === 'complete' && <CompletePage {...props} />}
      {pageName === 'emailChange' && <EmailChangePage {...props} />}
      {pageName === 'passwordChange' && <PasswordChangePage {...props} />}
    </>
  );
};

const typedMemo: <T>(c: T) => T = memo;

export const Preview = typedMemo(PreviewInner);
