import { Box } from '@chakra-ui/react';
import { FC, Ref } from 'react';
import { InputItem } from 'admin/components/authHub/inputItem';
import { Settings } from 'admin/components/authHub/settings';
import { TargetType, FormType } from 'admin/types/authHub';
import { FieldErrors, RegisterOptions, ChangeHandler } from 'react-hook-form';
import { SettingType } from './type';

type Props = {
  register: (
    name: string,
    options?: RegisterOptions,
  ) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: Ref<HTMLInputElement>;
  };
  settings: Settings;
  settingType: SettingType;
  target: TargetType;
  errors: FieldErrors<FormType>;
  getValues: (key: string) => string;
};

export const Controls: FC<Props> = ({
  register,
  settings,
  settingType,
  target,
  errors,
  getValues,
}) => {
  const ss = settings[target];

  return (
    <Box mb={2} mr={2}>
      {ss.map((setting) => {
        // 組織側のフローでログイン画面から新規登録画面へのフローを消すための暫定的な対応
        if (
          settingType === 'organization' &&
          (setting.name === 'login.signupButtonName' ||
            setting.name === 'login.unregisteredUserGuide')
        ) {
          return null;
        }

        return (
          <InputItem
            key={setting.name}
            register={register}
            setting={setting}
            errors={errors}
            getValues={getValues}
          />
        );
      })}
    </Box>
  );
};
