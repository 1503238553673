import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { SettingMaskingText } from 'admin/components/service/setting/settingMaskingText';
import { Colors } from 'admin/define/colors';
import { SettingType } from 'admin/types/service/setting/';
import { useCopy } from 'hooks/useCopy';
import { FC, memo, useMemo } from 'react';
import { MdContentCopy } from 'react-icons/md';

type Props = {
  settingData: SettingType;
};

export const AccessSecretKeyComponent: FC<Props> = memo(
  ({ settingData }: Props) => {
    const { copy } = useCopy();

    const requestHeader = useMemo<string>(() => {
      let tmpHeaderTxt = settingData.contentType;
      if (tmpHeaderTxt && settingData.authorization) {
        tmpHeaderTxt += '\r\n';
      }
      tmpHeaderTxt += settingData.authorization;

      return tmpHeaderTxt || '未設定';
    }, [settingData]);

    return (
      <VStack
        spacing={4}
        alignItems="flex-start"
        fontFamily="Hiragino Sans"
        display="inline-block"
        w="100%"
      >
        <Box>
          <Text fontWeight="700">アクセスキー・シークレットキー</Text>
        </Box>
        <Box>
          <Text>
            個人情報などを含む、厳重な取り扱いが必要なデータに利用します。
          </Text>
          <Text color={Colors.RED_20}>
            このキーは外部へ公開されることがないよう、お取り扱いに十分ご注意ください。
          </Text>
        </Box>
        <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
          <Text fontWeight="700">利用環境</Text>
          <Text>{settingData.usageEnvironment}</Text>
        </Box>
        <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
          <Text fontWeight="700">用途</Text>
          <Text>{settingData.purpose}</Text>
        </Box>
        <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
          <Text fontWeight="700">アクセスキー</Text>
          <SettingMaskingText text={settingData.accessKey} />
        </Box>
        <Box p="8px 16px" bgColor={Colors.GRAY_70} borderRadius="8px">
          <Text fontWeight="700">シークレットキー</Text>
          <SettingMaskingText text={settingData.secretKey} />
        </Box>
        <Box>
          <Flex>
            <Text fontWeight="700">
              リクエストヘッダーに以下を設定してください。
            </Text>
            <Button
              variant="outline"
              size="xs"
              ml="auto"
              onClick={() => copy(requestHeader)}
            >
              <Flex alignItems="flex-start">
                <Box>
                  <MdContentCopy />
                </Box>
                <Text pl="6px">内容をコピー</Text>
              </Flex>
            </Button>
          </Flex>
          <Box
            mt="8px"
            p="8px 16px"
            border="1px solid"
            borderColor={Colors.GRAY_30}
            borderRadius="6px"
            whiteSpace="pre"
          >
            {requestHeader}
          </Box>
        </Box>
      </VStack>
    );
  },
);
