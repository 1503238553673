import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { getEnqueteIdSetting } from 'api/enquete/getEnqueteIdSetting';
import {
  EnqueteSettingDefaultValue, SettingInputs
} from 'api/enquete/types';
import { getTenantUserList } from 'api/user/getTenantUserList';
import { OptionBase } from 'chakra-react-select';
import { DrawerForm, ErrorTextMsg } from 'components/common/atoms';
import { EnqueteFormSettings } from 'components/enquete/organisms/EnqueteFormSettings';
import { useEditEnqueteSetting } from 'hooks/enquete/useEditEnqueteSetting';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUserInfo } from 'hooks/useUserInfo';
import { memo, useCallback, useEffect, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { errorToast } from 'utils/toast';

type EnqueteSettingDrawerFormProps = {
  enqueteId: string;
  buttonName: string;
};

type selectListType = {
  value: string;
  label: string;
};

// chakra-react-select用インターフェース
interface userOption extends OptionBase {
  value: string;
  label: string;
}

const defaultValues: SettingInputs = EnqueteSettingDefaultValue;

export const EnqueteSettingDrawerForm: VFC<EnqueteSettingDrawerFormProps> =
  memo(
    ({
      enqueteId = '',
      buttonName,
    }: EnqueteSettingDrawerFormProps) => {
      const [uneditableMessate, setUneditableMessate] = useState('');
      const [selectedList, setSelectedList] = useState<selectListType[]>([]);
      const [initFields, setInitFields] = useState(false);
      const [isEmail, setIsEmail] = useState(true);
      const [enqueteFormId, setEnqueteFormId] = useState('');
      const [enqueteFormTitle, setEnqueteFormTitle] = useState('');
      const [userList, setUserList] = useState<userOption[]>([]);
      const toast = useCustomToast();
      const [searchParams, setSearchParams] = useSearchParams();
      const { isOpen, onOpen, onClose } = useDisclosure();
      const tenantId = useUserTenantId();
      const { isStaff, isAssistant } = useUserInfo();

      const methods = useForm<SettingInputs>({
        mode: 'onBlur',
        // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
        reValidateMode: 'onBlur',
        defaultValues,
      });
      const { reset, setValue, handleSubmit, setError } = methods;
      const formId = 'enqueteSetting-form';

      const subInfoStyle = {
        fontSize: '14px',
        fontWeight: 'normal',
      };

      const onDrawerClose = useCallback(() => {
        const params = {};
        if (searchParams.get('order'))
          Object.assign(params, { order: searchParams.get('order') });
        if (searchParams.get('sort'))
          Object.assign(params, { sort: searchParams.get('sort') });
        if (searchParams.get('status'))
          Object.assign(params, { status: searchParams.get('status') });
        if (searchParams.get('page'))
          Object.assign(params, { page: searchParams.get('page') });
        setSearchParams(params);
        reset();
        onClose();
      }, [onClose, reset, searchParams, setSearchParams]);

      // 配信設定ボタン押下時
      const onEnqueteSettingOpen = useCallback(async () => {
        // 配信設定情報取得
        const setting = await getEnqueteIdSetting(tenantId, enqueteId);
        setEnqueteFormId(setting.id as string);
        setEnqueteFormTitle(setting.title as string);
        setValue('id', setting.id);
        setValue('startDate', setting.startDate || null);
        setValue('endDate', setting.endDate || null);
        setValue('answerPossibleNum', setting.answerPossibleNum || 1);
        setValue(
          'answerErrMessage',
          setting.answerErrMessage ||
            'すでに回答済みです。回答は1人1回までとなります。',
        );
        setValue('group', setting.group || '');
        setValue('trigger', setting.trigger || 'immediately');
        setValue('postTarget', setting.postTarget || 1);
        setValue('positionPc', setting.positionPc || 1);
        setValue('positionSp', setting.positionSp || 1);
        setValue('positionTop', setting.positionTop || 0);
        setValue('positionLeft', setting.positionLeft || 0);
        setValue('positionRight', setting.positionRight || 0);
        setValue('positionBottom', setting.positionBottom || 0);
        setValue('scroll', setting.scroll || 1);
        setValue('timer', setting.timer || 1);
        setValue('any', setting.any || '');
        setValue('passwordQuestion', setting.passwordQuestion || '');
        setValue('passwordAnswer', setting.passwordAnswer || '');
        setValue('bgColor', setting.bgColor || '#FFFFFF');
        setValue('buttonName', setting.buttonName || '');
        setValue('buttonFontColor', setting.buttonFontColor || '#000000');
        setValue('buttonBgColor', setting.buttonBgColor || '#FFFFFF');
        setValue('buttonSize', setting.buttonSize || 1);
        setValue('isSendThanksMail', setting.isSendThanksMail || false);
        setValue(
          'isNoticeMailFileDownloadUrl',
          setting.isNoticeMailFileDownloadUrl || false,
        );
        setValue('thanksMailFromAddress', setting.thanksMailFromAddress || '');
        setValue(
          'thanksMailReplyAddress',
          setting.thanksMailReplyAddress || '',
        );
        setValue('thanksMailSubject', setting.thanksMailSubject || '');
        setValue('thanksMailBodyText', setting.thanksMailBodyText || '');
        setValue(
          'thanksMailFromAddressList',
          setting.thanksMailFromAddressList || [],
        );
        setValue(
          'thanksMailReplyAddressList',
          setting.thanksMailReplyAddressList || [],
        );
        setValue('notificationUsers', setting.notificationUsers || []);
        setUneditableMessate(
          setting.isPublic && (isStaff || isAssistant)
            ? '公開されているため、編集することができません。'
            : '',
        );
        if(setting.enqueteContents) {
          const isEmailCheck = setting.enqueteContents.some(
            (enquete) =>
              (enquete.type === 'userEmail' || enquete.type === 'uni_email') &&
              enquete.required &&
              !enquete.isCondFlg,
          );
          if (isEmailCheck) setIsEmail(!isEmailCheck);
        }

        const userTenantList = await getTenantUserList(tenantId);
        const selectUserList = userTenantList.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setUserList(selectUserList);

        const selectedNotificationList = setting.notificationUsers as string[];
        const itemSelect: userOption[] = [];
        if (selectUserList) {
          selectUserList.forEach((item) => {
            const itemId = item.value;
            if (selectedNotificationList) {
              selectedNotificationList.forEach((val: string) => {
                if (itemId === val) itemSelect.push(item);
              });
            }
          });
          if (itemSelect) setSelectedList(itemSelect);
        }

        onOpen();
      }, [
        setValue,
        onOpen,
        enqueteId,
        tenantId,
        isStaff,
        isAssistant,
      ]);

      const { onSubmit, isLoading } = useEditEnqueteSetting({
        setError,
        onDrawerClose,
      });

      const formSubmit = (data: SettingInputs) => {
        onSubmit({
          tenantId,
          enqueteId,
          enqueteSetting: data,
        });
      };

      useEffect(() => {
        if (initFields) return;
        const openDrawer = searchParams.get('openDrawer');
        if (enqueteId === openDrawer) {
          onEnqueteSettingOpen().catch((_) => {
            toast({
              ...errorToast,
              title: '配信設定読み込みエラー',
              description:
                '配信設定の読み込みに失敗したため、設定画面の表示ができませんでした。',
            });
          });
          setInitFields(true);
        }
      }, [searchParams, enqueteId, onEnqueteSettingOpen, toast, initFields]);

      return (
        <FormProvider {...methods}>
          <DrawerForm
            title="フォーム詳細設定"
            subInfo={
              <>
                <Flex>
                  <Text minW={90} {...subInfoStyle}>フォームID : </Text>
                  <Text {...subInfoStyle}>{enqueteFormId}</Text>
                </Flex>
                <Flex>
                  <Text minW={90} {...subInfoStyle}>フォーム名 : </Text>
                  <Text {...subInfoStyle}>{enqueteFormTitle}</Text>
                </Flex>
              </>
            }
            openBtnChildNode={<Text as="span">{buttonName}</Text>}
            openBtnProps={{
              bgColor: 'white',
              borderWidth: '1px',
              borderColor: 'gray.400',
            }}
            submitBtnTitle="保存"
            submitBtnProps={{
              colorScheme: 'blue',
              isLoading,
            }}
            isOpen={isOpen}
            onOpen={onEnqueteSettingOpen}
            onClose={onDrawerClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            size="lg"
            formId={formId}
            drawerCloseButtonProps={{
              mr: 4,
              disabled: isLoading,
            }}
            drawerFooterJustify="flex-end"
            isFooter={uneditableMessate === ''}
            firstFocus="button"
          >
            <form
              id={formId}
              onSubmit={handleSubmit((data) => formSubmit(data))}
              data-testid="setting-user-drawer"
            >
              {uneditableMessate !== '' && (
                <ErrorTextMsg msg={uneditableMessate} />
              )}
              <Box p={2} py={4}>
                <EnqueteFormSettings
                  uneditableMessate={uneditableMessate}
                  isEmail={isEmail}
                  userList={userList}
                  selectedList={selectedList}
                />
              </Box>
            </form>
          </DrawerForm>
        </FormProvider>
      );
    },
  );
