import { memo } from 'react';
import { FirstInfoEntryPage as Template } from 'view-template/dist/page';
import { TemplatePageProps, Wrap } from '../Page';
import { defaultTheme, dummyUserInfo } from '../util';

export const FirstInfoEntryPage = memo(
  ({ watch, force }: TemplatePageProps) => {
    const dummyProps = {
      csrfToken: '',
      postPath: '',
      errorMessage: undefined,
      oauthRequest: {
        response_type: '',
        client_id: '',
        redirect_uri: '',
      },
      loginUrl: '',
      email: dummyUserInfo.email,
      hasPasswordField: false,
      info: dummyUserInfo,
      validationError: {
        newsletter: undefined,
        attributes: undefined,
      },
    };

    return (
      <Wrap>
        <Template {...dummyProps} theme={defaultTheme(watch)} force={force} />
      </Wrap>
    );
  },
);
