import { Box, ListItem, Tr as ChakraTr, UnorderedList } from '@chakra-ui/react';
import {
  NewsletterTableBody,
  Props as NewsletterTableBodyProps,
} from 'admin/components/service/newsletter/newsletterTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import {
  Table,
  Tbody,
  Th,
  Thead,
  ThWithBorder,
} from 'admin/components/ui/table';
import {
  ServiceNewslettersType,
  ServiceNewsletterType,
} from 'admin/types/service/newsletter';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  newsletters: ServiceNewslettersType;
  setTargetServiceNewsletter: Dispatch<
    SetStateAction<ServiceNewsletterType | undefined>
  >;
  onOpenEditDrawer: (target: ServiceNewsletterType) => void;
  onOpenTenantAppendDialogHandler: NewsletterTableBodyProps['onOpenTenantAppendDialogHandler'];
  onOpenDeliveryAvailableModalHandler: NewsletterTableBodyProps['onOpenDeliveryAvailableModalHandler'];
  onOpenDisplayWhenRegistrationModalHandler: NewsletterTableBodyProps['onOpenDisplayWhenRegistrationModalHandler'];
  onOpenCheckWhenRegistrationModalHandler: NewsletterTableBodyProps['onOpenCheckWhenRegistrationModalHandler'];
};

export const NewsletterTableComponent: FC<Props> = memo(
  ({
    newsletters,
    setTargetServiceNewsletter,
    onOpenEditDrawer,
    onOpenTenantAppendDialogHandler,
    onOpenDeliveryAvailableModalHandler,
    onOpenDisplayWhenRegistrationModalHandler,
    onOpenCheckWhenRegistrationModalHandler,
  }: Props) => (
    <Box mt="24px">
      <Table w="100%" maxHeight="calc(100vh - 300px)">
        <Thead h="54px">
          <ChakraTr>
            <Th maxWidth="150px" minWidth="150px" fontSize="14px">
              メールマガジンID
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（日本語）
            </Th>
            <Th maxWidth="180px" minWidth="180px" fontSize="14px">
              表示名（外国語）
            </Th>
            <Th maxWidth="120px" minWidth="120px" fontSize="14px">
              フィールド名
            </Th>
            <Th maxWidth="140px" minWidth="140px" fontSize="14px" pr="0">
              共通マイページ
            </Th>
            <ThWithBorder maxWidth="240px" minWidth="240px" fontSize="14px">
              メモ
            </ThWithBorder>
            <Th
              maxWidth="140px"
              minWidth="140px"
              fontSize="14px"
              pr="0"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                テナント割当
                <HelpTooltip
                  label={
                    <UnorderedList>
                      <ListItem>
                        ONにすると、対象のメールマガジン購読設定が操作中のテナントで利用可能になります。
                      </ListItem>
                      <ListItem>
                        一度ONにした設定はOFFに変更できません。
                      </ListItem>
                    </UnorderedList>
                  }
                />
              </Box>
            </Th>
            <Th maxWidth="140px" minWidth="140px" fontSize="14px">
              配信利用
            </Th>
            <Th maxWidth="140px" minWidth="140px" fontSize="14px">
              新規登録時表示
            </Th>
            <Th maxWidth="150px" minWidth="150px" fontSize="14px">
              初期表示チェック
            </Th>
          </ChakraTr>
        </Thead>
        <Tbody>
          {newsletters.map((newsletter) => (
            <NewsletterTableBody
              newsletter={newsletter}
              setTargetServiceNewsletter={setTargetServiceNewsletter}
              onOpenEditDrawer={onOpenEditDrawer}
              onOpenTenantAppendDialogHandler={onOpenTenantAppendDialogHandler}
              onOpenDeliveryAvailableModalHandler={
                onOpenDeliveryAvailableModalHandler
              }
              onOpenDisplayWhenRegistrationModalHandler={
                onOpenDisplayWhenRegistrationModalHandler
              }
              onOpenCheckWhenRegistrationModalHandler={
                onOpenCheckWhenRegistrationModalHandler
              }
              key={`newsletter_${newsletter.id}}`}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
