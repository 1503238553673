import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'passwordChange.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'パスワード変更',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'passwordChange.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'パスワード変更',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordChange.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      'パスワードを変更する場合は、\n「認証コードメールの送信」ボタンを押してください。\n\n以下に入力されている現在のメールアドレス宛に認証コードが発行されます。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'passwordChange.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordChange.continueButtonName',
    label: 'ボタン名 認証コードメール送信',
    type: 'Text',
    defaultValue: '認証コードメールの送信',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'passwordChange.backButtonName',
    label: 'ボタン名 マイページTOPに遷移',
    type: 'Text',
    defaultValue: 'マイページTOPへ',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
