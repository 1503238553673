import { ButtonProps, useDisclosure } from '@chakra-ui/react';
import { getTenantUser } from 'api/user/getTenantUser';
import { User, UserTenantEditForm } from 'api/user/types';
import { DrawerForm } from 'components/common/atoms';
import { UsersEditForm } from 'components/setting/users/molecules/UsersEditForm';
import { useIndentGroupList } from 'hooks/tenant/useIndentGroupList';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { memo, useCallback, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type UsersDrawerFormProps = {
  title: string;
  user?: User;
  openBtnChildNode: React.ReactNode;
  openBtnProps?: ButtonProps;
  openBtnTestId?: string;
  submitBtnTitle: string;
  submitBtnHandler: () => void;
};

const defaultUser: User = {
  id: '',
  email: '',
  name: '',
  tenant: {
    id: '',
    name: '',
    permission: 0,
    groups: [],
    features: [],
    userPoolAuthenticationType: null,
  },
  corporations: [],
};

const defaultValues: UserTenantEditForm = {
  tenantId: '',
  userId: '',
  email: '',
  permission: 0,
  groups: [],
};

export const UsersEditDrawerForm: VFC<UsersDrawerFormProps> = memo(
  ({
    title,
    user = defaultUser,
    openBtnChildNode,
    openBtnProps = {},
    openBtnTestId = '',
    submitBtnTitle,
    submitBtnHandler,
  }) => {
    const toast = useCustomToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSendLoading, setSendLoading] = useState(false);
    const tenantId = useUserTenantId();
    const groupList = useIndentGroupList(tenantId);
    const methods = useForm<UserTenantEditForm>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues,
    });
    const {
      reset,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
    const formId = 'userEdit-form';

    // キャンセルボタン押下時
    const cancelBtnHandler = useCallback(() => {
      reset();
      onClose();
    }, [reset, onClose]);

    // 編集ボタン押下時
    const onClickUserEdit = useCallback(async () => {
      try {
        // ユーザーのテナント設定情報を取得、セットする
        const selectedUser: User = await getTenantUser(
          !Array.isArray(user.tenant) ? user.tenant.id : '',
          user.id,
        );
        setValue(
          'tenantId',
          !Array.isArray(selectedUser.tenant) ? selectedUser.tenant.id : '',
        );
        setValue('userId', selectedUser.id);
        setValue('email', selectedUser.email);
        setValue(
          'permission',
          !Array.isArray(selectedUser.tenant)
            ? selectedUser.tenant.permission
            : 0,
        );
        setValue(
          'groups',
          !Array.isArray(selectedUser.tenant) &&
            selectedUser.tenant.groups.length > 0
            ? selectedUser.tenant.groups
            : [groupList[0]],
        );
        onOpen();
      } catch (err) {
        if (err instanceof Error) {
          toast.closeAll();
          toast({
            title: err.message,
            status: 'error',
          });
        }
      }
    }, [user, groupList, setValue, onOpen, toast]);

    // API実行開始
    const startSending = useCallback(() => {
      setSendLoading(true);
    }, [setSendLoading]);

    // フォームエラーの存在チェック
    const isFailed = useCallback(
      (): boolean => Object.keys(errors).length > 0,
      [errors],
    );

    // 送信成功時
    const onSuccess = useCallback(
      (isError: boolean) => {
        if (!isSubmitting) {
          // エラーが存在しない時のみドロワーを非表示にする
          if (!isError && !isFailed()) {
            onClose();
            submitBtnHandler();
          }
          setSendLoading(false);
        }
      },
      [isSubmitting, isFailed, onClose, submitBtnHandler, setSendLoading],
    );

    return (
      <FormProvider {...methods}>
        <DrawerForm
          title={title}
          openBtnChildNode={openBtnChildNode}
          openBtnProps={openBtnProps}
          openBtnTestId={openBtnTestId}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={cancelBtnHandler}
          cancelBtnProps={{ disabled: isSendLoading }}
          submitBtnTitle={submitBtnTitle}
          submitBtnProps={{
            variant: 'primary',
            disabled: isFailed(),
            isLoading: isSendLoading,
          }}
          isOpen={isOpen}
          onOpen={onClickUserEdit}
          onClose={onClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          size="md"
          formId={formId}
          drawerCloseButtonProps={{ disabled: isSendLoading }}
          drawerFooterJustify="space-between"
        >
          <UsersEditForm
            formId={formId}
            startSending={startSending}
            onSuccess={onSuccess}
          />
        </DrawerForm>
      </FormProvider>
    );
  },
);
