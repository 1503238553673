import { ButtonProps, useDisclosure } from '@chakra-ui/react';
import { RegistInviteUserForm, User } from 'api/user/types';
import { DrawerForm } from 'components/common/atoms';
import { UsersForm } from 'components/setting/users/molecules/UsersForm';
import { memo, useCallback, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { userTenantState } from 'store/user';

type UsersDrawerFormProps = {
  title: string;
  user?: User;
  openBtnChildNode: React.ReactNode;
  openBtnProps?: ButtonProps;
  openBtnTestId?: string;
  submitBtnTitle: string;
};

const defaultUser: User = {
  id: '',
  email: '',
  name: '',
  tenant: {
    id: '',
    name: '',
    permission: 0,
    groups: [],
    features: [],
    userPoolAuthenticationType: null,
  },
  corporations: [],
};

const defaultValues: RegistInviteUserForm = {
  emailTo: '',
  tenant: '',
  permission: 0,
  groups: [],
};

export const UsersDrawerForm: VFC<UsersDrawerFormProps> = memo(
  ({
    title,
    user = defaultUser,
    openBtnChildNode,
    openBtnProps = {},
    openBtnTestId = '',
    submitBtnTitle,
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSendLoading, setSendLoading] = useState(false);
    const userTenant = useRecoilValue(userTenantState);
    const methods = useForm<RegistInviteUserForm>({
      mode: 'onBlur',
      // エラーのある入力が再度バリデーションされるタイミングを変更(default: onChange)
      reValidateMode: 'onBlur',
      defaultValues,
    });
    const {
      reset,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
    const formId = 'userEdit-form';

    // キャンセルボタン押下時
    const cancelBtnHandler = useCallback(() => {
      reset();
      onClose();
    }, [reset, onClose]);

    // 招待ボタン押下時
    const onClickUserEdit = useCallback((): void => {
      setValue('emailTo', user.email);
      setValue('tenant', userTenant?.id as string);
      setValue(
        'permission',
        !Array.isArray(user.tenant) ? user.tenant.permission : 0,
      );
      setValue('groups', [{ id: '', name: '' }]);
      onOpen();
    }, [user, userTenant, setValue, onOpen]);

    // API実行開始
    const startSending = useCallback(() => {
      setSendLoading(true);
    }, [setSendLoading]);

    // フォームエラーの存在チェック
    const isFailed = useCallback(
      (): boolean => Object.keys(errors).length > 0,
      [errors],
    );

    // 送信成功時
    const onSuccess = useCallback(
      (isError: boolean) => {
        if (!isSubmitting) {
          // エラーが存在しない時のみドロワーを非表示にする
          if (!isError && !isFailed()) {
            onClose();
          }
          setSendLoading(false);
        }
      },
      [isSubmitting, isFailed, onClose, setSendLoading],
    );

    return (
      <FormProvider {...methods}>
        <DrawerForm
          title={title}
          openBtnChildNode={openBtnChildNode}
          openBtnProps={openBtnProps}
          openBtnTestId={openBtnTestId}
          cancelBtnTitle="キャンセル"
          cancelBtnHandelr={cancelBtnHandler}
          cancelBtnProps={{ disabled: isSendLoading }}
          submitBtnTitle={submitBtnTitle}
          submitBtnProps={{
            variant: 'primary',
            disabled: isFailed(),
            isLoading: isSendLoading,
          }}
          isOpen={isOpen}
          onOpen={onClickUserEdit}
          onClose={onClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          size="md"
          formId={formId}
          drawerCloseButtonProps={{ disabled: isSendLoading }}
          drawerFooterJustify="space-between"
        >
          <UsersForm
            formId={formId}
            startSending={startSending}
            onSuccess={onSuccess}
          />
        </DrawerForm>
      </FormProvider>
    );
  },
);
