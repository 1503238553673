import {
  Box,
  ListItem,
  Text,
  Tr as ChakraTr,
  UnorderedList,
} from '@chakra-ui/react';
import {
  AttributeTableBody,
  AttributeTableBodyProps,
} from 'admin/components/service/attribute/attributeTableBody';
import { HelpTooltip } from 'admin/components/ui/helpTooltip';
import { Table, Tbody, Th, Thead } from 'admin/components/ui/table';
import { Colors } from 'admin/define/colors';
import { ServiceAttributesType } from 'admin/types/service/attribute';
import { FC, memo } from 'react';

type Props = {
  attributes: ServiceAttributesType;
} & Omit<AttributeTableBodyProps, 'attribute'>;

export const AttributeTableComponent: FC<Props> = memo(
  ({
    attributes,
    onOpen,
    onOpenFormAttrDialog,
    onOpenChildAttrDialog,
    onOpenUserAttrDialog,
    onOpenUserAttrRequiredDialog,
    hasUniikey,
  }: Props) => (
    <Box>
      <Table w="100%" maxHeight="calc(100vh - 420px)">
        <Thead border="none">
          <ChakraTr border="none">
            <Th
              maxWidth="130px"
              minWidth="130px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
              borderRightStyle="none"
              borderRightWidth="0"
              borderRight="none"
            >
              属性ID
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
              borderRightStyle="none"
              borderRightWidth="0"
              borderLeftStyle="none"
              borderLeftWidth="0"
            >
              表示名（日本語）
            </Th>
            <Th
              maxWidth="180px"
              minWidth="180px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              表示名（外国語）
            </Th>
            <Th
              maxWidth="144px"
              minWidth="144px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              フィールド名
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="120px"
              minWidth="120px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              UIタイプ
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="120px"
              minWidth="120px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              データ型
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="144px"
              minWidth="144px"
              fontSize="14px"
              fontWeight="600"
              p={0}
              textAlign="center"
            >
              選択肢マスタ
              <Text as="span" color={Colors.RED_20}>
                (＊)
              </Text>
            </Th>
            <Th
              maxWidth="100px"
              minWidth="100px"
              fontSize="14px"
              fontWeight="600"
              p="16px"
            >
              個人情報
            </Th>
            {hasUniikey && (
              <Th
                hasBorder
                maxWidth="165px"
                minWidth="165px"
                fontSize="14px"
                fontWeight="600"
                p="16px"
              >
                共通マイページ表示
              </Th>
            )}
            {!hasUniikey && (
              <Th
                hasBorder
                maxWidth="210px"
                minWidth="210px"
                fontSize="14px"
                fontWeight="600"
                p="16px"
              >
                JSONパス
              </Th>
            )}
            <Th
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              テナント割当
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性が操作中のテナントで利用可能になります。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            <Th
              hasBorder
              maxWidth="154px"
              minWidth="154px"
              fontSize="14px"
              fontWeight="600"
              p="0"
              textAlign="center"
            >
              フォームで利用
              <HelpTooltip
                label={
                  <UnorderedList>
                    <ListItem>
                      ONにすると、対象の属性が操作中のテナントのフォームで表示されます。
                    </ListItem>
                    <ListItem>一度ONにした設定はOFFに変更できません。</ListItem>
                  </UnorderedList>
                }
              />
            </Th>
            {hasUniikey && (
              <>
                <Th
                  maxWidth="154px"
                  minWidth="154px"
                  fontSize="14px"
                  fontWeight="600"
                  p="0"
                  textAlign="center"
                >
                  会員登録時利用
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「テナント割当」が「ON」の場合、操作が可能です。
                        </ListItem>
                        <ListItem>
                          ONにすると、対象の属性がAuth
                          Hubの会員登録時に表示されます。
                        </ListItem>
                        <ListItem>
                          フィールド名がemailの属性は、初期値がONとなっておりOFFに変更できません。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Th>
                <Th
                  hasBorder
                  maxWidth="130px"
                  minWidth="130px"
                  fontSize="14px"
                  fontWeight="600"
                  p="16px"
                >
                  必須
                  <HelpTooltip
                    label={
                      <UnorderedList>
                        <ListItem>
                          「テナント割当」が「ON」の場合、操作が可能です。
                        </ListItem>
                        <ListItem>
                          ONにすると、対象の属性が会員登録時の必須登録項目になります。また、「会員登録時利用」も連動してONになります。
                        </ListItem>
                        <ListItem>
                          フィールド名がemailの属性は、初期値がONとなっておりOFFに変更できません。
                        </ListItem>
                      </UnorderedList>
                    }
                  />
                </Th>
              </>
            )}
          </ChakraTr>
        </Thead>
        <Tbody>
          {attributes.map((attribute, idx) => (
            <AttributeTableBody
              attribute={attribute}
              onOpen={onOpen}
              onOpenFormAttrDialog={onOpenFormAttrDialog}
              onOpenChildAttrDialog={onOpenChildAttrDialog}
              onOpenUserAttrDialog={onOpenUserAttrDialog}
              onOpenUserAttrRequiredDialog={onOpenUserAttrRequiredDialog}
              key={`attribute-table-body_${String(idx)}`}
              hasUniikey={hasUniikey}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  ),
);
