import { maxLength, urlOptions } from './options';

export default [
  {
    name: 'footer.termsName1',
    label: 'リンクテキスト フッターリンク1',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink1',
    label: 'URL フッターリンク1',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName2',
    label: 'リンクテキスト フッターリンク2',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink2',
    label: 'URL フッターリンク2',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName3',
    label: 'リンクテキスト フッターリンク3',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink3',
    label: 'URL フッターリンク3',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName4',
    label: 'リンクテキスト フッターリンク4',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink4',
    label: 'URL フッターリンク4',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName5',
    label: 'リンクテキスト フッターリンク5',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink5',
    label: 'URL フッターリンク5',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName6',
    label: 'リンクテキスト フッターリンク6',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink6',
    label: 'URL フッターリンク6',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.termsName7',
    label: 'リンクテキスト フッターリンク7',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'footer.termsLink7',
    label: 'URL フッターリンク7',
    type: 'Text',
    defaultValue: '',
    options: urlOptions,
    elementOptions: {},
  },
  {
    name: 'footer.copyrightText',
    label: '表示名 著作権表示',
    type: 'Text',
    defaultValue: '',
    options: {
      maxLength: maxLength(100),
    },
    elementOptions: {},
  },
] as const;
