import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  itemNameOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  // 前画面での入力内容表示領域	-
  {
    name: 'infoConfirmation.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '入力情報の確認',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'infoConfirmation.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '入力情報の確認',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'infoConfirmation.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      'まだ登録は完了していません。\n入力内容に誤りがなければ、この内容で登録してください。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'infoConfirmation.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'infoConfirmation.registerButtonName',
    label: 'ボタン名 完了画面に遷移',
    type: 'Text',
    defaultValue: '登録',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'infoConfirmation.backAndEditButtonName',
    label: 'ボタン名 前の画面に戻り修正',
    type: 'Text',
    defaultValue: '入力内容を修正する',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
