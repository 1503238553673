import {
  maxLength,
  headTitleOptions,
  screenNameOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'mypage.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'マイページ',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'マイページ',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.description',
    label: '案内文 マイページ',
    type: 'Textarea',
    defaultValue:
      '会員情報を管理します。\n利用状況や設定のご確認や、必要に応じた情報変更が可能です。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'mypage.description2',
    label: '案内文2 マイページ',
    type: 'Textarea',
    defaultValue: '',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'mypage.infoChangeButtonName',
    label: 'ボタン名 会員情報の確認・変更',
    type: 'Text',
    defaultValue: '会員情報の確認・変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.emailChangeButtonName',
    label: 'ボタン名 メールアドレス変更',
    type: 'Text',
    defaultValue: 'メールアドレス変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.passwordChangeButtonName',
    label: 'ボタン名 パスワード変更',
    type: 'Text',
    defaultValue: 'パスワード変更',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.logoutButtonName',
    label: 'ボタン名 ログアウト',
    type: 'Text',
    defaultValue: 'ログアウト',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'mypage.withdrawalProcedureButtonName',
    label: 'ボタン名 退会手続き',
    type: 'Text',
    defaultValue: '退会手続き',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
