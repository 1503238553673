import {
  Box,
  Flex,
  Switch,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import { getEnqueteCopy } from 'api/enquete/getEnqueteCopy';
import {
  EnqueteColumns,
  EnqueteColumnsFilter,
  EnqueteListType,
  isEnqueteListType
} from 'api/enquete/types';
import { DropDownMenu, Paginator } from 'components/common/atoms';
import { DownloadContents } from 'components/enquete/atoms/DownloadContents';
import { ChangePublicModal } from 'components/enquete/molecules/ChangePublicModal';
import { EnquetePreviewModal } from 'components/enquete/molecules/EnquetePreviewModal';
import { EnqueteListTableHeader } from 'components/enquete/organisms/EnqueteListTableHeader';
import { EnqueteSettingDrawerForm } from 'components/enquete/organisms/EnqueteSettingDrawerForm';
import { format } from 'date-fns';
import { useEnqueteList } from 'hooks/enquete/useEnqueteList';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';
import { useCopy } from 'hooks/useCopy';
import { useCustomToast } from 'hooks/useCustomToast';
import { useUserInfo } from 'hooks/useUserInfo';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { ChangeEvent, VFC, memo, useCallback, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import 'styles/common/customNavlink.css';
import { isGetValidationError } from 'utils/form';
import { errorToast, successToast } from 'utils/toast';
import { GeneratePublicUrlModal } from '../molecules/GeneratePublicUrlModal';

type EnqueteListInnerProps = {
  columns: EnqueteColumns[];
  statusList: EnqueteColumnsFilter[];
  onSort: (item: EnqueteColumns) => void;
  onColumnFilter: (
    item: EnqueteColumns,
    filterItem: EnqueteColumnsFilter,
  ) => void;
};
const DATETIME_FORMAT = 'yyyy年MM月dd日 HH:mm';

export const EnqueteListDefaultValue: EnqueteListType = {
  id: '',
  title: '',
  enqueteContents: [],
  startDate: '',
  endDate: '',
  statusName: '',
  ansCnt: 0,
  ansFileCnt: 0,
  isPublic: true,
  previewCode: '',
};

export const EnqueteListInner: VFC<EnqueteListInnerProps> = memo(
  ({ columns, statusList, onSort, onColumnFilter }: EnqueteListInnerProps) => {
    const { ClearEnqueteListCache } = useEnqueteUtil();
    const toast = useCustomToast();
    const tenantId = useUserTenantId();
    // const websiteList = useWebUrls({ tenantId });
    const { page, enqueteList } = useEnqueteList();
    const { isStaff, isAssistant } = useUserInfo();
    const [eventInfo, setEventInfo] = useState<ChangeEvent<HTMLInputElement>>();
    const [enqueteCurrentId, setEnqueteCurrentId] = useState('');
    const { copy } = useCopy();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formId, setFormId] = useState('');
    const [title, setTitle] = useState('');
    const [enqueteFormId, setEnqueteFormId] = useState('');
    const [previewCode, setPreviewCode] = useState('');

    const {
      isOpen: isOpenGenerate,
      onOpen: onOpenGenerate,
      onClose: onCloseGenerate,
    } = useDisclosure();
    const {
      isOpen: isOpenPreview,
      onOpen: onOpenPreview,
      onClose: onClosePreview,
    } = useDisclosure();
    // 公開非公開設定
    const onModalShow = useCallback(
      (event: ChangeEvent<HTMLInputElement>, itemId: string) => {
        if (!isStaff || !isAssistant) {
          setEventInfo(event);
          setEnqueteCurrentId(itemId);
          onOpen();
        } else {
          toast({
            status: 'error',
            position: 'bottom',
            duration: 2000,
            isClosable: true,
            title: '変更権限がありません。',
          });
        }
      },
      [onOpen, isStaff, isAssistant, toast],
    );

    // フォームのコピー
    const onDuplicate = useCallback(
      async (item: EnqueteListType) => {
        const res = await getEnqueteCopy(tenantId, item.id);
        if (isEnqueteListType(res)) {
          ClearEnqueteListCache();
          toast({
            ...successToast,
            duration: 4000,
            title: 'フォームをコピーしました',
            position: 'top-right',
            variant: 'copy',
          });
        }

        if (
          isGetValidationError<EnqueteListType>(
            res,
            Object.keys(EnqueteListDefaultValue),
          )
        ) {
          if (res?.others) {
            const errorMsg = Object.entries(res).map(([_, value]) => value);
            toast({
              ...errorToast,
              position: 'top-right',
              description: errorMsg[0],
            });
          }
        }
      },
      [tenantId, toast, ClearEnqueteListCache],
    );

    // 公開用URL生成のモーダルを開く
    const onGenerateModal = useCallback(() => {
      onOpenGenerate();
    }, [onOpenGenerate]);

    const menuItems = useCallback(
      (item: EnqueteListType) =>
        // 「公開用URLをコピー」を一応残しておく
        // let url = '';
        // if (websiteList && websiteList[0]) {
        //   const baseUrl = websiteList[0];
        //   const param = `uecfcode=${item.id}`;
        //   const conjunction = baseUrl.includes('?') ? '&' : '?';

        //   const pos = baseUrl.indexOf('#');
        //   if (pos !== -1) {
        //     url = `${baseUrl.slice(
        //       0,
        //       pos,
        //     )}${conjunction}${param}${baseUrl.slice(pos)}`;
        //   } else {
        //     url = `${baseUrl}${conjunction}${param}`;
        //   }
        // }

        [
          {
            menuItemType: 'text',
            label: '公開用URLを生成',
            onClick: () => {
              setFormId(item.id);
              setTitle(item.title);
              onGenerateModal();
            },
          },
          {
            menuItemType: 'text',
            label: 'URLパラメータをコピー',
            onClick: () => {
              copy(`uecfcode=${item.id}`);
            },
          },
          // {
          //   menuItemType: 'children',
          //   onClick: () => {
          //     if (url !== '') copy(url);
          //   },
          //   children: (
          //     <Box w="100%" maxW="215px" m={0} p={0}>
          //       <Box m={0} p={0}>
          //         公開用URLをコピー
          //       </Box>
          //       <Box m={0} pl={3} pr={1} pb={1} color="#696f75" fontSize="11px">
          //         {url || '公開用URLを生成できません'}
          //       </Box>
          //     </Box>
          //   ),
          // },
          {
            menuItemType: 'divider',
          },
          {
            menuItemType: 'text',
            label: 'フォームを複製',
            testId: 'enquete-copy',
            onClick: () => {
              void onDuplicate(item);
            },
          },
        ],
      [copy, onDuplicate, onGenerateModal],
    );

    // プレビューボタン押下時の処理
    const onPreviewHandler = useCallback(
      (id: string, code: string) => {
        setEnqueteFormId(id);
        setPreviewCode(code);
        onOpenPreview();
      },
      [enqueteFormId],
    );

    return (
      <>
        <Box
          w="calc(100vw - 100px)"
          h="100vh"
          overflowY="auto"
          maxHeight="calc(100vh - 210px)"
        >
          <Table variant="striped">
            <Thead position="sticky" zIndex="sticky" top={0} bgColor="white">
              <EnqueteListTableHeader
                columns={columns}
                statusList={statusList}
                onSort={onSort}
                onColumnFilter={onColumnFilter}
              />
            </Thead>
            <Tbody>
              {enqueteList?.map((item) => (
                <Tr key={item.id}>
                  <Td fontSize={12}>
                    <Flex alignItems="center">
                      {item.id}
                      <DropDownMenu
                        menuType="icon"
                        items={menuItems(item)}
                        icon={<BsChevronDown />}
                        variant="none"
                        width="100%"
                        zIndex="1500"
                      />
                    </Flex>
                  </Td>
                  <Td fontSize={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <NavLink
                        to={`/enquete/form?formId=${item.id || ''}`}
                        className="custom-nav-link--no-scroll-bar"
                      >
                        {item.title}
                      </NavLink>
                      <Box position="relative" ml={4}>
                        <EnqueteSettingDrawerForm
                          enqueteId={item.id || ''}
                          buttonName="詳細設定"
                        />
                      </Box>
                    </Box>
                  </Td>
                  <Td fontSize={12}>
                    {item.startDate
                      ? format(new Date(item.startDate), DATETIME_FORMAT)
                      : '-'}
                    {item.endDate && <span>〜</span>}
                    <br />
                    {item.endDate &&
                      format(new Date(item.endDate), DATETIME_FORMAT)}
                  </Td>
                  <Td fontSize={12}>{item.statusName}</Td>
                  <Td fontSize={12}>{item.ansCnt}</Td>
                  <Td fontSize={12}>
                    {/* TODO: フォーム結果の表示制御 */}
                    {item.id !== '' && (
                      <a
                        href={`/enquete/results/${item.id}`}
                        style={{
                          textDecoration: 'underline',
                          display: 'block',
                          margin: '2px 0',
                        }}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        ・フォーム結果詳細
                      </a>
                    )}
                    {item.id !== '' &&
                      (item.ansCnt > 0 || item.ansFileCnt > 0) && (
                        <DownloadContents data={item} />
                      )}
                  </Td>
                  <Td>
                    <Switch
                      id="email-alerts"
                      size="sm"
                      onChange={(e) => onModalShow(e, item.id || '')}
                      isChecked={item.isPublic}
                      isDisabled={isStaff || isAssistant}
                    />
                  </Td>
                  <Td>
                    <Box>
                      <Button
                        variant="outline"
                        bgColor="white"
                        border="1px"
                        borderColor="gray.400"
                        onClick={() => onPreviewHandler(item.id, item?.previewCode)}
                      >
                        プレビュー
                      </Button>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <ChangePublicModal
            itemId={enqueteCurrentId}
            eventInfo={eventInfo || undefined}
            isOpen={isOpen}
            onClose={onClose}
          />
          <GeneratePublicUrlModal
            formId={formId}
            title={title}
            isOpen={isOpenGenerate}
            onClose={onCloseGenerate}
          />
          <EnquetePreviewModal
            isOpen={isOpenPreview}
            onClose={onClosePreview}
            enqueteFormId={enqueteFormId}
            previewCode={previewCode}
          />
        </Box>
        <Paginator pageName="/enquete/" config={page} />
      </>
    );
  },
);

