import {
  Button,
  ButtonGroup,
  Link,
  Switch,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { EditIcon, CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  AuthHubActivateFormType,
  AuthHubsResultType,
  AuthHubShallowType,
  AuthHubType,
} from 'admin/types/authHub';
import { memo, useCallback } from 'react';
import { QueryObserverResult } from 'react-query';

export const DataRow = memo(
  ({
    userPoolId,
    tenantId,
    detailPath,
    item,
    setDeleteTarget,
    setCopyTargetId,
    setEditTarget,
    activateMutate,
    refetch,
  }: {
    userPoolId?: string;
    tenantId?: string;
    detailPath: string;
    item: AuthHubShallowType;
    setCopyTargetId: (_?: string) => void;
    setEditTarget: (_?: [string, string]) => void;
    setDeleteTarget: (_?: [string, string]) => void;
    activateMutate: (_: AuthHubActivateFormType) => Promise<AuthHubType>;
    refetch: () => Promise<QueryObserverResult<AuthHubsResultType, unknown>>;
  }) => {
    const { id } = item;
    const onActivate = useCallback(async () => {
      if (userPoolId || tenantId) {
        await activateMutate({ userPoolId, tenantId, id });
        await refetch();
      }
    }, [userPoolId, tenantId, id, activateMutate, refetch]);

    return (
      <Tr>
        <Td>
          <Link href={`${detailPath}/${item.id}/theme`}>
            <Text color="#3062d4" as="u">
              {item.id}
            </Text>
          </Link>
        </Td>
        <Td>
          <Text as="span" mr={2}>
            {item.name}
          </Text>
          <Button
            borderWidth="0"
            color="#3062d4"
            onClick={() => setEditTarget([id, item.name])}
          >
            <EditIcon />
          </Button>
        </Td>
        <Td>
          <ButtonGroup variant="outline" spacing="2">
            <Button
              bgColor="white"
              borderColor="primary"
              borderWidth="1px"
              color="primary"
              onClick={() => setCopyTargetId(id)}
            >
              <CopyIcon />
              <Text as="span" ml={2}>
                複製
              </Text>
            </Button>
            <Button
              bgColor="white"
              borderColor="red.500"
              borderWidth="1px"
              color="red.500"
              onClick={() => setDeleteTarget([id, item.name])}
              disabled={item.isActive}
            >
              <DeleteIcon />
              <Text as="span" ml={2}>
                削除
              </Text>
            </Button>
          </ButtonGroup>
        </Td>
        <Td>
          <Switch
            id={`auth-hub-setting-active-${id}`}
            size="md"
            isChecked={item.isActive}
            onChange={onActivate}
          />
          <Text as="span" ml={2}>
            {item.isActive ? '有効' : '無効'}
          </Text>
        </Td>
      </Tr>
    );
  },
);
