import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
  itemNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'newPasswordEntry.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: 'パスワード登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '以下のメールアドレスに認証コードメールを送信しました。\n\nメールに記載されている６桁のコードを入力し、\n新しいパスワードを設定して「パスワードの更新」を押下してください。。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.codeEntryItemName',
    label: '入力欄名 認証コード',
    type: 'Text',
    defaultValue: 'コードの入力',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.passwordItemName',
    label: '入力欄名 新パスワード',
    type: 'Text',
    defaultValue: '新しいパスワード',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.passwordConfirmationItemName',
    label: '入力欄名 新パスワード（確認用）',
    type: 'Text',
    defaultValue: '新しいパスワード（確認用）',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.continueButtonName',
    label: 'ボタン名 パスワードの更新',
    type: 'Text',
    defaultValue: 'パスワードの更新',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.backButtonName',
    label: 'ボタン名 戻る',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'newPasswordEntry.resendInstructionText',
    label: 'ボタン名 戻る',
    type: 'Textarea',
    defaultValue:
      '認証コードメールが届かない場合、\n前の画面に戻り再度送信をお試しください。',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
