// ----- Mutate関連 -----
const SAVE_AUTH_HUB = {
  ERROR: '申し訳ございません。Auth Hub設定の保存に失敗しました。',
  FAILED:
    'Auth Hub設定の保存に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

const GET_AUTH_HUB = {
  ERROR: '申し訳ございません。Auth Hub設定の取得に失敗しました。',
  NOT_FOUND:
    'Auth Hub設定の取得に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
  ID_MISSING: 'Auth Hub設定のIDが不足しています。',
} as const;

const DELETE_AUTH_HUB = {
  ERROR: '申し訳ございません。Auth Hub設定の削除に失敗しました。',
  FAILED:
    'Auth Hub設定の削除に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

const COPY_AUTH_HUB = {
  ERROR: '申し訳ございません。Auth Hub設定のコピーに失敗しました。',
  FAILED:
    'Auth Hub設定のコピーに失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

const ACTIVATE_AUTH_HUB = {
  ERROR: '申し訳ございません。Auth Hub設定の有効化に失敗しました。',
  FAILED:
    'Auth Hub設定の有効化に失敗しました。恐れ入りますが時間をおいて再度実行してください。',
} as const;

export const AUTH_HUB = {
  AUTH_HUB: {
    ACTIVATE_AUTH_HUB,
    COPY_AUTH_HUB,
    DELETE_AUTH_HUB,
    GET_AUTH_HUB,
    SAVE_AUTH_HUB,
  },
};
