import { StackDivider, VStack } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { AccessSecretKey } from 'admin/components/service/setting/accessSecretKey';
import { ApiKey } from 'admin/components/service/setting/apiKey';
import { ApiWhiteList } from 'admin/components/service/setting/apiWhiteList';
import { Colors } from 'admin/define/colors';
import { useSetting } from 'admin/hooks/service/setting/useSetting';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { Dispatch, FC, memo, SetStateAction } from 'react';

type Props = {
  setHeaderLabel: Dispatch<SetStateAction<string>>;
};

const FetchSettingBody: FC<Props> = memo(({ setHeaderLabel }: Props) => {
  const { data: settingData } = useSetting();
  if (!settingData) {
    return null;
  }

  setHeaderLabel(settingData.label);

  return (
    <VStack
      spacing={8}
      alignItems="flex-start"
      divider={<StackDivider borderColor={Colors.GRAY_30} />}
    >
      <ApiKey settingData={settingData} />
      <AccessSecretKey settingData={settingData} />
      <ApiWhiteList settingData={settingData} />
    </VStack>
  );
});

export const settingBody: FC<Props> = memo(({ setHeaderLabel }: Props) => (
  <FetchSettingBody setHeaderLabel={setHeaderLabel} />
));

export const SettingBody = memo(
  withSuspenseAndErrorBoundary(settingBody, {
    ErrorComponent: <ErrorContents name="その他の設定" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
