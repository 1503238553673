import { colorOptions } from './options';

export default [
  // 画面全体の設定
  {
    name: 'theme.backgroundColor',
    label: '画面全体の背景',
    type: 'ColorPicker',
    defaultValue: '#F3F3F2',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.contentBackgroundColor',
    label: 'コンテンツエリアの背景',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.contentBorderColor',
    label: 'コンテンツエリアの枠線',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.focusBaseColor',
    label: 'フォーカスのベースカラー',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.hoverBaseColor',
    label: 'ホバーのベースカラー',
    type: 'ColorPicker',
    defaultValue: '#000000',
    options: colorOptions,
    elementOptions: {},
  },
  // テキスト設定
  {
    name: 'theme.titleTextColor',
    label: 'タイトルテキスト',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.baseTextColor',
    label: 'ベーステキスト',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  // インプットテキスト設定
  {
    name: 'theme.inputTextColor',
    label: 'インプットテキスト',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.inputTextBorderColor',
    label: 'インプットテキストの枠線',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.inputTextBackgroundColor',
    label: 'インプットテキストの背景',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  // コンテンツエリア設定
  {
    name: 'theme.contentSeparatorColor',
    label: 'コンテンツエリアの区切り線',
    type: 'ColorPicker',
    defaultValue: '#111827',
    options: colorOptions,
    elementOptions: {},
  },
  // リンク設定
  {
    name: 'theme.linkTextColor',
    label: 'リンクテキスト',
    type: 'ColorPicker',
    defaultValue: '#1A0DAB',
    options: colorOptions,
    elementOptions: {},
  },
  // ボタン設定
  {
    name: 'theme.primaryButtonColor',
    label: 'プライマリーボタン',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.primaryButtonTextColor',
    label: 'プライマリーボタンのテキスト',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.secondaryButtonColor',
    label: 'セカンダリーボタン',
    type: 'ColorPicker',
    defaultValue: '#FFFFFF',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.secondaryButtonTextColor',
    label: 'セカンダリーボタンのテキスト',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.secondaryButtonBorderColor',
    label: 'セカンダリーボタンの枠線',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.selectedColor',
    label: 'チェックボックス選択肢 / ラジオボタン選択時',
    type: 'ColorPicker',
    defaultValue: '#0033CC',
    options: colorOptions,
    elementOptions: {},
  },
  // その他
  {
    name: 'theme.requiredMarkColor',
    label: '必須マーク',
    type: 'ColorPicker',
    defaultValue: '#D32F2F',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.successMessageColor',
    label: '成功メッセージ',
    type: 'ColorPicker',
    defaultValue: '#218756',
    options: colorOptions,
    elementOptions: {},
  },
  {
    name: 'theme.errorMessageColor',
    label: 'エラーメッセージ',
    type: 'ColorPicker',
    defaultValue: '#D32F2F',
    options: colorOptions,
    elementOptions: {},
  },
] as const;
