import {
  Box,
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useFilterAttributes } from 'admin/hooks/userPool/attribute/useFilterAttributes';
import { AttributeAndGroupType } from 'admin/types/userPool/attribute';
import {
  enqueteFormSelectOption,
  EnqueteFormType,
  EnqueteFormTypeDefaultValue,
  isEnqueteUserAttributeGroup,
  UserAttribute,
} from 'api/enquete/types';
import { ErrorTextMsg } from 'components/common/atoms';
import { SelectForm } from 'components/common/molecules';
import { CheckElement } from 'components/enquete/atoms/CheckElement';
import { CheckMatrixElement } from 'components/enquete/atoms/CheckMatrixElement';
import { ClipStudioShareElement } from 'components/enquete/atoms/ClipStudioShareElement';
import { DateElement } from 'components/enquete/atoms/DateElement';
import { DeleteContentsDialog } from 'components/enquete/atoms/DeleteContentsDialog';
import { ExplainTextElement } from 'components/enquete/atoms/ExplainTextElement';
import { HeadingElement } from 'components/enquete/atoms/HeadingElement';
import { ImageUploader } from 'components/enquete/atoms/ImageUploader';
import { MailMagazineElement } from 'components/enquete/atoms/MailMagazineElement';
import { PageSplit } from 'components/enquete/atoms/PageSplit';
import { RadioElement } from 'components/enquete/atoms/RadioElement';
import { RadioMatrixElement } from 'components/enquete/atoms/RadioMatrixElement';
import { SelectElement } from 'components/enquete/atoms/SelectElement';
import { TextElement } from 'components/enquete/atoms/TextElement';
import { UploadInfoElement } from 'components/enquete/atoms/UploadInfoElement';
import { UserInfoElement } from 'components/enquete/atoms/UserInfoElement';
import { MailMagazine } from 'components/setting/mailMagazine/typed';
import { useEnqueteContentGroups } from 'hooks/enquete/useEnqueteContentGroups';
import { useEnqueteUserAttributes } from 'hooks/enquete/useEnqueteUserAttributes';
import { useUserTenantId } from 'hooks/user/useUserTenantId';
import { useUserInfo } from 'hooks/useUserInfo';
import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FieldError,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  useFormContext,
} from 'react-hook-form';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';
import { VscDiffAdded } from 'react-icons/vsc';
import { useLocation } from 'react-router-dom';
import { UserAttributeElement } from '../atoms/UserAttributeElement';

type EnqueteFormElementProps<T> = {
  index: number;
  baseName: Path<T>;
  copySelectType: string;
  mailMagazineList: MailMagazine[];
  onUp: (
    index: number,
    isFirst: boolean,
    curRef: RefObject<HTMLDivElement>,
    prevRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onDown: (
    index: number,
    isLast: boolean,
    curRef: RefObject<HTMLDivElement>,
    nextRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onAdd: (index: number) => void;
  onAddElement: () => void;
  onInsertElement: (index: number, form: EnqueteFormType) => void;
  onCopy: (index: number) => void;
  onDelete: (index: number) => void;
  apiData: EnqueteFormType[];
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  setErrorList: Dispatch<SetStateAction<string[]>>;
  isThanksFlg: boolean;
  isFirst: boolean;
  isLast: boolean;
  curRef: RefObject<HTMLDivElement>;
  prevRef: RefObject<HTMLDivElement> | null;
  nextRef: RefObject<HTMLDivElement> | null;
  isDragging: boolean;
  uniqueId: () => string;
};
type FetchEnqueteFormElementProps<T> = {
  hasUserAttributes: boolean;
  hasEnqueteContentGroups: boolean;
  isAdmin: boolean;
} & EnqueteFormElementProps<T>;

/**
 * enqueteFormSelectOption
 * ※isChartはグラフ表示で必要なフラグです
 * グラフ表示時にAPIを実行しない（グラフ表示しない）場合はfalseとしてください。
 * グラフ表示する場合はtrueとしてください
 */

export const EnqueteFormSelectDecoOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormSelect-1',
    value: 'heading',
    name: '見出し',
    isChart: false,
  },
  {
    id: 'EnqueteFormSelect-2',
    value: 'richText',
    name: '説明文章',
    isChart: false,
  },
  {
    id: 'EnqueteFormSelect-3',
    value: 'uploadImage',
    name: '説明画像',
    isChart: false,
  },
  // { id: 'EnqueteFormSelect-4', value: 'split', name: 'ページ区切り設置' },
];

export const EnqueteFormSelectOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormSelect-4',
    value: 'text',
    name: 'フリーアンサー',
    isChart: true,
  },
  {
    id: 'EnqueteFormSelect-5',
    value: 'check',
    name: 'チェックボックス（複数選択）',
    isChart: true,
  },
  {
    id: 'EnqueteFormSelect-6',
    value: 'select',
    name: 'プルダウン（単一選択）',
    isChart: true,
  },
  {
    id: 'EnqueteFormSelect-7',
    value: 'radio',
    name: 'ラジオボタン（単一選択）',
    isChart: true,
  },
  // {
  //   id: 'EnqueteFormSelect-8',
  //   value: 'checkMatrix',
  //   name: 'チェックマトリクス',
  // },
  // {
  //   id: 'EnqueteFormSelect-9',
  //   value: 'radioMatrix',
  //   name: 'ラジオマトリクス',
  // },
  {
    id: 'EnqueteFormSelect-10',
    value: 'upload',
    name: 'ファイルアップロード',
    isChart: false,
  },
  {
    id: 'EnqueteFormSelect-11',
    value: 'date',
    name: '日付',
    isChart: false,
  },
  {
    id: 'EnqueteFormSelect-12',
    value: 'time',
    name: '時刻',
    isChart: false,
  },
  {
    id: 'EnqueteFormSelect-13',
    value: 'datetime',
    name: '日付＋時刻',
    isChart: false,
  },
];

export const EnqueteFormPrivatePresetOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormPrivatePresetOptions-1',
    value: 'userName',
    name: '●姓名漢字セット',
    isChart: false,
  },
  {
    id: 'EnqueteFormPrivatePresetOptions-2',
    value: 'userKana',
    name: '●姓名カナセット',
    isChart: false,
  },
  {
    id: 'EnqueteFormPrivatePresetOptions-3',
    value: 'userAddress',
    name: '●郵便番号・都道府県・住所セット（住所で1カラム）',
    isChart: true,
  },
  {
    id: 'EnqueteFormPrivatePresetOptions-4',
    value: 'userTelephone',
    name: '●電話番号',
    isChart: false,
  },
  {
    id: 'EnqueteFormPrivatePresetOptions-5',
    value: 'userEmail',
    name: '●メールアドレス',
    isChart: false,
  },
];

export const EnqueteFormPresetOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormPresetOptions-1',
    value: 'userGender',
    name: '性別',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-2',
    value: 'userBirthday',
    name: '生年月日',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-3',
    value: 'userBirthYear',
    name: '生年のみ',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-4',
    value: 'userPrefectures',
    name: '都道府県のみ',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-5',
    value: 'userJob',
    name: '就業形態',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-6',
    value: 'userIndustry',
    name: '業種',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-7',
    value: 'userOccupiation',
    name: '職種',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-8',
    value: 'userAnnualIncome',
    name: 'ご自身の年収',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-9',
    value: 'userHouseholdIncome',
    name: '世帯年収',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-16',
    value: 'userMonthlyPocketMoney',
    name: '1ヶ月に自由に使えるお金',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-10',
    value: 'userMarriage',
    name: '結婚・それに相当する関係の有無',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-17',
    value: 'userFamilyInfoStructure',
    name: '家族情報 同居している方',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-11',
    value: 'userHasChild',
    name: '子どもの有無',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-18',
    value: 'userChild1Gender',
    name: 'お子さま① 性別',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-19',
    value: 'userChild1Birthday',
    name: 'お子さま① 生年月日',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-20',
    value: 'userChild2Gender',
    name: 'お子さま② 性別',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-21',
    value: 'userChild2Birthday',
    name: 'お子さま② 生年月日',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-22',
    value: 'userChild3Gender',
    name: 'お子さま③ 性別',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-23',
    value: 'userChild3Birthday',
    name: 'お子さま③ 生年月日',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-14',
    value: 'userWorkplace',
    name: '勤務先名',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-15',
    value: 'userDepartment',
    name: '部署名',
    isChart: false,
  },
  {
    id: 'EnqueteFormPresetOptions-12',
    value: 'userPosition',
    name: '役職',
    isChart: true,
  },
  {
    id: 'EnqueteFormPresetOptions-13',
    value: 'userEmployees',
    name: '勤務先従業員数',
    isChart: true,
  },
];

export const EnqueteFormPrivateOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormPrivateOptions-1',
    value: 'userPrivateAddress',
    name: '●郵便番号・都道府県・住所・番地セット（住所・番地で2カラム）',
    isChart: false,
  },
  {
    id: 'EnqueteFormPrivateOptions-2',
    value: 'userPrivateText',
    name: '●フリーアンサー（個人情報指定）',
    isChart: false,
  },
];

export const EnqueteFormOtherOptions: enqueteFormSelectOption[] = [
  {
    id: 'EnqueteFormOtherOptions-1',
    value: 'clip',
    name: 'CLIP STUDIO SHARE',
    isChart: false,
  },
  {
    id: 'EnqueteFormOtherOptions-2',
    value: 'mailMagazine',
    name: 'メルマガ購読管理',
    isChart: false,
  },
];

type valueOf<T> = T[keyof T];

// EnqueteFormTypeDefaultValueと設問パーツをマージする
const mergeObjects = (
  defaultValue: EnqueteFormType,
  newFormElement: EnqueteFormType,
) => {
  const defaultMap = new Map<keyof EnqueteFormType, valueOf<EnqueteFormType>>();
  const newValueMap = new Map<
    keyof EnqueteFormType,
    valueOf<EnqueteFormType>
  >();

  // 最初のオブジェクトのキーと値をMapに追加
  (Object.keys(defaultValue) as (keyof EnqueteFormType)[]).forEach(
    (key: keyof EnqueteFormType) => {
      if (key !== 'id') {
        defaultMap.set(key, defaultValue[key]);
      }
    },
  );

  // 二つ目のオブジェクトのキーと値をMapに追加または更新
  (Object.keys(newFormElement) as (keyof EnqueteFormType)[]).forEach(
    (key: keyof EnqueteFormType) => {
      if (newFormElement[key] && key !== 'id') {
        newValueMap.set(key, newFormElement[key]);
      }
    },
  );
  const mergeUserMap = new Map([
    ...defaultMap.entries(),
    ...newValueMap.entries(),
  ]);

  const result = Object.fromEntries(mergeUserMap.entries());

  return result as EnqueteFormType;
};

/**
 * 各種エレメント
 */
export const FetchEnqueteFormElement = <T extends FieldValues>({
  index,
  baseName,
  copySelectType,
  mailMagazineList,
  onUp,
  onDown,
  onAdd,
  onCopy,
  onDelete,
  apiData,
  setIsEdited,
  setErrorList,
  isThanksFlg,
  isFirst,
  isLast,
  curRef,
  prevRef,
  nextRef,
  hasUserAttributes = true,
  hasEnqueteContentGroups = true,
  isAdmin = false,
  isDragging,
  uniqueId,
  onInsertElement,
}: FetchEnqueteFormElementProps<T>): ReturnType<FC> => {
  const [selected, setSelected] = useState(copySelectType);
  const [disabledFlg, setDisabledFlg] = useState(false);
  const { getValues, setValue, watch, register, formState } =
    useFormContext<T>();

  const tenantId = useUserTenantId();
  // ToDo: 組織管理画面の場合はエンドポイントが異なる為その切り分け
  const { getFormType, hasPresetForm } = useUserInfo();
  const userAttributes = useEnqueteUserAttributes({
    enabled: hasUserAttributes && !isAdmin,
  });
  const adminUserAttributes = useFilterAttributes({
    enabled: isAdmin,
  });
  const enqueteContentGroups = useEnqueteContentGroups({
    enabled: hasEnqueteContentGroups && !isAdmin,
  });

  /**
   * エラーが発生した場合、親コンポーネントにエラー設問IDのリストを返却する
   */
  useEffect(() => {
    if (Object.keys(formState.errors).length === 0) return;
    const errors = formState.errors as { [key: string]: FieldError[] };
    if (errors[baseName] === undefined || !Array.isArray(errors[baseName]))
      return;
    const errorList: string[] = [];
    errors[baseName].forEach((val, idx) => {
      if (val !== undefined) {
        errorList.push(`${baseName}.${idx}`);
      }
    });
    setErrorList(errorList);
  }, [index, formState, setErrorList, baseName]);

  // 設問グループパーツが選択された場合の設問パーツ生成処理
  const generateContentGroupUnits = useCallback(
    (groupUnits: EnqueteFormType[]) => {
      onDelete(index);
      groupUnits.forEach((groupUnit, idx) => {
        /**
         * パーツ生成時は、部的に保有している場合のあるcondContentのIDはフォーム作成時には不要となる
         * condContentフィールドを削除するため、新しいObjectを作成する
         * @see https://www.notion.so/uniikey/f6dff33129ef41e7837f67dff4fe8393
         */
        const unit = Object.assign(groupUnit, {});
        // condContentフィールドを削除する
        delete unit.condContent;
        const targetTypeOrUserAttrId =
          unit.uniikeyUserAttributeSettingId ?? unit.type ?? '';
        const newIdx = index + idx;
        const newValue = mergeObjects(EnqueteFormTypeDefaultValue, unit);
        onInsertElement(newIdx, {
          ...newValue,
          typeOrUserAttrId: targetTypeOrUserAttrId,
          dndId: `${uniqueId()}`,
        });
      });
    },
    [index],
  );

  // ユーザー属性の設問パーツを生成
  const generateUserAttributeUnit = useCallback(
    (userAttr: UserAttribute | AttributeAndGroupType) => {
      let tmpType = '';
      let tmpSelected = '';
      let tmpUUASId = '';
      if (isEnqueteUserAttributeGroup(userAttr)) {
        // ユーザー属性グループ
        tmpType = 'uni_group';
        tmpSelected = 'uni_group';
        tmpUUASId = userAttr.id;
      } else {
        // ユーザー属性
        tmpType = `uni_${userAttr.uiType}`;
        tmpSelected = `uni_${userAttr.uiType}`;
        tmpUUASId = userAttr.id;
      }
      setValue(
        `${baseName}.${index}.uniikeyUserAttributeSettingId` as Path<T>,
        tmpUUASId as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      setValue(
        `${baseName}.${index}.type` as Path<T>,
        tmpType as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      setSelected(tmpSelected);
    },
    [index],
  );

  // 従来の設問パーツを生成
  const generateNormalFormUnit = useCallback(
    (typeOrId: PathValue<T, Path<T>>) => {
      const tmpType = typeOrId;
      const tmpSelected = typeOrId;
      const tmpUUASId = '';
      setValue(
        `${baseName}.${index}.uniikeyUserAttributeSettingId` as Path<T>,
        tmpUUASId as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      setValue(
        `${baseName}.${index}.type` as Path<T>,
        tmpType as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      setSelected(tmpSelected);
    },
    [index],
  );

  // 設問プルダウン変更時に設問パーツを生成する処理
  const setTypeAndUserAttrId = useCallback(() => {
    // ドラッグ中は下記処理を行わない
    if (isDragging) return;
    const typeOrId = getValues(
      `${baseName}.${index}.typeOrUserAttrId` as Path<T>,
    );
    const userAttr = isAdmin
      ? adminUserAttributes.find((v) => v.id === typeOrId)
      : userAttributes.find((v) => v.id === typeOrId);
    const contentGroup = enqueteContentGroups.find((v) => v.id === selected);

    if (contentGroup) {
      // 設問グループ
      generateContentGroupUnits(contentGroup.groupUnits);

      return;
    }
    if (userAttr) {
      // ユーザー属性
      generateUserAttributeUnit(userAttr);
    } else {
      // 従来の設問
      generateNormalFormUnit(typeOrId);
    }
  }, [selected]);

  useEffect(() => {
    const subscription = watch((_, { name: watchName }) => {
      if (watchName === `${baseName}.${index}.typeOrUserAttrId`) {
        setTypeAndUserAttrId();
      } else if (watchName === `${baseName}.${index}.fileUploadFormat`) {
        setValue(
          `${baseName}.${index}.fileUploadNum` as Path<T>,
          (getValues(`${baseName}.${index}.fileUploadNum` as Path<T>) ||
            1) as UnpackNestedValue<PathValue<T, Path<T>>>,
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, getValues, index]);

  const FormElement = () => {
    switch (selected) {
      case 'heading':
        return (
          <HeadingElement<T>
            baseName={baseName}
            placeholder="見出しを入力してください。"
            option={['inline', 'colorPicker', 'fontSize']}
            index={index}
            position="heading"
            setIsEdited={setIsEdited}
          />
        );
      case 'text':
        return (
          <TextElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'richText':
        return (
          <ExplainTextElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'uploadImage':
        return <ImageUploader<T> baseName={baseName} index={index} />;
      case 'select':
        return (
          <SelectElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'check':
        return (
          <CheckElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'radio':
        return (
          <RadioElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'checkMatrix':
        return (
          <CheckMatrixElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'radioMatrix':
        return (
          <RadioMatrixElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'upload':
        return (
          <UploadInfoElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'date':
        return (
          <DateElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
            text="日付"
          />
        );
      case 'time':
        return (
          <DateElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
            text="時刻"
          />
        );
      case 'datetime':
        return (
          <DateElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
            text="日付＋時刻"
          />
        );
      case 'split':
        return <PageSplit index={index} />;
      case 'userName':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に「姓」と「名」の入力欄を自動で挿入します"
            presetNum={1}
            setIsEdited={setIsEdited}
          />
        );
      case 'userKana':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に「姓（カナ）」と「名（カナ）」の入力欄を自動で挿入します"
            presetNum={2}
            setIsEdited={setIsEdited}
          />
        );
      case 'userAddress':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text={[
              'フォーム表示時に以下の項目を自動で挿入します',
              <br key="1" />,
              '郵便番号入力欄',
              <br key="2" />,
              '都道府県選択',
              <br key="3" />,
              '住所（市区町村番地以降）',
            ]}
            presetNum={3}
            setIsEdited={setIsEdited}
          />
        );
      case 'userPrefectures':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に都道府県の選択欄を自動で挿入します"
            presetNum={4}
            setIsEdited={setIsEdited}
          />
        );
      case 'userTelephone':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に電話番号の入力欄を自動で挿入します"
            presetNum={5}
            setIsEdited={setIsEdited}
          />
        );
      case 'userEmail':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にメールアドレスの入力欄を自動で挿入します"
            presetNum={6}
            setIsEdited={setIsEdited}
          />
        );
      case 'userGender':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に性別の選択欄を自動で挿入します"
            presetNum={7}
            setIsEdited={setIsEdited}
          />
        );
      case 'userBirthday':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に生年月日の選択欄を自動で挿入します"
            presetNum={8}
            setIsEdited={setIsEdited}
          />
        );
      case 'userBirthYear':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に生年の選択欄を自動で挿入します"
            presetNum={9}
            setIsEdited={setIsEdited}
          />
        );
      case 'userJob':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に就業形態の選択欄を自動で挿入します"
            presetNum={10}
            setIsEdited={setIsEdited}
          />
        );
      case 'userIndustry':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に業種の選択欄を自動で挿入します"
            presetNum={11}
            setIsEdited={setIsEdited}
          />
        );
      case 'userOccupiation':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に職種の選択欄を自動で挿入します"
            presetNum={12}
            setIsEdited={setIsEdited}
          />
        );
      case 'userAnnualIncome':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にご自身の年収の選択欄を自動で挿入します"
            presetNum={13}
            setIsEdited={setIsEdited}
          />
        );
      case 'userHouseholdIncome':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に世帯年収の選択欄を自動で挿入します"
            presetNum={14}
            setIsEdited={setIsEdited}
          />
        );
      case 'userMonthlyPocketMoney':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に1ヶ月に自由に使えるお金の選択欄を自動で挿入します"
            presetNum={16}
            setIsEdited={setIsEdited}
          />
        );
      case 'userMarriage':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に結婚・それに相当する関係の有無の選択欄を自動で挿入します"
            presetNum={15}
            setIsEdited={setIsEdited}
          />
        );
      case 'userFamilyInfoStructure':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に家族情報 同居している方の選択欄を自動で挿入します"
            presetNum={17}
            setIsEdited={setIsEdited}
          />
        );
      case 'userHasChild':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に子どもの有無の選択欄を自動で挿入します"
            presetNum={16}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild1Gender':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま① 性別の選択欄を自動で挿入します"
            presetNum={18}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild1Birthday':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま① 生年月日の選択欄を自動で挿入します"
            presetNum={19}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild2Gender':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま② 性別の選択欄を自動で挿入します"
            presetNum={20}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild2Birthday':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま② 生年月日の選択欄を自動で挿入します"
            presetNum={21}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild3Gender':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま③ 性別の選択欄を自動で挿入します"
            presetNum={22}
            setIsEdited={setIsEdited}
          />
        );
      case 'userChild3Birthday':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時にお子さま③ 生年月日の選択欄を自動で挿入します"
            presetNum={23}
            setIsEdited={setIsEdited}
          />
        );
      case 'userPosition':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に役職の選択欄を自動で挿入します"
            presetNum={17}
            setIsEdited={setIsEdited}
          />
        );
      case 'userEmployees':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に勤務先従業員数の選択欄を自動で挿入します"
            presetNum={18}
            setIsEdited={setIsEdited}
          />
        );
      case 'userWorkplace':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に勤務先名の入力欄を自動で挿入します"
            presetNum={19}
            setIsEdited={setIsEdited}
          />
        );
      case 'userDepartment':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text="フォーム表示時に部署名の入力欄を自動で挿入します"
            presetNum={20}
            setIsEdited={setIsEdited}
          />
        );
      case 'userPrivateAddress':
        return (
          <UserInfoElement<T>
            baseName={baseName}
            index={index}
            text={[
              'フォーム表示時に以下の項目を自動で挿入します',
              <br key="1" />,
              '郵便番号入力欄',
              <br key="2" />,
              '都道府県選択',
              <br key="3" />,
              '住所（市区町村）',
              <br key="4" />,
              '住所（番地以降）',
            ]}
            presetNum={21}
            setIsEdited={setIsEdited}
          />
        );
      case 'userPrivateText':
        return (
          <TextElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'clip':
        return (
          <ClipStudioShareElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
          />
        );
      case 'mailMagazine':
        return (
          <MailMagazineElement<T>
            baseName={baseName}
            index={index}
            setIsEdited={setIsEdited}
            mailMagazineList={mailMagazineList}
          />
        );
      case 'uni_text':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            type={selected}
            setIsEdited={setIsEdited}
            text="ユーザー属性 テキスト1行です。"
            hasTextLimit
            hasFreeFormat
          />
        );
      case 'uni_radio':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            type={selected}
            setIsEdited={setIsEdited}
            text="ユーザー属性 ラジオボタンです。"
            hasSingleLineItems
          />
        );
      case 'uni_checkbox':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            type={selected}
            setIsEdited={setIsEdited}
            text="ユーザー属性 チェックボックス（複数）です。"
            hasSingleLineItems
            hasCheckLimit
          />
        );
      case 'uni_selectbox':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            type={selected}
            setIsEdited={setIsEdited}
            text="ユーザー属性 セレクトボックスです。"
          />
        );
      case 'uni_textarea':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            type={selected}
            setIsEdited={setIsEdited}
            text="ユーザー属性 テキスト複数行です。"
            hasFreeRowCount
            hasTextLimit
            hasFreeFormat
          />
        );
      case 'uni_date_calendar':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 日付（カレンダー）です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_date_split':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 日付（年月日別）です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_email':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 メールアドレスです。"
            type={selected}
            setIsEdited={setIsEdited}
            hasEmailConfirm
          />
        );
      case 'uni_tel':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 電話番号です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_zip_code_ja':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 郵便番号（日本）です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_zip_code_foreign':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 郵便番号（海外）です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_checkbox_single':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 チェックボックス（単一）です。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      case 'uni_group':
        return (
          <UserAttributeElement<T>
            baseName={baseName}
            index={index}
            text="ユーザー属性 グループです。"
            type={selected}
            setIsEdited={setIsEdited}
          />
        );
      default:
        return <></>;
    }
  };

  // 初期処理
  useEffect(() => {
    apiData.forEach((item) => {
      const id = getValues(`${baseName}.${index}.id` as Path<T>);
      if (item.id === id) {
        const type = getValues(`${baseName}.${index}.type` as Path<T>);
        const tId = getValues(
          `${baseName}.${index}.uniikeyUserAttributeSettingId` as Path<T>,
        );

        setValue(
          `${baseName}.${index}.typeOrUserAttrId` as Path<T>,
          (tId || type) as UnpackNestedValue<PathValue<T, Path<T>>>,
        );
        setDisabledFlg(true);
      }
    });
    setTypeAndUserAttrId();
  }, [setTypeAndUserAttrId]);

  const getOptionList = useCallback(() => {
    // 組織管理画面側では`説明画像`選択肢を除外する
    const excludeEnqueteFormSelectDecoOptions =
      EnqueteFormSelectDecoOptions.filter(
        (x) => !isAdmin || (isAdmin && x.value !== 'uploadImage'),
      );
    const optGroupList: { name: string; options: JSX.Element[] }[] = [];

    optGroupList.push(
      {
        name: '見出し・説明パーツ',
        options: excludeEnqueteFormSelectDecoOptions.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        )),
      },
      {
        name: '設問パーツ',
        options: EnqueteFormSelectOptions.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        )),
      },
    );

    // 組織管理画面側の設問パーツ選択肢用プルダウン生成処理
    if (isAdmin) {
      if (adminUserAttributes.length > 0) {
        optGroupList.push({
          name: 'ユーザー属性設問',
          options: adminUserAttributes
            .sort(
              (a, b) =>
                ((a?.displayOrder ?? 0) as number) -
                ((b?.displayOrder ?? 0) as number),
            )
            .map((item) => (
              <option key={item.id} value={item.id}>
                {item.isPersonalInformation ? '●' : ''}
                {item.displayNameJa}
              </option>
            )),
        });
      }
      // 集英社用の設問を追加する
      const options = [];
      let tmp = EnqueteFormPrivateOptions.find(
        (v) => v.id === 'EnqueteFormPrivateOptions-2',
      );
      if (tmp) {
        options.push(tmp); // ●フリーアンサー（個人情報指定）
      }
      tmp = EnqueteFormOtherOptions.find(
        (v) => v.id === 'EnqueteFormOtherOptions-1',
      );
      if (tmp) {
        options.push(tmp); // CLIP STUDIO SHARE
      }

      optGroupList.push({
        name: 'その他設問テンプレート（会員情報自動入力なし）',
        options: options.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        )),
      });
    } else {
      // UEC管理画面側の設問パーツ選択肢用プルダウン生成処理

      // ユーザー属性設問の実装前に作成された設問は編集できないがプルダウンには表示する
      const isDisabled = disabledFlg || getValues('uneditableState' as Path<T>);
      const formType = getFormType(tenantId);
      const hasPreset = hasPresetForm(tenantId);

      // 2 && true: GIGYA(従来全設問)
      if ((formType === 2 && !hasPreset) || isDisabled) {
        // 2: Gigya由来の従来の設問を追加する
        optGroupList.push(
          {
            name: '個人情報設問テンプレート（会員情報自動入力あり※ログイン時のみ）',
            options: EnqueteFormPrivatePresetOptions.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            )),
          },
          {
            name: '属性情報設問テンプレート（会員情報自動入力あり※ログイン時のみ）',
            options: EnqueteFormPresetOptions.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            )),
          },
          {
            name: '個人情報設問テンプレート（会員情報自動入力なし）',
            options: EnqueteFormPrivateOptions.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            )),
          },
          {
            name: 'その他設問テンプレート（会員情報自動入力なし）',
            options: EnqueteFormOtherOptions.map((item) => {
              const disabled =
                item.value === 'mailMagazine' && mailMagazineList.length === 0;

              return (
                <option key={item.id} value={item.value} disabled={disabled}>
                  {item.name}
                </option>
              );
            }),
          },
        );
      }
      // 2 && false: GIGYA(集英社暫定型)
      if ((formType === 2 && hasPreset) || isDisabled) {
        // 集英社用の設問を追加する

        // プリセット設問を非表示にする場合でも使いたい設問がある為追加する
        const privateOptionIds = [
          'EnqueteFormPrivatePresetOptions-1',
          'EnqueteFormPrivatePresetOptions-2',
          'EnqueteFormPrivatePresetOptions-4',
          'EnqueteFormPrivatePresetOptions-5',
        ];
        const otherOptionIds = [
          'EnqueteFormPresetOptions-1',
          'EnqueteFormPresetOptions-2',
          'EnqueteFormPresetOptions-10',
          'EnqueteFormPresetOptions-11',
        ];

        const privateOptions = EnqueteFormPrivatePresetOptions.filter((v) =>
          privateOptionIds.includes(v.id),
        ).map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ));
        const otherOptions = EnqueteFormPresetOptions.filter((v) =>
          otherOptionIds.includes(v.id),
        ).map((item) => {
          const disabled =
            item.value === 'mailMagazine' && mailMagazineList.length === 0;

          return (
            <option key={item.id} value={item.value} disabled={disabled}>
              {item.name}
            </option>
          );
        });

        optGroupList.push(
          {
            name: '個人情報設問テンプレート（会員情報自動入力なし）',
            options: [
              ...privateOptions,
              ...EnqueteFormPrivateOptions.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              )),
            ],
          },
          {
            name: 'その他設問テンプレート（会員情報自動入力なし）',
            options: [
              ...otherOptions,
              ...EnqueteFormOtherOptions.map((item) => {
                const disabled =
                  item.value === 'mailMagazine' &&
                  mailMagazineList.length === 0;

                return (
                  <option key={item.id} value={item.value} disabled={disabled}>
                    {item.name}
                  </option>
                );
              }),
            ],
          },
        );
      }
      // 1:Uniikey ユーザー属性, 3:集英社(Uniikey型)
      if (formType === 1 || formType === 3 || isDisabled) {
        // ユーザー属性が存在するときのみ設問を表示する
        if (userAttributes.length > 0) {
          optGroupList.push({
            name: 'ユーザー属性設問',
            options: userAttributes
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.isPersonalInformation ? '●' : ''}
                  {item.displayNameJa}
                </option>
              )),
          });
        }
      }
      if (formType === 3 || isDisabled) {
        // 集英社用の設問を追加する
        const options = [];
        let tmp = EnqueteFormPrivateOptions.find(
          (v) => v.id === 'EnqueteFormPrivateOptions-2',
        );
        if (tmp) {
          options.push(tmp); // ●フリーアンサー（個人情報指定）
        }
        tmp = EnqueteFormOtherOptions.find(
          (v) => v.id === 'EnqueteFormOtherOptions-1',
        );
        if (tmp) {
          options.push(tmp); // CLIP STUDIO SHARE
        }

        optGroupList.push({
          name: 'その他設問テンプレート（会員情報自動入力なし）',
          options: options.map((item) => (
            <option key={item.id} value={item.value}>
              {item.name}
            </option>
          )),
        });
      }
    }

    if (enqueteContentGroups.length > 0) {
      optGroupList.push({
        name: 'フォーム設問グループ(共通)',
        options: enqueteContentGroups.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )),
      });
    }

    return optGroupList.map((item, idx) => (
      <optgroup
        label={`${idx + 1}_${item.name}`}
        key={`${baseName}_type${idx + 1}`}
      >
        {item.options}
      </optgroup>
    ));
  }, [
    disabledFlg,
    enqueteContentGroups,
    getFormType,
    getValues,
    hasPresetForm,
    mailMagazineList.length,
    tenantId,
    userAttributes,
    adminUserAttributes,
  ]);

  return (
    <VStack p="6" spacing={4}>
      <Flex
        w="full"
        h={8}
        justifyContent="space-between"
        alignItems="flex-end"
        mt="-3rem"
        pointerEvents="none"
      >
        <Box fontSize={24} color="#ccc" mb="-1rem">
          No. {index + 1}
        </Box>
        <Box display="flex">
          <Box pointerEvents="auto">
            <Tooltip label="ひとつ上に移動" bg="gray.300" color="black">
              <IconButton
                variant="link"
                aria-label="EnqueteBlockUp"
                icon={<AiOutlineArrowUp />}
                fontSize={20}
                padding="6px"
                onClick={() => onUp(index, isFirst, curRef, prevRef)}
                isDisabled={isFirst}
              />
            </Tooltip>
          </Box>
          <Box pointerEvents="auto">
            <Tooltip label="ひとつ下に移動" bg="gray.300" color="black">
              <IconButton
                variant="link"
                aria-label="EnqueteBlockDown"
                icon={<AiOutlineArrowDown />}
                fontSize={20}
                padding="6px"
                onClick={() => onDown(index, isLast, curRef, nextRef)}
                isDisabled={isLast}
              />
            </Tooltip>
          </Box>
        </Box>
      </Flex>
      <SelectForm<T>
        name={`${baseName}.${index}.typeOrUserAttrId` as Path<T>}
        placeholder="パーツを選択してください（●は個人情報に該当）"
        attr={{
          required: 'パーツを選択してください（●は個人情報に該当）',
        }}
        errorPos="top"
        bgColor="#f9f9f9"
        borderColor="#ccc"
        isDisabled={disabledFlg || getValues('uneditableState' as Path<T>)}
        optionList={getOptionList()}
      />
      {FormElement()}
      <Flex w="full" justifyContent="flex-end" alignItems="center">
        {isThanksFlg &&
          (selected === 'userEmail' || selected === 'uni_email') && (
            <ErrorTextMsg
              fontSize="0.8rem"
              key="error-thanks-mail"
              msg="回答制限もしくはサンクスメール利用時は削除できません。"
            />
          )}
        <Box>
          <Tooltip label="下に項目追加" bg="gray.300" color="black">
            <IconButton
              variant="link"
              aria-label="EnqueteBlockCopy"
              icon={<VscDiffAdded />}
              fontSize={22}
              padding="6px"
              onClick={() => onAdd(index)}
              isDisabled={getValues('uneditableState' as Path<T>)}
            />
          </Tooltip>
        </Box>
        <Box>
          <Tooltip label="コピー" bg="gray.300" color="black">
            <IconButton
              variant="link"
              aria-label="EnqueteBlockCopy"
              icon={<MdContentCopy />}
              fontSize={16}
              padding="6px"
              onClick={() => onCopy(index)}
              isDisabled={getValues('uneditableState' as Path<T>)}
            />
          </Tooltip>
        </Box>
        <Box ml={2}>
          <DeleteContentsDialog<T>
            onDelete={onDelete}
            index={index}
            isAdmin={isAdmin}
          />
        </Box>
        {!['heading', 'richText', 'uploadImage', 'split'].includes(
          selected,
        ) && (
          <>
            <Box h="20px">
              <Divider orientation="vertical" />
            </Box>
            <Box ml={4}>
              <Checkbox
                {...register(`${baseName}.${index}.required` as Path<T>)}
                isDisabled={getValues('uneditableState' as Path<T>)}
              >
                必須
              </Checkbox>
            </Box>
          </>
        )}
      </Flex>
    </VStack>
  );
};

export const EnqueteFormElement = <T extends FieldValues>(
  props: EnqueteFormElementProps<T>,
): ReturnType<FC> => {
  const { pathname } = useLocation();
  const tenantId = useUserTenantId();
  const { getFormType } = useUserInfo();

  // 集英社の時は user_attributes の API を実行するとエラーになる為
  const hasUserAttributes = getFormType(tenantId) !== 2;
  const hasEnqueteContentGroups = hasUserAttributes;
  // 組織管理画面かどうか
  const regexp = /^\/admin/;
  const isAdmin = regexp.test(pathname);

  return (
    <FetchEnqueteFormElement<T>
      {...props}
      hasUserAttributes={hasUserAttributes}
      hasEnqueteContentGroups={hasEnqueteContentGroups}
      isAdmin={isAdmin}
    />
  );
};
