import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'memberInfoChange.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '会員情報の変更',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoChange.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '会員情報の変更',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoChange.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '会員情報を変更し、画面最下部にある「保存」を押下することで会員情報の変更が完了します。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoChange.saveButtonName',
    label: 'ボタン名 変更した会員情報の保存',
    type: 'Text',
    defaultValue: '保存',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoChange.backButtonName',
    label: 'ボタン名 前の画面に遷移',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
