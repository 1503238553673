import { getSetting } from 'admin/api/service/setting/getSetting';
import { CustomError } from 'admin/error/CustomError';
import { querySettingKey } from 'admin/hooks/service/setting/querySettingKey';
import { logger } from 'api/logger';
import { useCustomToast } from 'hooks/useCustomToast';
import { useQuery } from 'react-query';
import { successToast } from 'utils/toast';

export const useSetting = () => {
  const toast = useCustomToast();
  const { data } = useQuery({
    queryKey: querySettingKey.getSetting(),
    queryFn: () => getSetting(),
    onSuccess: () => {
      toast({
        ...successToast,
        duration: 4000,
        title: 'その他の設定情報を取得しました。',
        position: 'bottom',
        variant: 'solid',
      });
    },
    onError: async (error) => {
      if (error instanceof CustomError) {
        await logger({
          loglevel: error.logLevel,
          data: JSON.stringify(error.cause),
          message: error.message,
        });
      }
    },
  });

  return { data };
};
