import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'withdrawalConfirmation.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '退会確認',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalConfirmation.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '退会確認',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalConfirmation.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue:
      '「退会する」を押すと退会が完了します。\n\n退会をされますと、\nご利用中の各媒体の会員向けサービスを利用することができなくなります。\nまた、退会前にご登録いただいた情報の復元はできません。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalConfirmation.continueButtonName',
    label: 'ボタン名 退会',
    type: 'Text',
    defaultValue: '退会',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'withdrawalConfirmation.backButtonName',
    label: 'ボタン名 前の画面に遷移',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
