import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Colors } from 'admin/define/colors';
import { SettingType } from 'admin/types/service/setting/';
import { FC, memo } from 'react';

type Props = {
  settingData: SettingType;
  onOpen: () => void;
};

export const ApiWhiteListComponent: FC<Props> = memo(
  ({ settingData, onOpen }: Props) => (
    <Box display="inline-block" w="100%">
      <Flex>
        <Box display="block" mr="auto" fontFamily="Hiragino Sans">
          <Text fontWeight="700">API通信のホワイトリスト</Text>
          <Text>API通信のホワイトリストについて...</Text>
        </Box>
        <Button
          variant="outline"
          colorScheme="primary"
          p="8px 24px"
          onClick={onOpen}
        >
          API通信のホワイトリスト設定
        </Button>
      </Flex>
      <Box
        mt="16px"
        p="8px 16px"
        height="175px"
        bgColor={Colors.GRAY_70}
        fontFamily="Hiragino Sans"
        borderRadius="8px"
      >
        <Text fontWeight="700">ホワイトリスト</Text>
        <Text>{settingData.whiteList}</Text>
      </Box>
    </Box>
  ),
);
