import {
  maxLength,
  headTitleOptions,
  itemNameOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'firstInfoEntry.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '会員情報の登録',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '会員情報の登録',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue: 'お客さまの情報を入力してください。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.emailItemName',
    label: '入力欄名 メールアドレス',
    type: 'Text',
    defaultValue: 'メールアドレス',
    options: itemNameOptions,
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.termsDescription',
    label: '案内文 規約全体の説明',
    type: 'Textarea',
    defaultValue:
      '本サービスの利用には、以下の各規約に同意する必要があります。',
    options: {
      maxLength: maxLength(50),
    },
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.termsAgreementCheckbox',
    label: 'チェックボックス名 規約同意',
    type: 'Text',
    defaultValue: '各規約に同意する',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'firstInfoEntry.continueButtonName',
    label: 'ボタン名 入力情報確認画面に遷移',
    type: 'Text',
    defaultValue: '入力内容の確認',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
