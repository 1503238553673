import {
  headTitleOptions,
  screenNameOptions,
  screenDescriptionOptions,
  buttonNameOptions,
} from './options';

export default [
  // ロゴ	画像
  {
    name: 'memberInfoConfirm.headTitle',
    label: 'タイトル',
    type: 'Text',
    defaultValue: '会員情報',
    options: headTitleOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoConfirm.screenName',
    label: '画面名',
    type: 'Text',
    defaultValue: '会員情報',
    options: screenNameOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoConfirm.screenDescription',
    label: '案内文 画面',
    type: 'Textarea',
    defaultValue: '登録されている情報の変更は「会員情報の変更」から行えます。',
    options: screenDescriptionOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoConfirm.saveButtonName',
    label: '「会員情報の変更」ボタン',
    type: 'Text',
    defaultValue: '保存',
    options: buttonNameOptions,
    elementOptions: {},
  },
  {
    name: 'memberInfoConfirm.backButtonName',
    label: '戻るボタン',
    type: 'Text',
    defaultValue: '戻る',
    options: buttonNameOptions,
    elementOptions: {},
  },
] as const;
