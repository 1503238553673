import { Flex, IconButton, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

type Props = {
  viewText: string;
  isVisibility: boolean;
  onClickIsVisibility: () => void;
};

export const SettingMaskingTextComponent: FC<Props> = memo(
  ({ viewText, isVisibility, onClickIsVisibility }: Props) => (
    <Flex alignItems="center" justifyContent="space-between">
      <Text>{viewText}</Text>
      <IconButton
        variant="ghost"
        aria-label="visibilityButton"
        onClick={onClickIsVisibility}
      >
        {isVisibility ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
      </IconButton>
    </Flex>
  ),
);
