import { Drawer, useDisclosure } from '@chakra-ui/react';
import { withSuspenseAndErrorBoundary } from 'admin/components/Error/WithErrorBoundary';
import { ApiWhiteListComponent } from 'admin/components/service/setting/apiWhiteList/ApiWhiteListComponent';
import { ApiWhiteListDrawer } from 'admin/components/service/setting/apiWhiteListDrawer';
import { SettingType } from 'admin/types/service/setting/';
import { ErrorContents, LoadingSpinner } from 'components/common/atoms';
import { FC, memo } from 'react';

type Props = {
  settingData: SettingType;
};

export const apiWhiteList: FC<Props> = memo(({ settingData }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <ApiWhiteListComponent onOpen={onOpen} settingData={settingData} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <ApiWhiteListDrawer onClose={onClose} />
      </Drawer>
    </>
  );
});

export const ApiWhiteList = memo(
  withSuspenseAndErrorBoundary(apiWhiteList, {
    ErrorComponent: <ErrorContents name="API通信のホワイトリスト" />,
    LoadingComponent: <LoadingSpinner />,
  }),
);
