import { z } from 'zod';

const alphaNumericRegex = /^[0-9A-Za-z]+$/;

export const alphaNumericSchema = z
  .string()
  .regex(alphaNumericRegex, { message: '半角英数字を入力してください' });

export const isPositiveInteger = (value: string): boolean => {
  if (!value) {
    return true;
  }
  // すべての文字が数字であることを確認
  const allDigits = /^\d+$/.test(value);

  // 小数点以下を含まないことを確認
  const noDecimalPoint = !/\./.test(value);

  // スキーマの条件を満たしているか確認
  return allDigits && noDecimalPoint;
};

export const deleteSubmitFormSchema = z.object({
  word: z.string().refine((val) => val === 'delete', {
    message: '"delete"と入力してください',
  }),
});

export const confirmSubmitFormSchema = z.object({
  word: z.string().refine((val) => val === 'confirm', {
    message: '"confirm"と入力してください',
  }),
});

export const agreeSubmitFormSchema = z.object({
  word: z.string().refine((val) => val === 'agree', {
    message: '"agree"と入力してください',
  }),
});
