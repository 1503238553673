import { VStack } from '@chakra-ui/react';
import ErrorBoundary from 'api/ErrorBoundary';
import { LoadingSkeleton } from 'components/common/atoms';
import { ListError } from 'components/common/molecules';
import { useErrorBoundaryReset } from 'hooks/useErrorBoundaryReset';
import { FC, Suspense } from 'react';
import { Inner } from './Inner';

type Props = { userPoolId?: string };

export const AuthHubList: FC<Props> = ({ userPoolId }) => {
  const { ebKey, onError } = useErrorBoundaryReset();

  return (
    <VStack w="100%" spacing={4}>
      <ErrorBoundary
        errorComponent={
          <ListError message="ホスティング設定一覧を表示できません" />
        }
        onError={onError}
        key={ebKey.current}
      >
        <Suspense fallback={<LoadingSkeleton width="100%" />}>
          <Inner userPoolId={userPoolId} />
        </Suspense>
      </ErrorBoundary>
    </VStack>
  );
};
