export const required = 'このフィールドは入力必須です。';

export const colorPattern = {
  value: /#[0-9a-fA-F]{6}/,
  message: '有効なカラーコード（例：#FFFFFF）を入力してください。',
};

export const urlPattern = {
  value: /^https?:\/\/[\w/:%#$&?()~.=+-]+$/,
  message: 'URL形式で入力してください。',
};

export const maxLength: (_: number) => { value: number; message: string } = (
  value,
) => ({
  value,
  message: `${value}文字以下で入力してください。`,
});

export const colorOptions = {
  required,
  pattern: colorPattern,
};

export const buttonNameOptions = {
  required,
  maxLength: maxLength(15),
};

export const itemNameOptions = {
  required,
  maxLength: maxLength(30),
};

export const urlOptions = {
  pattern: urlPattern,
};

export const headTitleOptions = {
  maxLength: maxLength(15),
};

export const screenNameOptions = {
  maxLength: maxLength(15),
};

export const screenDescriptionOptions = {
  maxLength: maxLength(200),
};
