import { Badge, Flex, Text, VStack } from '@chakra-ui/react';
import { StatusBadge } from 'admin/components/member/statusBadge';
import { Colors } from 'admin/define/colors';
import { MemberType } from 'admin/types/member';
import { MEMBER_STATUS_TYPE_LIST } from 'define';
import { useFormatDate } from 'hooks/useFormatDate';
import { FC, memo } from 'react';

// import { useNavigate } from 'react-router-dom';

type Props = {
  member: MemberType;
};

export const MemberInfoComponent: FC<Props> = memo(({ member }: Props) => {
  const { dateTimeFormat } = useFormatDate();
  // TODO: テナントのAPI待ちの為、一時的にコメントアウト
  // const navigate = useNavigate();

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      bgColor={Colors.GRAY_70}
      p={4}
      borderRadius="8px"
      style={{ gap: '8px' }}
      overflowX="auto"
    >
      <VStack w="50%" minW="470px" alignItems="flex-start">
        <Flex>
          <Text minW="112px" mr="16px" color={Colors.GRAY_00}>
            会員ID
          </Text>
          <Text fontWeight="700">{member.uid}</Text>
        </Flex>
        <Flex>
          <Text minW="112px" mr="16px" color={Colors.GRAY_00}>
            メールアドレス
          </Text>
          <Text fontWeight="700">{member.email}</Text>
        </Flex>
        <Flex>
          <Text minW="112px" mr="16px" color={Colors.GRAY_00}>
            ステータス
          </Text>
          <StatusBadge
            status={member.status}
            label={MEMBER_STATUS_TYPE_LIST.get(member.status)}
          />
        </Flex>
        <Flex>
          <Text minW="112px" mr="16px" color={Colors.GRAY_00}>
            登録日時
          </Text>
          <Text fontWeight="700">{dateTimeFormat(member.createdAt)}</Text>
        </Flex>
        <Flex>
          <Text minW="112px" mr="16px" color={Colors.GRAY_00}>
            最終ログイン日時
          </Text>
          <Text fontWeight="700">{dateTimeFormat(member.lastLoginAt)}</Text>
        </Flex>
      </VStack>
      <VStack w="50%" alignItems="flex-start">
        <Flex>
          <Text minW="151px" mr="16px" color={Colors.GRAY_00}>
            所属するユーザープール
          </Text>
          <Text fontWeight="700">{member.userPool}</Text>
        </Flex>
        <Flex>
          <Text minW="151px" mr="16px" color={Colors.GRAY_00}>
            所属するテナント
          </Text>
          <Flex flexWrap="wrap" css={{ gap: '6px 8px' }}>
            {member.userPoolChildren.map((service) => (
              <Badge
                key={`member_service_${service.id}`}
                bgColor={Colors.BLUE_LIGHT_01}
                fontWeight={400}
                px={2}
                fontSize="14px"
                borderRadius="6px"
                // TODO: テナントのAPI待ちの為、一時的にコメントアウト
                // onClick={() => navigate(`/admin/member/service/${service.id}`)}
                // cursor="pointer"
              >
                {service.name}
              </Badge>
            ))}
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  );
});
