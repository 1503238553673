import { putEnquete } from 'api/enquete/putEnquete';
import {
  AddEnqueteSurveyForm,
  EnqueteFormType,
  EnqueteFormTypeDefaultValue,
  EnqueteSurveyForm,
  isEnqueteSurveyForm,
} from 'api/enquete/types';
import { ResponseError, isResponseError } from 'api/types';
import { useEnqueteUtil } from 'hooks/enquete/useEnqueteUtil';
import { useCustomToast } from 'hooks/useCustomToast';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import { UseMutateFunction, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getValidationError,
  isGetNestedValidationError,
  toMultiError,
} from 'utils/form';

type UnPromisify<T> = T extends Promise<infer U> ? U : T;

const formData: EnqueteSurveyForm = {
  id: '',
  title: '',
  enqueteContents: [],
  thanks: '',
  thanksHtml: '',
  thanksButtonText: '',
  thanksButtonUrl: '',
  submitButtonText: '',
  skin: '',
};

/**
 * アンケートテンプレート変更hooks
 * @param {Object} UseFormSetError setError
 * @param {setGlobalErrors} callback setGlobalErrors
 * @returns {Object} { onSubmit, isLoading }
 */
export const useEditEnquete = ({
  setError,
}: {
  setError: UseFormSetError<EnqueteSurveyForm>;
}): {
  onSubmit: UseMutateFunction<
    UnPromisify<ReturnType<typeof putEnquete>>,
    unknown,
    AddEnqueteSurveyForm,
    unknown
  >;
  isLoading: boolean;
} => {
  const { EnqueteFormRefetchListQueries } = useEnqueteUtil();
  const toast = useCustomToast();
  const navigate = useNavigate();

  const { mutate: onSubmit, isLoading } = useMutation(
    ({ tenantId, enqueteId, enqueteSurverForm }: AddEnqueteSurveyForm) =>
      putEnquete({ tenantId, enqueteId, enqueteSurverForm }),
    {
      onSuccess: (result: EnqueteSurveyForm | ResponseError) => {
        EnqueteFormRefetchListQueries();
        if (isEnqueteSurveyForm(result)) {
          toast({
            status: 'success',
            position: 'bottom',
            duration: 4000,
            isClosable: true,
            title: 'フォームを修正しました',
          });
          navigate(`/enquete/`);
        }
        if (
          isGetNestedValidationError<EnqueteSurveyForm>(
            result,
            Object.keys(formData),
          )
        ) {
          if (result?.others) {
            const errorMsg = Object.entries(result).map(([_, value]) => value);
            toast({
              status: 'error',
              position: 'bottom',
              duration: 4000,
              isClosable: true,
              title: errorMsg[0],
            });
          } else {
            Object.keys(result).forEach((k) => {
              const key = k as keyof EnqueteSurveyForm;
              const errMsgs = result?.[key];
              if (
                errMsgs &&
                Array.isArray(errMsgs) &&
                errMsgs.every((v) => typeof v === 'string')
              ) {
                setError(key, {
                  types: toMultiError(errMsgs),
                });
              } else {
                errMsgs?.forEach((err, idx) => {
                  if (isResponseError(err)) {
                    const nestErrors = getValidationError<EnqueteFormType>({
                      formData: EnqueteFormTypeDefaultValue,
                      response: err as ResponseError,
                      replaceKeys: {
                        nonFieldErrors: 'typeOrUserAttrId',
                      },
                    });
                    Object.keys(nestErrors).forEach((eftk) => {
                      const eftkey = eftk as keyof EnqueteFormType;
                      const errNestMsgs = nestErrors?.[eftkey];
                      const errorKey: FieldPath<EnqueteSurveyForm> = `enqueteContents.${idx}.typeOrUserAttrId`;
                      setError(errorKey, {
                        types: toMultiError(errNestMsgs || []),
                      });
                    });
                  }
                });
                toast({
                  status: 'error',
                  position: 'bottom',
                  duration: 4000,
                  isClosable: true,
                  title: '入力エラーがあります。',
                  description: 'エラーを確認してください。',
                });
              }
            });
          }
        }
      },
    },
  );

  return { onSubmit, isLoading };
};
