import { Box, Icon, List, ListItem } from '@chakra-ui/react';
import { EnqueteFormType } from 'api/enquete/types';
import { EnqueteFormElement } from 'components/enquete/molecules/EnqueteFormElement';
import { MailMagazine } from 'components/setting/mailMagazine/typed';
import {
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DragDropContext,
  DragStart,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { FieldErrors, FieldValues, Path } from 'react-hook-form';
import { MdDragIndicator } from 'react-icons/md';

type DraggableListItemProps<T> = {
  baseName: Path<T>;
  errors: FieldErrors<FieldValues>;
  mailMagazineList?: MailMagazine[];
  apiData: EnqueteFormType[];
  isThanksFlg: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  list: EnqueteFormType[];
  handleDragEnd: (result: DropResult) => void;
  copySelectType: string;
  onUp: (
    index: number,
    isFirst: boolean,
    curRef: RefObject<HTMLDivElement>,
    prevRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onDown: (
    index: number,
    isLast: boolean,
    curRef: RefObject<HTMLDivElement>,
    nextRef: RefObject<HTMLDivElement> | null,
  ) => void;
  onAdd: (index: number) => void;
  onAddElement: () => void;
  onInsertElement: (index: number, form: EnqueteFormType) => void;
  onCopy: (index: number) => void;
  onDelete: (index: number) => void;
  uniqueId: () => string;
};

/**
 * フォームパーツ作成・編集用共通コンポーネント
 * 以下のファイルで使用しています。
 * フォームページ: src/components/enquete/organisms/EnqueteInner.tsx
 * フォーム設問グループページ: src/admin/components/idPoolConsumer/formGroup/detail/detailFormGroups.tsx
 *
 * 共通化に伴い、型を指定するよう修正していますが、以下のpropsは`EnqueteFormType`型に依存しています。
 * - list
 * - apiData
 */
export const DraggableListItem = <T extends FieldValues>({
  baseName,
  errors,
  mailMagazineList = [],
  apiData,
  isThanksFlg,
  setIsEdited,
  list,
  handleDragEnd,
  copySelectType,
  onUp,
  onDown,
  onAdd,
  onAddElement,
  onCopy,
  onDelete,
  uniqueId,
  onInsertElement,
}: DraggableListItemProps<T>): ReturnType<FC> => {
  const [isDragging, setDragging] = useState<boolean>(false);

  // 子供のアンケートフォームでエラーがあった場合、該当コンポーネントkeyを配列で保持する
  const [errorList, setErrorList] = useState<string[]>([]);

  const handleDragStart = useCallback((_: DragStart) => {
    setDragging(true);
    setErrorList([]);
  }, []);

  const handleDndDragEnd = useCallback((result: DropResult) => {
    setDragging(false);
    handleDragEnd(result);
  }, []);

  // ↑↓ボタンで隣の設問と入れ替える際のスクロールで隣の設問のDOMを取得する必要がある
  const formRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  list.forEach((_, idx) => {
    formRefs.current[idx] = createRef<HTMLDivElement>();
  });

  // フォームのエラーがリセットされたタイミングで子のエラー配列をリセットする
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorList([]);
    }
  }, [errors]);

  return (
    <Box w="100%" textAlign="center">
      <DragDropContext
        onDragStart={handleDragStart}
        onDragEnd={(result: DropResult) => handleDndDragEnd(result)}
      >
        <Droppable droppableId="EnqueteFormList">
          {(dorppableProvided) => (
            <List
              mr={4}
              w="100%"
              {...dorppableProvided.droppableProps}
              ref={dorppableProvided.innerRef}
            >
              {list.map((item, index) => (
                <Box
                  ref={formRefs.current[index]}
                  key={`Droppable${item.dndId} ${String(index)}`}
                >
                  <Draggable draggableId={item.dndId} index={index}>
                    {(draggableProvided) => (
                      <ListItem
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        <Box
                          borderWidth="1px"
                          borderRadius="md"
                          p={3}
                          py={2}
                          mb={5}
                          boxShadow="md"
                          backgroundColor={
                            errorList.includes(`${baseName}.${index}`)
                              ? '#FED7D7'
                              : 'white'
                          }
                        >
                          <Box
                            textAlign="center"
                            {...draggableProvided.dragHandleProps}
                          >
                            <Icon
                              as={MdDragIndicator}
                              fontSize={20}
                              color="gray.500"
                              mr={2}
                              style={{ transform: 'rotate(90deg)' }}
                            />
                          </Box>
                          <EnqueteFormElement<T>
                            key={`EnqueteFormElement${item.dndId} ${String(
                              index,
                            )}`}
                            index={index}
                            baseName={baseName}
                            copySelectType={copySelectType}
                            mailMagazineList={mailMagazineList}
                            onUp={onUp}
                            onDown={onDown}
                            onAdd={onAdd}
                            onAddElement={onAddElement}
                            onInsertElement={onInsertElement}
                            onCopy={onCopy}
                            onDelete={onDelete}
                            apiData={apiData}
                            setIsEdited={setIsEdited}
                            setErrorList={setErrorList}
                            isThanksFlg={isThanksFlg}
                            isFirst={index === 0}
                            isLast={index === list.length - 1}
                            prevRef={
                              index === 0 ? null : formRefs.current[index - 1]
                            }
                            nextRef={
                              index === list.length - 1
                                ? null
                                : formRefs.current[index + 1]
                            }
                            curRef={formRefs.current[index]}
                            isDragging={isDragging}
                            uniqueId={uniqueId}
                          />
                        </Box>
                      </ListItem>
                    )}
                  </Draggable>
                </Box>
              ))}
              {dorppableProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
